import React from "react";
import { Link } from "react-router-dom";

const PublicNavigationTop = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <ul>
      <li>
        <Link onClick={handleClick} to="/#speak-with-an-advisor">
          Contact Us
        </Link>
      </li>
      <li>
        <Link onClick={handleClick} to="/login">
          Client Login
        </Link>
      </li>
    </ul>
  );
};

export default PublicNavigationTop;
