import React, { useEffect } from "react";
import styles from "./ResearchAndInsightsDetailScreen.module.scss";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isoToDateTime } from "../../utils/util";
import arrowLeft from "../../assets/images/left-arrow-svgrepo-com.svg";

import { selectEvents } from "../../store/slices/events/slice";

const ResearchAndInsightsDetailScreen = () => {
  const params = useParams();
  const events = useSelector(selectEvents);
  const data = events.find((element) => element.id === params.id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return typeof data !== "undefined" ? (
    <div className={styles.container}>
      <div className={styles.insideContainer}>
        <div className={styles.back}>
          <Link to="/research-and-insights">
            <img src={arrowLeft} alt="back" />
            Return to Research and Insights
          </Link>
        </div>
        <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
        <div className={styles.details}>
          <span>{isoToDateTime(data.eventDate)}</span>
        </div>
        <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
      </div>
    </div>
  ) : (
    <Navigate to="/research-and-insights" />
  );
};

export default ResearchAndInsightsDetailScreen;
