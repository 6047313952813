import React, { useEffect, useState } from "react";
import styles from "./DocumentsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentToSign } from "../../../store/slices/documentToSign/asyncThunks";
import { selectDocumentToSign } from "../../../store/slices/documentToSign/slice";
import Tabs from "../../../components/Tabs/Tabs";

const DocumentScreen = () => {
  const dispatch = useDispatch();
  const { userDocuments } = useSelector(selectDocumentToSign);

  const [tabsData] = useState([
    {
      id: 0,
      label: "New",
    },
    {
      id: 1,
      label: "Signed",
    },
  ]);

  useEffect(() => {
    dispatch(fetchDocumentToSign({}));
  }, []);

  return (
    <div className={styles.container}>
      <h1>Your documents</h1>
      <Tabs data={tabsData} documents={userDocuments} />
    </div>
  );
};

export default DocumentScreen;
