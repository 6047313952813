import React from "react";
import styles from "./RetirementPlanning.module.scss";

import image from "./../../assets/images/ourServicesRetirement.jpg";

const RetirementPlanning = () => {
  return (
    <section className={styles.retirementPlanning}>
      <img src={image} alt="Retirement Planning" />
      <h1>Retirement Planning</h1>
      <div className={styles.retirementPlanningContent}>
        <p>
          After decades of hard work and dedication to your profession, there
          comes a time when you are ready, willing, and able to relax and enjoy
          the fruits of your labor. You will want to determine the best time to
          retire and how much income you need to sustain the quality of life you
          wish to enjoy. You may have some new expenses, such as more frequent
          vacations, or lower costs due to less activity, such as less travel
          for work. We understand that planning for retirement can feel
          overwhelming. However, retirement planning is vital to securing your
          and your family’s financial future. We can help you reduce your stress
          over retirement planning and help you develop a clear and manageable
          solution.
          <br />
          <br />
          At Wells Investments, we recognize that planning for retirement is not
          a one-time event but rather an ongoing process that requires care and
          attention. Our retirement planning experts can help you create a
          customized retirement plan that addresses your personal goals, needs,
          and unique circumstances. We also continually work with you to monitor
          and update your retirement plan. We have worked with clients with
          various retirement savings (hundreds of thousands to millions of
          dollars). Our clients have a variety of career backgrounds, from
          labor-intensive blue-collar professionals to white-collar office
          professionals. We also help clients review and determine how best to
          receive various benefits like Social Security and Medicare. We help
          clients establish tax-efficient methods of receiving income and assist
          them with employer-sponsored plans such as a 401(k) or 403(b). We also
          help clients and potential clients evaluate early retirement packages
          and regular retirement packages offered by their companies. When you
          retire, there are a variety of circumstances to consider before making
          a decision. To the best of your ability, you want to ensure you have
          enough to retire. It is critical to get this right because once the
          process of receiving your retirement package is complete, it may be
          irreversible. Keep in mind that when you retire, you may lose your
          medical benefits.
          <br />
          <br />
          Every client has their own set of needs and requirements. Because
          every person, every client, is unique, we believe in a client-centered
          approach that places your needs and aspirations at the center of
          everything we do. We start by getting to know you as a person. We
          listen to you. We learn from you because we listen to you. We learn
          about your financial situation, your family situation, and your
          personal needs. This helps us better understand you and your goals.
          You are the center of attention; this approach will help us help you.
          We will help you review your income needs versus your expenses and
          your assets versus your liabilities. If applicable, we can review
          whether you should maintain a mortgage or pay it off. Because we
          review all the above information, this will give you a high level of
          confidence that we can help you. You will be informed, and you will
          understand and know that we listened to you. “One problem [for the
          sustainability of retirement funds]…is how people without specialized
          financial training could manage their resources… towards retirement”
          (Herrador-Alcaide et al., 2021).
          <br />
          <br />
          Our goal is to build long-lasting relationships with our clients based
          on trust, respect, and a shared commitment to help you achieve your
          retirement goals. We will provide education, ongoing support, and
          guidance throughout your financial journey.
          <br />
          <br />
          <br />
          Reference:
          <br />
          Herrador-Alcaide, T., Hernández-Solís, M., & Topa, G. (2021). A model
          for personal financial planning towards retirement. Journal of
          Business Economics and Management, 22(2), 482-502.&nbsp;
        </p>
      </div>
    </section>
  );
};

export default RetirementPlanning;
