import React, { forwardRef, useImperativeHandle, useState } from "react";
import styles from "./Modal.module.scss";
import { ReactComponent as Close2 } from "../../assets/icons/close_icon.svg";
import Button from "../Button/Button";
import classNames from "classnames";

const Modal = forwardRef(
  (
    {
      children,
      title,
      onClose,
      maxWidth,
      marginTop,
      buttonOkText,
      buttonCloseText,
      buttonOkOnClick,
      buttonCloseOnClick,
      pendingAction,
      buttonOkDisabled,
      showCancelButton,
      showOkButton,
      modalStyle,
      modalHeader,
      modalFooter,
      modalInnerStyle,
      closeIcon,
      closeIconStyle,
    },
    ref
  ) => {
    const [visible, setVisibility] = useState(false);

    useImperativeHandle(ref, () => ({
      show() {
        setVisibility(true);
      },
      hide() {
        setVisibility(false);
      },
    }));

    if (!visible) return null;

    return (
      <div className={styles.modal}>
        <div
          className={classNames(styles.modalContent, modalStyle)}
          style={{ maxWidth, marginTop }}
        >
          {closeIcon && (
            <div
              className={classNames(styles.closeIcon, closeIconStyle)}
              onClick={onClose}
            >
              <Close2 />
            </div>
          )}
          {!modalHeader && (
            <div className={styles.modalHeader}>
              <p></p>
              <p className={styles.modalHeaderTitle}>{title}</p>
              <div
                className={styles.modalHeaderIconBox}
                onClick={onClose}
              ></div>
            </div>
          )}
          <div
            className={classNames(styles.modalContentInner, modalInnerStyle)}
          >
            {children}
          </div>

          {!modalFooter && (
            <div className={styles.modalFooter}>
              {showCancelButton && (
                <Button
                  onClick={buttonCloseOnClick}
                  classes={styles.modalFooterCloseButton}
                  style={{ maxWidth: "30%" }}
                  title={buttonCloseText}
                  disabled={pendingAction}
                ></Button>
              )}
              {showOkButton && (
                <Button
                  onClick={buttonOkOnClick}
                  classes={styles.modalFooterOkButton}
                  style={{ maxWidth: "30%" }}
                  title={buttonOkText}
                  enabled={pendingAction || buttonOkDisabled}
                  loading={pendingAction}
                ></Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

Modal.defaultProps = {
  title: "Title",
  maxWidth: "50rem",
  marginTop: 0,
  buttonOkText: "Ok",
  buttonCloseText: "Close",
  showCancelButton: true,
  showOkButton: true,
  onClose: () => console.log(),
  buttonCloseOnClick: () => alert("CLOSE"),
  buttonOkOnClick: () => alert("Ok"),
};

export default Modal;
