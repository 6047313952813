import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserInfoBuilder,
  loginBuilder,
  logoutBuilder,
  signupBuilder,
  forgotPasswordBuilder,
  forgotPasswordSendCodeBuilder,
} from "./builder";

const initialState = {
  loggedIn: false,
  loginPending: false,
  signupPending: false,
  forgotPasswordPending: false,
  forgotPasswordSendCodePending: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    loginBuilder(builder);
    fetchUserInfoBuilder(builder);
    logoutBuilder(builder);
    signupBuilder(builder);
    forgotPasswordBuilder(builder);
    forgotPasswordSendCodeBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const {} = authSlice.actions;

export const selectLoggedIn = (state) => state.auth.loggedIn;

export default authSlice.reducer;
