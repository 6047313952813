class SessionStorageService {
  getItem = (key) => {
    return sessionStorage.getItem(key);
  };

  getAll = () => {
    let storage = {};
    Object.keys(sessionStorage).forEach((key) => {
      storage[key] = sessionStorage.getItem(key);
    });
    return storage;
  };

  setItem = (key, value) => {
    if (typeof value === "object") {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  };

  removeItem = (key) => {
    sessionStorage.removeItem(key);
  };

  removeAll = async () => {
    await sessionStorage.removeAll();
  };
}

export default new SessionStorageService();
