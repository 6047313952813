import React from "react";
import styles from "./InvestmentPlanning.module.scss";

import image from "./../../assets/images/img3.jpg";

const InvestmentPlanning = () => {
  return (
    <section className={styles.investmentPlanning}>
      <img src={image} alt="Investment Planning" />
      <h1>Investment Planning</h1>
      <div className={styles.investmentPlanningContent}>
        <p>
          Wells Investments offers personalized equity and fixed-income
          management to higher-net-worth individuals ($500,000 or more of
          investable assets) and institutional clients. The firm takes an active
          approach to portfolio management, using information obtained by
          capital markets research to adjust client portfolios based on our
          forecasts and opportunities that we see in the markets.
          <br />
          <br />
          The objective of an investment plan should be based on situational
          awareness. You want to create the most reasonable chance of success in
          your investment plan by being honest with yourself and your financial
          or investment advisor. We can help you gain situational awareness.
          <br />
          <br />
          An investment plan can be developed with clear, concise, and realistic
          investment goals considering your age, employment status and income,
          other income sources, expenses, net worth, and a risk analysis, among
          other variables. We provide personalized portfolio management and
          reporting for each client based on their investment goals, time
          horizon, cash flow requirements, outside holdings, tax status, and
          other factors. We take the time to understand our clients’ risk
          tolerances and goals and combine that with our broad investment
          philosophy and investment acumen. We take an active approach to try to
          optimize returns relative to risk because we believe that protecting
          clients from downside volatility is the most effective way to optimize
          long-term returns for clients. We try to optimize upside returns
          relative to risk during bull markets. We do not guarantee returns.
          Risk management may be the most crucial aspect of your investment
          plan. We work very hard to help you achieve your long-term goals. We
          believe you will see the difference because of our frequent contact
          with you and our emphasis on portfolio risk management.
          <br />
          <br />
          Of the above considerations, risk analysis may be one of the most
          challenging considerations to properly evaluate because it is
          subjective and emotional. The other considerations are empirical and
          more easily measurable. If an investment advisor asks you to take a
          risk profile test and bases his/her recommendations on that survey,
          they may be missing a significant analysis point. That point is
          “emotional response.” It is one thing to take a survey in a friendly,
          comfortable office with air-conditioning and a cup of coffee. There is
          no emotion; there is only a comfortable response to a comfortable
          atmosphere. You do not feel the emotion of loss or investment decline.
          It is an entirely different experience to feel that decline and
          provide responses to a survey with that intense emotional feeling of
          loss. In fact, academic research suggests that a decline is felt about
          2 ½ times more intensely than an increase of the same amount (Kahneman
          and Tversky, 1979).
          <br />
          <br />
          Wells Investments attempts to get a more accurate response to risk
          tolerance questions based on genuine feelings of loss based on your
          past reactions to market declines. This is a unique but much more
          accurate way to measure risk tolerance. Did you experience or do you
          remember some of the recent market declines? Do you remember how you
          felt during those market declines? Our investment principals and
          professionals agree that a key to increasing your opportunity for
          success is to help you more accurately assess your risk tolerance and
          manage your assets and expectations within that tolerance.
          <br />
          <br />
          We take a holistic approach to investing. This means we analyze a
          broad spectrum of mainstream investments such as growth versus value,
          large versus medium versus small-cap equities, domestic versus
          international, high quality versus high yield bonds, the maturity and
          duration of bonds, and convertible bonds as well as the length of
          their maturity and their duration. We try to increase your income with
          comprehensive investment strategies.
          <br />
          <br />
          Our investment plan incorporates the concepts of the efficient
          frontier, which was developed by Nobel prize winner Harry Markowitz
          (Markowitz, 1952). By assembling a portfolio consisting of different
          assets and various asset classes, we attempt to reduce a portfolio's
          overall risk. This diversification can help reduce the variability and
          volatility of the returns, which may result in steadier performance
          over reasonable time frames. With potentially steadier performance, an
          investor may be more likely to stick with an investment program and be
          less likely to cash out during market downturns. If an investor’s
          emotion causes them to cash out during market downturns, they may lock
          in the current lower values. The investor may then forfeit their
          opportunity to have their portfolio recover when the stock market
          recovers. This emotional type of investing reduces your chance to
          succeed.
          <br />
          <br />
          We will help you understand and manage your investment risk to help
          you achieve your objectives. We believe portfolio downside risk
          management is crucial to help you potentially achieve your investment
          objectives. It may even be the most crucial aspect of your investment
          plan.
          <br />
          <br />
          We do not guarantee investment returns. Past performance is no
          guarantee of future performance.
          <br />
          <br />
          <br />
          References:
          <br />
          Kahneman, D. and Tversky, A. (1979). D. Prospect theory: An analysis
          of decision under risk, Econometrica, 47, 263-291.
          <br />
          Markowitz, H. M. (1952). Portfolio selection. The Journal of
          Finance, 46(2), 469-477.
        </p>
      </div>
    </section>
  );
};

export default InvestmentPlanning;
