import React from "react";
import styles from "./TrustPlanning.module.scss";

import image from "./../../assets/images/ourServicesTrust.jpg";

const TrustPlanning = () => {
  return (
    <section className={styles.trustPlanning}>
      <img src={image} alt="Trust Planning" />
      <h1>Trust Planning</h1>
      <div>
        <p>
          A revocable living trust document is a legal document established by
          an attorney for their client(s). The grantor (or settlor) is the
          client who hires the attorney to write the trust document (Rowe,
          2002). Typically, when a revocable living trust is created, the
          grantor is the initial trustee and the initial beneficiary. The
          initial trustee or successor trustee is the person that manages the
          trust assets for as long as the trust is in existence and the trustee
          is alive, competent, and wishes to remain as the trustee. During the
          grantor’s life, the grantor receives distributions from the trust and
          other benefits from trust assets as the initial beneficiary. A
          revocable living trust document is an estate planning tool that shifts
          ownership from personal ownership to legal ownership by the trustees
          for estate planning purposes such as avoiding probate, maintaining
          privacy, and other considerations.
          <br />
          <br />
          “A U.S. living trust is a legally existing and usually revocable trust
          with trustees who own the property which has been transferred to it by
          the grantor (i.e., settlor) and manages it on behalf of the grantor
          and any other beneficiaries. Whilst the grantor is living, the
          trustees (who may include the grantor) manage the trust property as
          the grantor may direct. This enables the grantor to retain control
          over the trust property and so ‘crown jewels’ can safely be held
          within the trust without loss of control. Upon the grantor’s death,
          the trustees either distribute the trust property to the named
          beneficiaries or continue to hold and manage it for their benefit
          according to the terms of the trust” (Stone, 2020).
          <br />
          <br />
          Trust planning can be described as “information intensive.” “If all
          the relevant data on a family’s situation is not accurately assembled
          before work begins, there is a high risk that the initial plans and
          the documents drafted to carry out the plans won’t be exactly right”
          (Dalsted et al., n.d.). You should ask yourself, “Does the attorney
          take the time to assemble and deliberate over all this information?”
          The attorney controls the outcome of the resulting estate plan because
          the client typically has “little to no knowledge of the legalities of
          a trust document” (Sharma, 1997). We call this “asymmetry of
          information.”
          <br />
          <br />
          Trust documents are not just for the wealthy. People have trusts
          created for several reasons, including avoiding probate, privacy,
          asset protection, and passing on property to heirs with specific
          guidance regarding who receives what, when, and how. If not created
          properly, a trust can become nightmarish to the grantors, trustees,
          and beneficiaries. We work with trust attorneys to help clients
          achieve their trust goals.
          <br />
          <br />
          Our financial and trust planning includes a free consultation with our
          clients to help collect the “important information” the trust attorney
          will need to create a “proper” trust document. There is no additional
          cost to our clients when we meet with them to help evaluate their
          potential trust needs. This in-depth meeting will help prepare our
          clients to identify their needs and concerns to the attorney creating
          their trust document. This client preparation meeting may save
          hundreds or thousands of dollars of attorney’s time. This meeting may
          make your consultation with the trust attorney more efficient and more
          productive. The attorney will draft your trust for approval. Upon your
          trust completion, we are happy to sit with you, our client, and review
          your new trust document as it relates to your overall financial plan.
          <br />
          <br />
          Do you have an existing trust document? “More than 4 million trusts
          are established each year, but 90% of them do not properly provide for
          surviving spouses and children” (Croke, 2000). Become a client and
          take advantage of our trust review service to help identify potential
          issues that may cause you to not properly provide for a surviving
          spouse or children.
          <br />
          <br />
          We are not attorneys. We do not practice law. We do not give legal
          advice. We simply ask questions to help you determine if what you want
          in your trust document is in your trust document. We approach trust
          planning from a financial planning perspective.
          <br />
          <br />
          <br />
          References:
          <br />
          Croke, F. (2000). Trust-Busters. Financial Planning magazine. March
          2000, pages 59–64. <br />
          Dalsted, N. L., & Sharp, R. L. Key Elements of an Estate Plan: An
          Overview. [n.d.] <br />
          Rowe, B. (2002). Estate Planning Basics: Revocable Living Trusts.{" "}
          <br />
          Stone, E. (2020). Dying trusts, living trusts, Trusts & Trustees,
          Volume 26, Issue 2, Pages 124–128.  <br />
          Sharma, A. (1997). Professional as agent: Knowledge asymmetry in
          agency exchange. Academy of Management Review, 22(3), 758–798. <br />
        </p>
      </div>
    </section>
  );
};

export default TrustPlanning;
