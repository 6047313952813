import React from "react";
import styles from "./Banner.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";

import img1 from "./../../assets/images/home1.jpg";
import img2 from "./../../assets/images/home2.jpg";
import img3 from "./../../assets/images/home3.jpg";
import img4 from "./../../assets/images/home4.jpg";

const Banner = () => {
  return (
    <section className={styles.bannerContainer}>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 12000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
      >
        <SwiperSlide className={styles.bannerImage}>
          <div className={styles.imageWrap}>
            <img src={img1} alt="1" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.imageWrap}>
            <img src={img2} alt="2" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.imageWrap}>
            <img src={img3} alt="3" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.imageWrap}>
            <img src={img4} alt="4" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className={styles.banner}>
        <div className={styles.bannerWrap}>
          <div className={styles.bannerTextConainer}>
            <span>Welcome to</span>
            <h1>Wells Investments!</h1>
            <p>
              Our mission: To create a positive and valuable client experience
              by enhancing their financial well-being through personalized
              strategies which reflect their unique financial needs and personal
              values.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
