import React, { useState, useEffect } from "react";
import styles from "../QuizScreen.module.scss";
import classNames from "classnames";

import sessionStorage from "../../../services/sessionStorageService";

import CheckBox from "../../../components/CheckBox/CheckBox";
import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";

const QuestionsSections = ({
  changeActiveSection,
  questions,
  loadedFromSession,
}) => {
  const [data, setData] = useState(questions);
  const [clickCounter, setClickCounter] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [nextQuestionAvailable, setNextQuestionAvailable] = useState(false);
  const [backBtnPressed, setBackBtnPressed] = useState(false);

  const session = sessionStorage;

  useEffect(() => {
    if (loadedFromSession) {
      setClickCounter((prevState) => prevState + 1);
      setData([...questions]);
      setActiveQuestion(data.length - 1);
      setNextQuestionAvailable(true);
    } else {
      if (activeQuestion === 0 && !backBtnPressed) {
        questions.forEach((el, index) => {
          if (index === 0) {
            el.answered = null;
          } else {
            el.answered = false;
          }

          el.answers.forEach((ans) => {
            ans.selected = false;
          });
        });
        setClickCounter((prevState) => prevState + 1);
        setData([...questions]);
      }
    }
  }, [activeQuestion]);

  const handleCheckedAnswer = (answer, questionIndex, answerIndex) => {
    let temp = [...data];
    let isSelectedAnswer = false;

    temp[questionIndex].answers[answerIndex].selected = answer;

    Object.keys(temp[questionIndex].answers).forEach((i) => {
      if (temp[questionIndex].answers[i].selected) {
        isSelectedAnswer = true;
      }
    });

    if (isSelectedAnswer) {
      setNextQuestionAvailable(true);
    } else {
      setNextQuestionAvailable(false);
    }

    setClickCounter((prevState) => prevState + 1);
    setData(temp);
  };

  const handleNextQuestion = () => {
    let temp = [...data];
    const questionIndex = activeQuestion;
    temp[questionIndex].answered = true;

    setClickCounter((prevState) => prevState + 1);
    setData(temp);
    session.setItem("section-1", temp);

    if (data.length === activeQuestion + 1) {
      changeActiveSection(data);

      return;
    }
    temp[questionIndex + 1].answered = null;
    setNextQuestionAvailable(false);
    setActiveQuestion(activeQuestion + 1);
    setBackBtnPressed(false);
  };

  const handleBack = () => {
    setBackBtnPressed(true);
    setActiveQuestion((prevState) => prevState - 1);
    let temp = [...data];

    const questionIndex = activeQuestion;
    temp[questionIndex].answered = false;
    Object.keys(temp[questionIndex].answers).forEach(
      (i) => (temp[questionIndex].answers[i].selected = false)
    );

    setData(temp);
    setNextQuestionAvailable(true);
  };

  return (
    <section className={styles.containerOutside}>
      <div className={styles.container}>
        <p className={styles.title}>
          After you answer a few, simple questions, we will provide you with a
          personalized set of resources. <br />
          This will also be helpful when starting a conversation with an
          advisor.
        </p>
        {data.map((q, i) =>
          q.answered || q.answered === null ? (
            <div key={i} className={styles.questionContainer}>
              <h1>{q.text}</h1>
              <span className={styles.subTitle}>
                Select the statements that apply to you.
              </span>
              <div className={styles.answersContainer}>
                {q.answers.map((answer, index) => (
                  <Options
                    key={`${i}-${index}-${clickCounter}`}
                    answer={answer.text}
                    checked={answer.selected}
                    handleCheckedAnswer={(a) =>
                      handleCheckedAnswer(a, i, index)
                    }
                  />
                ))}
              </div>
            </div>
          ) : null
        )}
        <div
          className={classNames(
            nextQuestionAvailable && activeQuestion === 0
              ? styles.btnContainerOneBtn
              : styles.btnContainerTwoBtns
          )}
        >
          {activeQuestion > 0 && (
            <Button
              title={"Previous Question"}
              classes={styles.backBtn}
              onClick={handleBack}
            />
          )}
          {nextQuestionAvailable && (
            <Button
              title={
                data.length === activeQuestion + 1 ? "Summary" : "Next question"
              }
              classes={styles.nextBtn}
              onClick={handleNextQuestion}
            />
          )}
        </div>
      </div>
    </section>
  );
};

const Options = ({ answer, checked, handleCheckedAnswer, disabled }) => {
  const [selected, setSelected] = useState(checked);

  const handleCheck = () => {
    setSelected(!selected);
    handleCheckedAnswer(!selected);
  };
  return (
    <Card classes={styles.answerCard} onClick={() => handleCheck()}>
      <p>{answer}</p>
      <CheckBox
        checked={selected}
        disabled={disabled}
        className={styles.checkbox}
        onClick={() => {}}
      />
    </Card>
  );
};

export default QuestionsSections;
