import React from "react";
import styles from "./EstatePlanning.module.scss";

import image from "./../../assets/images/ourServicesEstate.jpg";

const EstatePlanning = () => {
  return (
    <section className={styles.estatePlanning}>
      <img src={image} alt="Estate Planning" />
      <h1>Estate Planning</h1>
      <div>
        <p>
          “70 percent of estates come unglued after the estate transitions”
          (Preisser and Williams, 2010).
          <br />
          <br />
          Estate planning is a critical aspect of financial planning that often
          goes overlooked. If it is not done correctly, your estate plan may
          become “unglued.” Estate planning involves deciding how your assets
          will be distributed after your passing. You want to know that you have
          cared for your loved ones. When you prioritize estate planning, you
          may receive peace of mind knowing you have written documents on how
          your estate will be distributed upon your demise. You want to know
          that what you have goes to whom you want, in the way you want, and
          when you want. Poorly designed estate plans may result in the property
          of an estate ending up in the hands of the wrong people, maybe even
          people you do not know. If you want to learn how this could happen or
          how we may help you, contact us through this website.
          <br />
          <br />
          Several reasons make estate planning a financial priority. Without
          proper estate planning, your assets may be distributed according to
          state law, which may not align with your desires. Proper estate
          planning may allow your assets to avoid probate and potentially avoid
          some estate taxes for your beneficiaries. You may also want to control
          the cost of passing on your estate. Probate costs can run as much as
          5% or higher of the entire estate’s value. We believe reducing that
          cost to your heirs is important. We may be able to help you reduce
          probate costs to preserve more of your assets for your heirs.
          <br />
          <br />
          Maybe you have seen or heard of families fighting over money or
          property after the death of parents, grandparents, siblings, or other
          family members. Proper estate planning can significantly reduce, if
          not avoid, these family conflicts. We help our clients learn from
          documented cases of poor estate planning experienced by non-clients of
          Wells Investments. Poor estate planning can lead to severe family
          conflicts over inheritance. Wells Investments can help you avoid such
          conflicts by applying sound financial and estate planning concepts.
          Estate plans need to be clear and concise with little to no ambiguity.
          Once we learn about your goals, we can help you convey those goals to
          an estate planning attorney who will help you create the proper
          documents. If you are considering forming a trust, creating or
          modifying a will, or possibly designating a power of attorney, Wells
          Investments can help guide you through the process thanks to our years
          of professional experience and established professional relationships.
          Our professional relationships with accountants and attorneys may add
          peace of mind to the process. You will be educated, you will be
          informed, and for these reasons, you will have a better chance to get
          your estate planning documents done right the first time. The value we
          add in providing you with this exceptionally important information is
          significant. Our goal is to help our clients achieve the best outcome
          in their estate planning. We try to make sure our clients are properly
          taken care of in estate planning.
          <br />
          <br />
          Wells Investments, and companies we affiliate with, offer trust
          financial planning, trustee, and trust protector services. Our goal is
          to help provide exceptional service for anyone who is a stakeholder in
          a trust document (grantor, trustee, and beneficiary). Our trust
          services are designed to provide grantors, co-trustees, and
          beneficiaries with affordable alternatives to many current standard
          trustee arrangements. Trustee fees vary from state to state and
          typically range from 1% to 5% of the value of the assets in the trust
          during the initial trust administration and/or subsequent annual trust
          management. Compare those fees to the 1/4% to 2.0% that affiliated
          companies may typically charge. Consider the benefit of trust assets
          being potentially protected against litigation when you pass your
          assets on to your heirs. This depends on your state laws and the
          proper language inside your trust.
          <br />
          <br />
          For trusts with an existing trustee or if a grantor wishes to
          establish a trust with a specific trustee, a Wells Investments’
          affiliated company offers a trust protector service that can supervise
          the trustee and terminate a trustee for misconduct without having the
          cost of going to court and having a judge remove them for you. This
          helps to ensure the trustee does not withhold or delay benefits to
          trust beneficiaries or unreasonably tie up trust assets in legal
          disputes. A trust’s “trust protector” provision should be included in
          any new trust discussions or if trusts are being updated or amended.
          <br />
          <br />
          Wells Investments has the necessary experience and resources to help
          you and your family with these important trust and estate services
          under your financial planning. Estate planning is information
          intensive. We ask questions to help you determine what you want in
          your trust document. Our professionals have routinely worked with
          attorneys, tax professionals, and clients on estate planning. We use
          the knowledge gained from our decades of experience working with them
          to assist you in estate planning. We approach estate planning from a
          financial planning perspective, and we believe that adds value because
          it adds perspective. Our trust services are designed to help you have
          a pro-grantor, pro-trustee, and pro-beneficiary outcome to help avoid
          conflicts. Your assets belong to you and, later, to the beneficiaries
          you designate. You do not want strangers receiving your estate. We are
          not attorneys, and we do not give legal advice.
          <br />
          <br />
          <br />
          Reference:
          <br />
          Preisser, V., & Williams, R. (2010). The Future of Estate Planning.
          Trust and Estates, 43
        </p>
      </div>
    </section>
  );
};

export default EstatePlanning;
