import React from "react";
import styles from "./FamilyScreen.module.scss";

import img from "../../../assets/images/lifeFamily.jpg";
import { useSelector } from "react-redux";

import { selectLifePriorities } from "../../../store/slices/lifePriorities/slice";

const FamilyScreen = () => {
  const lifePriorites = useSelector(selectLifePriorities);
  return (
    <section className={styles.container}>
      <img src={img} alt="family" />
      <h1>Family</h1>
      {lifePriorites && (
        <div
          dangerouslySetInnerHTML={{
            __html: lifePriorites.find((element) => element.key === "family")
              ?.text,
          }}
        ></div>
      )}
    </section>
  );
};

export default FamilyScreen;
