import { createAsyncThunk } from "@reduxjs/toolkit";
import EventsService from "../../../api/services/events";

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  return await EventsService.fetchEvents();
});

export const fetchAllEvents = createAsyncThunk(
  "events/fetchAllEvents",
  async (requestData) => {
    const { search } = requestData;
    return await EventsService.fetchAllEvents(search);
  }
);

export const deleteEvents = createAsyncThunk(
  "events/deleteEvents",
  async (requestData) => {
    const { eventsId } = requestData;
    return await EventsService.deleteEvents(eventsId);
  }
);

export const createEvents = createAsyncThunk(
  "events/createEvents",
  async (requestData) => {
    return await EventsService.createEvents(requestData);
  }
);

export const editEvents = createAsyncThunk(
  "events/editEvents",
  async (requestData) => {
    return await EventsService.editEvents(requestData);
  }
);
