import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/quiz";

class QuizService {
  fetchQuestions = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}/questions`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { questions } = response.data.data;
      return { questions };
    } catch (err) {
      throw handleError(err, "Error occured while fetching questions.");
    }
  };

  fetchCategories = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}/categories`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { categories } = response.data.data;
      return { categories };
    } catch (err) {
      throw handleError(err, "Error occured while fetching categories.");
    }
  };

  fetchQuizResults = async (page, sortBy, order, search) => {
    try {
      if (!sortBy) {
        sortBy = "";
      }
      if (!order) {
        order = "ASC";
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/quizResults?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, "Error occured while fetching quizResults.");
    }
  };

  editQuestion = async (formData) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/question/${formData.id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing question.");
    }
  };

  editAnswer = async (formData) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/answer/${formData.id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing answer.");
    }
  };

  editCategory = async (formData) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/category/${formData.id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing category.");
    }
  };

  deleteQuizResult = async (id) => {
    try {
      const response = await axiosApiFreeClient.delete(`${BASE_URL}/${id}`);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting quiz result.");
    }
  };

  submitQuiz = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, "Error occured while submiting quiz.");
    }
  };
}

export default new QuizService();
