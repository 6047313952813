import { createSlice } from "@reduxjs/toolkit";
import {
  fetchResearchAndInsightBuilder,
  fetchAllResearchAndInsightBuilder,
  deleteResearchAndInsightBuilder,
  createResearchAndInsightBuilder,
} from "./builder";

const initialState = {
  researchAndInsight: {
    data: [],
  },
  createResearchAndInsight: false,
  deleteResearchAndInsight: false,
  fetchResearchAndInsight: false,
};

export const researchAndInsight = createSlice({
  name: "researchAndInsight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchResearchAndInsightBuilder(builder);
    fetchAllResearchAndInsightBuilder(builder);
    deleteResearchAndInsightBuilder(builder);
    createResearchAndInsightBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectResearchAndInsight = (state) =>
  state.researchAndInsight.researchAndInsight.data;
export const selectCreateResearchAndInsightPending = (state) =>
  state.researchAndInsight.createResearchAndInsight;
export const selectDeleteResearchAndInsightPending = (state) =>
  state.researchAndInsight.deleteResearchAndInsight;
export const selectEditResearchAndInsightPending = (state) =>
  state.researchAndInsight.editResearchAndInsight;

export default researchAndInsight.reducer;
