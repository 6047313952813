import React from "react";
import { Link } from "react-router-dom";
import styles from "./MainNav.module.scss";

import { ReactComponent as WellsLogo } from "../../assets/icons/wells-logo.svg";

const MainNav = ({ navigation, showSub }) => {
  return (
    <div className={styles.mainNavContainer}>
      <div className={styles.companyIdentityContainer}>
        <Link to="/">
          <WellsLogo className={styles.logo} />
        </Link>
        <div className={styles.companyName}>
          <Link to="/" className={styles.companyNameLink}>
            <span>Wells Investments</span>
          </Link>
          <a href="tel:+18632943361">863.294.3361</a>
        </div>
      </div>
      <nav className={styles.mainNav}>{navigation}</nav>
    </div>
  );
};

export default MainNav;
