import React from "react";
import styles from "./PrivateNavigation.module.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as WellsLogo } from "../../assets/icons/wells-logo.svg";

import { logout } from "../../store/slices/auth/asyncThunks";

const PrivateNavigation = () => {
  const dispatch = useDispatch();

  const makeLogout = () => {
    dispatch(logout());
  };

  return (
    <nav className={classNames(styles.navbar)}>
      <div className={styles.logoContainer}>
        <WellsLogo className={styles.logo} />
      </div>

      <ul className={classNames(styles.navbarContainer)}>
        <li>
          <NavLink
            to="/admin/contact-form?page=1"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Contact Forms</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/news"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>News</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/life-priorities"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Life Priorities</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/your-advisor-team"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Your advisor team</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/clients?page=1"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Clients</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/referrals?page=1"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Referrals</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/newsletter"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Newsletter</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/research-and-insights"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Research And Insights</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/events"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Events</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/documents-to-sign?page=1"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Documents to sign</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/quiz"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Quiz</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/quiz-results?page=1"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Quiz Results</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/categories"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            <div className={styles.activeNavLinkRectangle}></div>
            <span>Categories</span>
          </NavLink>
        </li>
      </ul>

      <div className={styles.bottomContainer}>
        <div className={styles.bottomContainerLogout}>
          <div onClick={makeLogout}>
            <Logout
              style={{ transform: "rotate(180deg)", marginBottom: "2px" }}
            />
            <span>LOGOUT</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PrivateNavigation;
