import React from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";
import spinner from "../../assets/images/spinner.gif";

const Button = ({
  children,
  classes,
  onClick,
  enabled,
  type,
  loading,
  title,
  iconLeft,
  iconRight,
}) => {
  return (
    <div className={classNames(styles.buttonContainer, classes)}>
      <button
        className={classNames(
          enabled && styles.disabled,
          classes,
          loading && styles.loadingContainer
        )}
        onClick={onClick}
        disabled={enabled || loading}
        type={type}
      >
        {loading ? (
          <img src={spinner} alt="loading-spinner" className={styles.spinner} />
        ) : iconLeft ? (
          <div className={styles.buttonTextContainer}>
            {iconLeft}
            {title}
          </div>
        ) : iconRight ? (
          <div className={styles.buttonTextContainer}>
            {title}
            {iconRight}
          </div>
        ) : !iconLeft && !iconRight ? (
          title
        ) : (
          ""
        )}
      </button>
    </div>
  );
};

export default Button;
