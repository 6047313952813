import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Table,
  TableItem,
  ModalContactForm,
  Modal,
} from "../../../components";
import styles from "./ContactFormScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron_right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";

import {
  selectContactForm,
  selectDeleteContactFormPending,
} from "../../../store/slices/contactForm/slice";
import {
  fetchContactForms,
  deleteContactForm,
} from "../../../store/slices/contactForm/asyncThunks";
import { notifyInfo } from "../../../utils/notify";

const ContactFormScreen = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  let navigate = useNavigate();

  const { data, perPage, total } = useSelector(selectContactForm);
  const pendingAction = useSelector(selectDeleteContactFormPending);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });
  const [selectedContactForm, setSelectedContactForm] = useState(null);

  const contactFormModal = useRef();
  const deleteContactFormModal = useRef();

  const tableHeader = [
    {
      key: "firstName",
      label: "Name",
      sortable: true,
    },
    {
      key: "email",
      label: "Email",
      sortable: true,
    },
    {
      key: "purpose",
      label: "Purpose",
      sortable: true,
    },
    {
      key: "assets",
      label: "Investable Assets",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const handleCloseCreateModal = () => {
    deleteContactFormModal.current.hide();
  };

  const handleOkModal = () => {
    dispatch(deleteContactForm({ contactFormId: selectedContactForm.id })).then(
      (promise) => {
        deleteContactFormModal.current.hide();
        notifyInfo("Contact form deleted.");
      }
    );
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/contact-form",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchContactForms({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) {
      dispatch(
        fetchContactForms({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
        })
      );
    }
    //eslint-disable-next-line
  }, [page, sortOptions, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          onChange={debouncedChangeHandler}
          placeholder="Search email"
        />
      </div>
      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((contactForm, index) => {
            return (
              <Fragment key={index}>
                <TableItem
                  data={contactForm.firstName + " " + contactForm.lastName}
                />
                <TableItem data={contactForm.email} />
                <TableItem data={contactForm.purpose} />
                <TableItem data={contactForm.assets} />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedContactForm(contactForm);
                          deleteContactFormModal.current.show();
                        }}
                      />
                      <ChevronIcon
                        className={styles.icon}
                        onClick={() => {
                          setSelectedContactForm(contactForm);
                          contactFormModal.current.show();
                        }}
                      />
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>

      <ModalContactForm
        title={"Contact form detail"}
        modalRef={contactFormModal}
        selectedContactForm={selectedContactForm}
      />
      <Modal
        ref={deleteContactFormModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
      >
        {"Delete contact form : " + selectedContactForm?.email}
      </Modal>
    </div>
  );
};

export default ContactFormScreen;
