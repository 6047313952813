import React from "react";
import styles from "./LifePriorities.module.scss";

const LifePriorities = () => {
  return (
    <div className={styles.container}>
      <h2>LifePriorities</h2>
    </div>
  );
};

export default LifePriorities;
