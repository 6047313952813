import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import classNames from "classnames";
import styles from "./ClientLayout.module.scss";
import { selectLoggedIn } from "../../store/slices/auth/slice";
import { selectLoggedInUserRole } from "../../store/slices/user/slice";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import ClientNavigation from "../../components/ClientNavigation/ClientNavigation";
import PublicNavigationTop from "../../components/PublicNavigation/PublicNavigationTop";
import ClientNavigationTopLogOut from "../../components/ClientNavigation/ClientNavigationTopLogOut";
import { ROLES } from "../../utils/constants";
const ClientLayout = () => {
  const isAuthenticated = useSelector(selectLoggedIn);

  const location = useLocation();

  const userRole = useSelector(selectLoggedInUserRole);

  return isAuthenticated && userRole !== ROLES.ADMIN ? (
    <div className={classNames(styles.container)}>
      <Header
        mainNavigation={<ClientNavigation />}
        topNavNavigation={
          isAuthenticated ? (
            <ClientNavigationTopLogOut />
          ) : (
            <PublicNavigationTop />
          )
        }
      />
      <Outlet />
      <Footer />
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ClientLayout;
