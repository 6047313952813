import { createAsyncThunk } from "@reduxjs/toolkit";
import AdvisorTeamService from "../../../api/services/advisorTeam";

export const fetchAdvisorTeam = createAsyncThunk(
  "advisorTeam/fetchAdvisorTeam",
  async () => {
    return await AdvisorTeamService.fetchAdvisorTeam();
  }
);

export const updateAdvisorTeam = createAsyncThunk(
  "advisorTeam/updateAdvisorTeam",
  async (requestData) => {
    let formData = new FormData();

    if (requestData.file) {
      formData.append("file", requestData.file);
    }

    formData.append("id", requestData.id);
    formData.append("name", requestData.name);
    formData.append("position", requestData.position);
    formData.append("school", requestData.school);
    formData.append("text", requestData.text);

    return await AdvisorTeamService.updateAdvisorTeam(formData);
  }
);
