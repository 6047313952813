import { fetchContactForms, deleteContactForm } from "./asyncThunks";

export const fetchContactFormsBuilder = (builder) => {
  builder.addCase(fetchContactForms.pending, (state) => {
    state.fetchContactForms = true;
  });
  builder.addCase(fetchContactForms.fulfilled, (state, action) => {
    state.fetchContactForms = false;
    state.contactForms.data = action.payload.data;
    state.contactForms.total = action.payload.total;
    state.contactForms.perPage = action.payload.perPage;
  });
  builder.addCase(fetchContactForms.rejected, (state) => {
    state.fetchContactForms = false;
  });
};

export const deleteContactFormBuilder = (builder) => {
  builder.addCase(deleteContactForm.pending, (state) => {
    state.deleteContactForm = true;
  });
  builder.addCase(deleteContactForm.fulfilled, (state, action) => {
    state.deleteContactForm = false;
    state.contactForms.data = state.contactForms.data.filter(
      (s) => s.id !== action.payload.data.result.id
    );
  });
  builder.addCase(deleteContactForm.rejected, (state) => {
    state.deleteContactForm = false;
  });
};
