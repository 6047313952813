import React from "react";
import styles from "./Footer.module.scss";

import { ReactComponent as WellsLogo } from "../../assets/icons/wells-logo.svg";

import {
  PublicNavigation,
  LifePrioritiesSubMenu,
  OurServicesSubMenu,
} from "../PublicNavigation/PublicNavigation.js";
import PublicNavigationTop from "../PublicNavigation/PublicNavigationTop";

const Footer = () => {
  return (
    <footer>
      <div className={styles.footerContainer}>
        <div className={styles.leftPart}>
          <div className={styles.companyDetails}>
            <WellsLogo className={styles.footerLogo} />
            <div className={styles.comapnyName}>
              <p>Wells Investments</p>
              <a href="tel:0018632943361">863.294.3361</a>
            </div>
          </div>
          <span className={styles.copy}>
            &copy; {new Date().getFullYear()} Wells Investments. All rights
            reserved.
          </span>
        </div>
        <div className={styles.footerNavigations}>
          <OurServicesSubMenu footer />
          <LifePrioritiesSubMenu footer />

          <nav className={styles.mainMenu}>
            <PublicNavigation footer />
            <PublicNavigationTop />
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
