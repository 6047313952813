import React from "react";
import classNames from "classnames";
import styles from "./Card.module.scss";

const Card = ({ children, classes, onClick }) => {
  return (
    <div
      className={classNames(styles.cardContainer, classes)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
