import {
  fetchDocumentToSign,
  fetchAllDocumentToSign,
  deleteDocumentToSign,
  createDocumentToSign,
  editDocumentToSign,
  fetchAllUserDocuments,
} from "./asyncThunks";

export const fetchAllDocumentToSignBuilder = (builder) => {
  builder.addCase(fetchAllDocumentToSign.pending, (state) => {
    state.fetchDocumentToSign = true;
  });
  builder.addCase(fetchAllDocumentToSign.fulfilled, (state, action) => {
    state.fetchDocumentToSign = false;
    state.documentToSign.data = action.payload.data;
    state.documentToSign.total = action.payload.total;
    state.documentToSign.perPage = action.payload.perPage;
  });
  builder.addCase(fetchAllDocumentToSign.rejected, (state) => {
    state.fetchDocumentToSign = false;
  });
};

export const fetchDocumentToSignBuilder = (builder) => {
  builder.addCase(fetchDocumentToSign.pending, (state) => {
    state.fetchDocumentToSign = true;
  });
  builder.addCase(fetchDocumentToSign.fulfilled, (state, action) => {
    state.fetchDocumentToSign = false;
    state.documentToSign.userDocuments = action.payload.data;
  });
  builder.addCase(fetchDocumentToSign.rejected, (state) => {
    state.fetchDocumentToSign = false;
  });
};

export const deleteDocumentToSignBuilder = (builder) => {
  builder.addCase(deleteDocumentToSign.pending, (state) => {
    state.deleteDocumentToSign = true;
  });
  builder.addCase(deleteDocumentToSign.fulfilled, (state, action) => {
    state.deleteDocumentToSign = false;
    state.documentToSign.data = state.documentToSign.data.filter((s) => {
      if (s.id !== action.payload.data.result.id) {
        return true;
      }
      return false;
    });
    state.allUserDocuments.data = state.allUserDocuments.data.filter((s) => {
      if (s.id !== action.payload.data.result.id) {
        return true;
      }
      return false;
    });
  });
  builder.addCase(deleteDocumentToSign.rejected, (state) => {
    state.deleteDocumentToSign = false;
  });
};

export const createDocumentToSignBuilder = (builder) => {
  builder.addCase(createDocumentToSign.pending, (state) => {
    state.createDocumentToSign = true;
  });
  builder.addCase(createDocumentToSign.fulfilled, (state, action) => {
    state.createDocumentToSign = false;
    state.documentToSign.data.unshift(action.payload.data.documentToSign);
  });
  builder.addCase(createDocumentToSign.rejected, (state) => {
    state.createDocumentToSign = false;
  });
};

export const editDocumentToSignBuilder = (builder) => {
  builder.addCase(editDocumentToSign.pending, (state) => {
    state.editDocumentToSign = true;
  });
  builder.addCase(editDocumentToSign.fulfilled, (state, action) => {
    state.editDocumentToSign = false;
    state.documentToSign.data = state.documentToSign.data.map((s) => {
      if (s.id === action.payload.data.documentToSign.id) {
        return action.payload.data.documentToSign;
      } else {
        return s;
      }
    });
    state.allUserDocuments.data = state.allUserDocuments.data.map((s) => {
      if (s.id === action.payload.data.documentToSign.id) {
        return action.payload.data.documentToSign;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editDocumentToSign.rejected, (state) => {
    state.editDocumentToSign = false;
  });
};

export const fetchAllUserDocumentsBuilder = (builder) => {
  builder.addCase(fetchAllUserDocuments.pending, (state) => {
    state.fetchAllUserDocuments = true;
  });
  builder.addCase(fetchAllUserDocuments.fulfilled, (state, action) => {
    state.fetchAllUserDocuments = false;
    // state.fetchAllUserDocuments.id = action.payload.data.result.id;
    state.allUserDocuments.data = action.payload.data;
    state.allUserDocuments.total = action.payload.total;
    state.allUserDocuments.perPage = action.payload.perPage;
    state.allUserDocuments.client = action.payload.client;
  });
  builder.addCase(fetchAllUserDocuments.rejected, (state) => {
    state.fetchAllUserDocuments = false;
  });
};
