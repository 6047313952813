import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import styles from "./LoginScreen.module.scss";
import { notifyError } from "../../../utils/notify";

import Button from "../../../components/Button/Button";
import Card from "../../../components/Card/Card";
import Input from "../../../components/Input/Input";

import { logout } from "../../../store/slices/auth/asyncThunks";
import { login } from "../../../store/slices/auth/asyncThunks";
import { selectLoggedIn } from "../../../store/slices/auth/slice";
import { selectLoggedInUserRole } from "../../../store/slices/user/slice";
import { ROLES } from "../../../utils/constants";

import { isValidEmail } from "../../../utils/util";
import { ReactComponent as WellsLogo } from "../../../assets/icons/wells-logo.svg";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isAuthenticated = useSelector(selectLoggedIn);
  const userRole = useSelector(selectLoggedInUserRole);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const validateInputData = useMemo(() => {
    if (
      email.trim().length > 0 &&
      password.trim().length > 0 &&
      isValidEmail(email)
    ) {
      return true;
    } else {
      return false;
    }
  }, [email, password]);

  const makeLogin = useCallback(() => {
    dispatch(login({ email, password })).then((promise) => {
      if (promise.payload) {
        if (promise.payload.user.role === ROLES.ADMIN) {
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate("/admin/contact-form");
          }
        } else {
          notifyError("User doesn't have permissions");
          dispatch(logout());
        }
      } else {
        notifyError("Wrong email or password");
      }
    });
  }, [dispatch, email, location, navigate, password]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (validateInputData) {
          makeLogin();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [makeLogin, validateInputData]);

  if (!isAuthenticated || userRole === ROLES.USER) {
    return (
      <div className={styles.loginScreenContainer}>
        <WellsLogo className={styles.logo} />
        <div className={styles.card}>
          <Card>
            <div className={styles.loginCardContainer}>
              <h1>Welcome!</h1>
              <h2>Sign in</h2>

              <Input
                placeholder="Email"
                onChange={(event) => setEmail(event)}
              ></Input>
              <Input
                placeholder="Password"
                type="password"
                onChange={(event) => setPassword(event)}
              ></Input>

              <div className={styles.buttonContainer}>
                <Button
                  aria-label="Login"
                  title="Sign In"
                  enabled={!validateInputData}
                  onClick={makeLogin}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/admin/contact-form?page=1" />;
  }
};

export default LoginScreen;
