import React, { useRef } from "react";
import styles from "./FileUpload.module.scss";
import classNames from "classnames";

const FileUpload = ({
  textUploadFile,
  classNameContainer,
  setFile,
  setFileName,
}) => {
  const handleImagePreview = async (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      setFile(file);
      setFileName(fileName);
    }
  };
  const fileInput = useRef();

  const onEditFileClick = () => {
    fileInput.current.click();
  };
  return (
    <div className={classNames(styles.fileUploadContainer, classNameContainer)}>
      <input
        className={styles.imageLogoInput}
        ref={fileInput}
        type="file"
        onChange={handleImagePreview}
      />
      <p className={styles.imageLogoLoad} onClick={onEditFileClick}>
        {textUploadFile}
      </p>
    </div>
  );
};

export default FileUpload;
