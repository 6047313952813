import {
  fetchUsers,
  fetchAllUsers,
  deleteUser,
  createUser,
  updateUser,
} from "./asyncThunks";

export const fetchUsersBuilder = (builder) => {
  builder.addCase(fetchUsers.pending, (state) => {
    state.fetchUsersPending = true;
  });
  builder.addCase(fetchUsers.fulfilled, (state, action) => {
    state.fetchUsersPending = false;
    state.clients.data = action.payload.data;
    state.clients.total = action.payload.total;
    state.clients.perPage = action.payload.perPage;
  });
  builder.addCase(fetchUsers.rejected, (state) => {
    state.fetchUsersPending = false;
  });
};

export const fetchAllUsersBuilder = (builder) => {
  builder.addCase(fetchAllUsers.pending, (state) => {
    state.fetchUsersPending = true;
  });
  builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
    state.fetchUsersPending = false;
    state.clients.allClients = action.payload.data;
  });
  builder.addCase(fetchAllUsers.rejected, (state) => {
    state.fetchUsersPending = false;
  });
};

export const deleteUsersBuilder = (builder) => {
  builder.addCase(deleteUser.pending, (state) => {
    state.deleteUserPending = true;
  });
  builder.addCase(deleteUser.fulfilled, (state, action) => {
    state.deleteUserPending = false;
    state.clients.data = state.clients.data.filter(
      (s) => s.id !== action.payload.data.result.id
    );
  });
  builder.addCase(deleteUser.rejected, (state) => {
    state.deleteUserPending = false;
  });
};

export const createUserBuilder = (builder) => {
  builder.addCase(createUser.pending, (state) => {
    state.createUserPending = true;
  });
  builder.addCase(createUser.fulfilled, (state, action) => {
    state.createUserPending = false;
    state.clients.data.unshift(action.payload.data.user);
  });
  builder.addCase(createUser.rejected, (state) => {
    state.createUserPending = false;
  });
};

export const updateUserBuilder = (builder) => {
  builder.addCase(updateUser.pending, (state) => {
    state.updateUserPending = true;
  });
  builder.addCase(updateUser.fulfilled, (state, action) => {
    state.updateUserPending = false;
    state.clients.data = state.clients.data.map((s) => {
      if (s.id === action.payload.data.user.id) {
        return action.payload.data.user;
      } else {
        return s;
      }
    });
  });
  builder.addCase(updateUser.rejected, (state) => {
    state.updateUserPending = false;
  });
};
