import { createAsyncThunk } from "@reduxjs/toolkit";
import ReferralService from "../../../api/services/referral";

export const fetchReferrals = createAsyncThunk(
  "referrals/fetchReferrals",
  async (requestData) => {
    const { page, sortBy, order, search } = requestData;
    return await ReferralService.fetchReferrals(page, sortBy, order, search);
  }
);

export const deleteReferral = createAsyncThunk(
  "referrals/deleteReferrals",
  async (requestData) => {
    const { referralId } = requestData;
    return await ReferralService.deleteReferral(referralId);
  }
);
