import { login, logout } from "../auth/asyncThunks";
import { fetchUserInfo, editUserInfo } from "./asyncThunks";
import { initialState, userSlice } from "./slice";

export const loginBuilder = (builder) => {
  builder.addCase(login.fulfilled, (state, action) => {
    userSlice.caseReducers.setUserInfo(state, action);
  });
};

export const editUserInfoBuilder = (builder) => {
  builder.addCase(editUserInfo.pending, (state) => {
    state.editUserInfoPending = true;
  });
  builder.addCase(editUserInfo.fulfilled, (state, action) => {
    userSlice.caseReducers.editUserInfo(state, action);
    state.editUserInfoPending = false;
  });
  builder.addCase(editUserInfo.rejected, (state) => {
    state.editUserInfoPending = false;
  });
};

export const fetchUserInfoBuilder = (builder) => {
  builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
    userSlice.caseReducers.setUserInfo(state, action);
  });
};

export const logoutBuilder = (builder) => {
  builder.addCase(logout.fulfilled, () => {
    return initialState;
  });
  builder.addCase(logout.rejected, () => {
    return initialState;
  });
};
