import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate } from "react-router-dom";

import sessionStorage from "../../services/sessionStorageService";

import QuestionsSections from "./QuestionsSection/QuestionsSection";
import PrioritiesScren from "./PrioritiesSection/PrioritiesSection";
import ResultsSection from "./ResultsSection/ResultsSection";
import ContinueTestSection from "./ContinueTestSection/ContinueTestSection";

import {
  selectQuestion,
  selectCategories,
} from "../../store/slices/quiz/slice";
import {
  fetchQuestions,
  fetchCategories,
} from "../../store/slices/quiz/asyncThunks";

const QuizScreen = () => {
  const [activeSection, setActiveSection] = useState(1);
  const [topPriorities, setTopPriorities] = useState([]);
  const [results, setResults] = useState([]);
  const [counter, setCounter] = useState(0);

  const [loadedFromSession, setLoadedFromSession] = useState(false);

  const session = sessionStorage;

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const questions = useSelector(selectQuestion);
  const categories = useSelector(selectCategories);

  useEffect(() => {
    dispatch(fetchQuestions());
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (!params.id) {
      session.removeItem("section-1");
      session.removeItem("top-order");
      navigate("/quiz/1");
    } else {
      let tmp = [];
      const sectionData = session.getItem("section-1");
      if (sectionData) {
        setLoadedFromSession(true);
        tmp = JSON.parse(sectionData);

        setCounter((prevState) => prevState + 1);
        setResults(tmp);
      } else {
        setLoadedFromSession(false);
        setCounter((prevState) => prevState + 1);
        setResults(JSON.parse(JSON.stringify(questions.slice(0, 3))));
      }
      setActiveSection(parseInt(params.id));

      const orderCategories = session.getItem("top-order");
      if (orderCategories) {
        setTopPriorities(JSON.parse(orderCategories));
      } else {
      }
    }
  }, [params, questions]);

  const computeTopPriorities = () => {
    let count = {
      Finances: 0,
      Health: 0,
      Leisure: 0,
      Work: 0,
      Home: 0,
      Family: 0,
    };

    results.forEach((q) => {
      q.answers.forEach((a) => {
        if (a.selected) {
          count[a.category.name] += 1;
        }
      });
    });

    let sortable = [];
    for (var item in count) {
      sortable.push([item, count[item]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    let finalList = [];

    sortable.forEach((item) => {
      finalList.push(categories.find((c) => c.name === item[0]));
    });
    setTopPriorities(finalList);
    session.setItem("top-order", finalList);
  };
  return (
    <>
      {activeSection === 1 && results.length > 0 ? (
        <QuestionsSections
          key={`${counter}`}
          loadedFromSession={loadedFromSession}
          changeActiveSection={(answers) => {
            setActiveSection((prevState) => prevState + 1);
            setResults(answers);
            computeTopPriorities();
            navigate("/quiz/2");
          }}
          questions={results}
        />
      ) : null}
      {activeSection === 2 ? (
        <PrioritiesScren
          topPrioritiesList={topPriorities}
          changeActiveSection={(val) => {
            setActiveSection((prevState) => prevState + 1);
            setTopPriorities([...val]);
            navigate("/quiz/3");
          }}
        />
      ) : null}
      {activeSection === 3 ? (
        <ResultsSection
          changeActiveSection={(val) => {
            setActiveSection(val);
            navigate(`/quiz/${parseInt(val)}`);
          }}
          topPrioritiesList={topPriorities}
        />
      ) : null}
      {activeSection === 4 ? (
        <ContinueTestSection
          topPrioritiesList={topPriorities}
          changeActiveSection={(val) => {
            setActiveSection(3);
            setTopPriorities([...val]);
            navigate(`/quiz/3`);
          }}
        />
      ) : null}
    </>
  );
};

export default QuizScreen;
