import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "../../store/slices/auth/asyncThunks";
import { notifyInfo } from "../../utils/notify";

const ClientNavigationTopLogOut = () => {
  const dispatch = useDispatch();

  const makeLogout = () => {
    dispatch(logout());
    notifyInfo("Successfully logged out.");
  };
  return (
    <ul>
      <li>
        <Link to="/#speak-with-an-advisor">Contact Us</Link>
      </li>
      <li>
        <Link to="/" onClick={makeLogout}>
          Log Out
        </Link>
      </li>
    </ul>
  );
};

export default ClientNavigationTopLogOut;
