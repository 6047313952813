import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SearchInput, Card, Modal, Input } from "../../../components";
import styles from "./NewsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyInfo } from "../../../utils/notify";
import debounce from "lodash.debounce";
import classNames from "classnames";
import { dateToString, isoToDate } from "../../../utils/util";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import {
  selectNews,
  selectDeleteNewsPending,
  selectCreateNewsPending,
  selectEditNewsPending,
} from "../../../store/slices/news/slice";
import {
  fetchNews,
  deleteNews,
  createNews,
  editNews,
} from "../../../store/slices/news/asyncThunks";

const NewsScreen = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [category, setCategory] = useState("");

  const dispatch = useDispatch();
  const news = useSelector(selectNews);

  const pendingDeleteAction = useSelector(selectDeleteNewsPending);
  const pendingCreateAction = useSelector(selectCreateNewsPending);
  const pendingEditAction = useSelector(selectEditNewsPending);

  const createNewsModal = useRef();
  const deleteNewsModal = useRef();

  const changeHandler = useCallback(
    (text) => {
      dispatch(fetchNews({ search: text }));
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchNews({ search: "" }));
  }, [dispatch]);

  const handleCreateNews = () => {
    createNewsModal.current.show();
  };

  const handleEditNewsletter = (item) => {
    setTitle(item.title);
    setCategory(item.category);
    setId(item.id);
    setPublishDate(dateToString(new Date(item.publishDate)));
    setDescription(item.text);

    createNewsModal.current.show();
  };

  const handleCloseCreateModal = () => {
    resetInputFields();
    createNewsModal.current.hide();
  };

  const handleDeleteNews = (item) => {
    setId(item.id);
    setTitle(item.title);

    deleteNewsModal.current.show();
  };

  const handleDeleteOkModal = () => {
    dispatch(deleteNews({ newsId: id })).then((promise) => {
      deleteNewsModal.current.hide();
      notifyInfo("News deleted.");
    });
  };

  const handleDeleteCloseModal = () => {
    deleteNewsModal.current.hide();
  };

  const resetInputFields = () => {
    setTitle("");
    setCategory("");
    setDescription("");
    setPublishDate("");
    setId("");
  };

  const handleOkModal = () => {
    if (!id) {
      dispatch(
        createNews({ category, text: description, title, publishDate })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createNewsModal.current.hide();
          notifyInfo("News successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    } else {
      dispatch(
        editNews({ id, category, text: description, title, publishDate })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createNewsModal.current.hide();
          notifyInfo("News successfully edited");
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <SearchInput onChange={debouncedChangeHandler} placeholder="Search" />
        </div>
        <button className={styles.createNewsBtn} onClick={handleCreateNews}>
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.newsCardContainer}>
        {news?.map((item) => (
          <Card
            classes={classNames(
              styles.categories,
              item.deletedAt ? styles.deletedNews : ""
            )}
            key={item.id}
          >
            <div>
              <span>{item.category}</span>
              <span>{isoToDate(item.publishDate)}</span>
            </div>
            <h2>{item.title}</h2>
            <div className={styles.cardContent}>
              <p>{item.text}</p>

              {!item.deletedAt && (
                <div className={styles.actionBtns}>
                  <div
                    className={styles.editIcon}
                    onClick={() => handleEditNewsletter(item)}
                  >
                    <EditIcon fill="#fff" />
                  </div>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => handleDeleteNews(item)}
                  >
                    <DeleteIcon fill="#fff" />
                  </div>
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={createNewsModal}
        title={id ? "Edit a news" : "Create a new news"}
        showCancelButton
        showOkButton
        buttonOkText={id ? "Edit" : "Create"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={id ? pendingEditAction : pendingCreateAction}
      >
        <Input
          placeholder={"Title"}
          name={"title"}
          classes={styles.inputContainer100}
          onChange={(event) => setTitle(event)}
          value={title}
        />
        <textarea
          placeholder={"Description"}
          name={"description"}
          className={styles.inputContainer100}
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
        <Input
          placeholder={"Publish date"}
          name={"publish-date"}
          type={"date"}
          classes={styles.inputContainer100}
          onChange={(event) => setPublishDate(event)}
          value={publishDate}
        />
        <Input
          placeholder={"Category"}
          name={"category"}
          classes={styles.inputContainer100}
          onChange={(event) => setCategory(event)}
          value={category}
        />
      </Modal>
      <Modal
        ref={deleteNewsModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingDeleteAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleDeleteCloseModal}
        buttonOkOnClick={handleDeleteOkModal}
      >
        {"Delete news with title : " + title}
      </Modal>
    </div>
  );
};

export default NewsScreen;
