import React from "react";
import styles from "./MutualFundScreen.module.scss";

import { Card } from "../../../components";

import { ReactComponent as NetxInvestor } from "../../../assets/icons/site_logo.svg";
import { ReactComponent as JohnHancock } from "../../../assets/icons/John_Hancock.svg";
import { ReactComponent as Venerable } from "../../../assets/icons/Venerable_Logo for Aleks.svg";

import Pimco from "../../../assets/images/pimco.png";
import Franklin from "../../../assets/images/FranklinTempleton.jpeg";
import Cgaf from "../../../assets/images/cgaf.jpg";
import Invesco from "../../../assets/images/invesco.png";
import Hermes from "../../../assets/images/federatedHermes.png";
import classNames from "classnames";

const MutualFundScreen = () => {
  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section className={styles.mutualFundContainer}>
      <h1 className={styles.mutualFundTitle}>Mutual Fund Prospectuses</h1>
      <div className={styles.partnersContainerFirst}>
        <Card classes={classNames(styles.partnersCard, styles.shiftLeft)}>
          <img
            src={Cgaf}
            alt={"banner"}
            className={styles.imgPointer}
            onClick={() => {
              openLink(
                "https://www.capitalgroup.com/individual/investments/prospectuses-and-reports.htm"
              );
            }}
          />
        </Card>
        <Card classes={styles.partnersCard}>
          <img
            src={Hermes}
            alt={"banner"}
            className={styles.imgPointer}
            onClick={() => {
              openLink(
                "https://www.federatedinvestors.com/product-info/prospectuses-and-regulatory-reports.do"
              );
            }}
          />
        </Card>
      </div>
      <div className={styles.partnersContainer}>
        <Card classes={styles.partnersCard}>
          <img
            src={Franklin}
            alt={"banner"}
            className={styles.imgPointer}
            onClick={() => {
              openLink(
                "https://www.franklintempleton.com/investments/options/mutual-funds?filter=SHARE_CLASS_NAME:Class%20A"
              );
            }}
          />
        </Card>
        <Card classes={styles.partnersCard}>
          <img
            src={Invesco}
            alt={"banner"}
            className={styles.imgPointer}
            onClick={() => {
              openLink(
                "https://www.invesco.com/us/financial-products/prospectus?audienceType=investors"
              );
            }}
          />
        </Card>
        <Card classes={styles.partnersCard}>
          <img
            src={Pimco}
            alt={"banner"}
            className={styles.imgPointer}
            onClick={() => {
              openLink("https://www.pimco.com/en-us/investments/mutual-funds/");
            }}
          />
        </Card>
      </div>
    </section>
  );
};

export default MutualFundScreen;
