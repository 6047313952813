import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import News from "../../components/News/News";
import TheWellsClients from "../../components/TheWellsClients/TheWellsClients";
import SpeakWithAnAdvisor from "../../components/SpeakWithAnAdvisor/SpeakWithAnAdvisor";
import DisclaimerAndDisclosure from "../../components/DisclaimerAndDisclosure/DisclaimerAndDisclosure";

const HomeScreen = () => {
  const location = useLocation();

  useEffect(() => {
    if (location?.hash?.length > 0) {
      const element = document.getElementById("speak-with-an-advisor");

      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [location]);
  return (
    <>
      <Banner />
      <News />
      <TheWellsClients />
      <div id={"speak-with-an-advisor"}>
        <SpeakWithAnAdvisor />
      </div>
      <DisclaimerAndDisclosure />
    </>
  );
};

export default HomeScreen;
