import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/newsletter";

class NewsletterService {
  fetchNewsletters = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { newsletter } = response.data.data;
      return { newsletter };
    } catch (err) {
      throw handleError(err, "Error occured while fetching newsletter.");
    }
  };

  fetchNewsletter = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { newsletter } = response.data.data;
      return { newsletter };
    } catch (err) {
      throw handleError(err, "Error occured while fetching newsletter.");
    }
  };

  fetchAllNewsletter = async (search) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/all?search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      console.log();
      const { newsletter } = response.data.data;
      return { newsletter };
    } catch (err) {
      throw handleError(err, "Error occured while fetching all newsletter.");
    }
  };

  deleteNewsletter = async (newsletterId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/${newsletterId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting newsletter.");
    }
  };

  createNewsletter = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while creating newsletter.");
    }
  };

  editNewsletter = async (formData) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/${formData.get("id")}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing newsletter.");
    }
  };
}

export default new NewsletterService();
