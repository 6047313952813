import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ResultsSection.module.scss";
import { notifyInfo } from "../../../utils/notify";

import bannerImage from "../../../assets/images/img1.jpg";

import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";
import Form from "../../../components/Form/Form";
import DropDownMenuCustom from "../../../components/DropDownMenuCustom/DropDownMenuCustom";
import { Modal, Input } from "../../../components";

import usaStates from "../../../assets/json/states.json";

import { icons, purposes, investableAssets } from "../../../utils/constants";

import QuizService from "../../../api/services/quiz";

const ResultsSection = ({ topPrioritiesList, changeActiveSection }) => {
  const submitModal = useRef();
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [purpose, setPurpose] = useState("");
  const [assets, setAssets] = useState("");
  const [note, setNote] = useState("");

  const [submitBtnEbabled, setSubmitBtnEbabled] = useState(false);

  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      address !== "" &&
      state !== "" &&
      purpose !== "" &&
      assets !== ""
    ) {
      setSubmitBtnEbabled(true);
    } else {
      setSubmitBtnEbabled(false);
    }
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
    state,
    purpose,
    assets,
  ]);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setState("");
    setPurpose("");
    setAssets("");
    setNote("");
  };

  const confirmAction = async (e) => {
    e.preventDefault();

    await QuizService.submitQuiz({
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      assets,
      purpose,
      note,
      state,
      result: topPrioritiesList[0].name + ", " + topPrioritiesList[1].name,
    })
      .then((res) => {
        resetForm();
        notifyInfo("Successfully sent");
        submitModal.current.hide();
        navigate({
          pathname: "/",
        });
        window.scrollTo(0, 0);
      })
      .finally();
    e.target.reset();
  };

  const handleCloseModal = () => {
    resetForm();
    submitModal.current.hide();
  };

  return (
    <>
      <img src={bannerImage} alt={"banner"} className={styles.bannerImage} />
      <section className={styles.container}>
        <h1>Your Results</h1>

        {topPrioritiesList?.slice(0, 2).map((item, index) => (
          <div className={styles.results} key={index}>
            <Card classes={styles.resultsCard}>
              <div>{icons[item.name]}</div>
              <h2>{item.name}</h2>
            </Card>
            <p>{item.description}</p>
          </div>
        ))}

        <div className={styles.actionsContainer}>
          <Button
            title={"Back"}
            classes={styles.backBtn}
            onClick={() => changeActiveSection(2)}
          />
          <div className={styles.actionSubmitContinue}>
            <Button
              title={"Tell us more about you"}
              onClick={() => changeActiveSection(4)}
              classes={styles.btnContinueTest}
            />
            <Button
              title={"Submit Answers"}
              onClick={() => submitModal.current.show()}
              classes={styles.btnSubmit}
            />
          </div>
        </div>
        <Modal
          ref={submitModal}
          title={""}
          showCancelButton={false}
          showOkButton={false}
          onClose={handleCloseModal}
          modalStyle={styles.modalStyle}
          modalHeader
          modalFooter
          closeIcon
          closeIconStyle={styles.closeIconStyle}
          modalInnerStyle={styles.modalInnerStyle}
        >
          <div className={styles.formWrapper}>
            <h2>Speak With An Advisor</h2>
            <Form onSubmit={confirmAction} classes={styles.formContainer}>
              <Input
                required={true}
                placeholder={"First Name"}
                name={"first-name"}
                classes={styles.inputContainer50}
                onChange={(event) => setFirstName(event)}
              />
              <Input
                required={true}
                placeholder={"Last Name"}
                name={"last-name"}
                classes={styles.inputContainer50}
                onChange={(event) => setLastName(event)}
              />
              <Input
                required={true}
                placeholder={"Email Address"}
                name={"email"}
                classes={styles.inputContainer50}
                onChange={(event) => setEmail(event)}
              />
              <Input
                required={true}
                placeholder={"Phone Number"}
                name={"phone-number"}
                classes={styles.inputContainer50}
                onChange={(event) => setPhoneNumber(event)}
              />
              <Input
                required={true}
                placeholder={"Address"}
                name={"address"}
                classes={styles.inputContainer50}
                onChange={(event) => setAddress(event)}
              />
              <DropDownMenuCustom
                required
                placeholder={"State of residence"}
                options={usaStates}
                onChange={(event) => setState(event)}
                classes={styles.inputContainer50}
              />
              <DropDownMenuCustom
                required
                placeholder={"Purpose of the Contact"}
                options={purposes}
                onChange={(event) => setPurpose(event)}
                classes={styles.inputContainer50}
              />
              <DropDownMenuCustom
                required
                placeholder={"Investable Assets"}
                options={investableAssets}
                onChange={(event) => setAssets(event)}
                classes={styles.inputContainer50}
              />
              <textarea
                placeholder={"Leave a note"}
                name={"note"}
                className={styles.inputContainer100}
                onChange={(event) => setNote(event.target.value)}
              />
              <Button
                enabled={!submitBtnEbabled}
                type={"submit"}
                classes={styles.submitButton}
                title={"Submit"}
              />
              <div className={styles.speakWithAnAdvisorDisclaimer}>
                <h3>Disclosure</h3>
                <p>
                  I certify that I am the person that is identified in the
                  information entered above, and I give Wells Investments, Inc.
                  permission to contact me by email or telephone.
                </p>
              </div>
            </Form>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default ResultsSection;
