import {
  fetchQuestions,
  fetchCategories,
  editQuestion,
  editCategory,
  editAnswer,
  fetchQuizResults,
  deleteQuizResult,
} from "./asyncThunks";

export const fetchQuestionsBuilder = (builder) => {
  builder.addCase(fetchQuestions.pending, (state) => {});
  builder.addCase(fetchQuestions.fulfilled, (state, action) => {
    state.questions.data = action.payload.questions;

    state.questions.data = state.questions.data.map((q) => {
      q.answers = q.answers.map((a) => {
        return { ...a, selected: false };
      });
      q.answered = null;
      return q;
    });
  });
  builder.addCase(fetchQuestions.rejected, (state) => {});
};

export const fetchCategoriesBuilder = (builder) => {
  builder.addCase(fetchCategories.pending, (state) => {});
  builder.addCase(fetchCategories.fulfilled, (state, action) => {
    state.categories.data = action.payload.categories;
  });
  builder.addCase(fetchCategories.rejected, (state) => {});
};

export const fetchQuizResultsBuilder = (builder) => {
  builder.addCase(fetchQuizResults.pending, (state) => {});
  builder.addCase(fetchQuizResults.fulfilled, (state, action) => {
    state.quizResults.data = action.payload.data;
    state.quizResults.total = action.payload.total;
    state.quizResults.perPage = action.payload.perPage;
  });
  builder.addCase(fetchQuizResults.rejected, (state) => {});
};

export const editQuestionBuilder = (builder) => {
  builder.addCase(editQuestion.pending, (state) => {
    state.editQuestion = true;
  });
  builder.addCase(editQuestion.fulfilled, (state, action) => {
    state.editQuestion = false;
    state.questions.data = state.questions.data.map((s) => {
      if (s.id === action.payload.data.question.id) {
        return action.payload.data.question;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editQuestion.rejected, (state) => {
    state.editQuestion = false;
  });
};

export const editCategoryBuilder = (builder) => {
  builder.addCase(editCategory.pending, (state) => {
    state.editCategory = true;
  });
  builder.addCase(editCategory.fulfilled, (state, action) => {
    state.editCategory = false;
    state.categories.data = state.categories.data.map((s) => {
      if (s.id === action.payload.data.category.id) {
        return action.payload.data.category;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editCategory.rejected, (state) => {
    state.editCategory = false;
  });
};

export const editAnswerBuilder = (builder) => {
  builder.addCase(editAnswer.pending, (state) => {
    state.editAnswer = true;
  });
  builder.addCase(editAnswer.fulfilled, (state, action) => {
    state.editAnswer = false;
    state.questions.data = state.questions.data.map((s) => {
      if (s.id === action.payload.data.answer.questionId) {
        s.answers = s.answers.map((a) => {
          if (a.id === action.payload.data.answer.id) {
            return {
              ...a,
              text: action.payload.data.answer.text,
              category: action.payload.data.category,
            };
          } else {
            return a;
          }
        });
        return s;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editAnswer.rejected, (state) => {
    state.editAnswer = false;
  });
};

export const deleteQuizResultBuilder = (builder) => {
  builder.addCase(deleteQuizResult.pending, (state) => {
    state.deleteQuizResult = true;
  });
  builder.addCase(deleteQuizResult.fulfilled, (state, action) => {
    state.deleteQuizResult = false;
    state.quizResults.data = state.quizResults.data.filter(
      (s) => s.id !== action.payload.data.result.id
    );
  });
  builder.addCase(deleteQuizResult.rejected, (state) => {
    state.deleteQuizResult = false;
  });
};
