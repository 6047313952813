import { createAsyncThunk } from "@reduxjs/toolkit";
import ContactFormService from "../../../api/services/contact";

export const fetchContactForms = createAsyncThunk(
  "contactForms/fetchContactForms",
  async (requestData) => {
    const { page, sortBy, order, search } = requestData;
    return await ContactFormService.fetchContactForms(
      page,
      sortBy,
      order,
      search
    );
  }
);

export const deleteContactForm = createAsyncThunk(
  "contactForms/deleteContactForms",
  async (requestData) => {
    const { contactFormId } = requestData;
    return await ContactFormService.deleteContactForm(contactFormId);
  }
);
