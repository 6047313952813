import { React, useEffect, useState } from "react";
import styles from "./DropDownMenuCustom.module.scss";
import classNames from "classnames";

import { ReactComponent as IconDown } from "../../assets/icons/Down.svg";

const DropDownMenuCustom = ({
  required,
  placeholder,
  classes,
  onChange,
  options,
  defaultValue,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  if (required === true) {
    placeholder += " *";
  }

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handeItemChose = (e, itemName, itemId) => {
    e.preventDefault();
    setValue(itemName);
    setOpen(!open);
    onChange(itemName, itemId);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (open) {
      let ignoreClickOnMeElement = document.getElementById(
        `mouseTarget-${value === "" ? placeholder : value}`
      );

      document.addEventListener("click", function (event) {
        let isClickInsideElement = ignoreClickOnMeElement.contains(
          event.target
        );
        if (!isClickInsideElement) {
          setOpen(!open);
        }
      });
    }
  });

  return (
    <div
      className={classNames(classes, styles.dropDownMenuCustom)}
      id={`mouseTarget-${value === "" ? placeholder : value}`}
    >
      <button
        type={"button"}
        onClick={(e) => handleOpen(e)}
        className={styles.dropbtn}
      >
        <span>{value === "" ? placeholder : value}</span>
        <IconDown
          className={classNames(styles.iconDown, open && styles.iconTop)}
        />
      </button>
      {open && (
        <div className={styles.selectContainer}>
          <ul className={styles.menu}>
            {options?.map((item, index) => {
              return (
                <li key={index}>
                  <button
                    onClick={(e) => handeItemChose(e, item.name, item.id)}
                  >
                    {item.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownMenuCustom;
