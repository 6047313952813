import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./ClientNavigation.module.scss";

import { selectLoggedInUserDetails } from "../../store/slices/user/slice";

import { Modal, Input, Button } from "../../components";

import { notifyInfo, notifyError } from "../../utils/notify";

import UserService from "../../api/services/user";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon2-01.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_icon.svg";

const ClientNavigation = () => {
  const logedInUserDetails = useSelector(selectLoggedInUserDetails);

  const myProfileModal = useRef();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetPasswordBtnEnabled, setResetPasswordBtnEnabled] = useState(true);

  const [pendingResetPassword, setPendingResetPassword] = useState(false);

  const changePassword = async () => {
    setPendingResetPassword(true);
    await UserService.changePassword({
      oldPassword: oldPassword,
      password: newPassword,
    })
      .then((res) => {
        notifyInfo("Password changed successfully");
        resetForm();
      })
      .catch((res) => {
        notifyError(res.message);
      })
      .finally();
  };

  const resetForm = async () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setResetPasswordBtnEnabled(true);
    setPendingResetPassword(false);
  };

  useEffect(() => {
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmNewPassword !== "" &&
      newPassword === confirmNewPassword
    ) {
      setResetPasswordBtnEnabled(false);
    } else {
      setResetPasswordBtnEnabled(true);
    }
  }, [oldPassword, newPassword, confirmNewPassword]);

  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    window.scrollTo(0, 0);
    setMobileMenu((prev) => !prev);
  };

  return (
    <>
      <div className={styles.mobileMenu} onClick={handleMobileMenu}>
        <MenuIcon />
      </div>
      {mobileMenu && (
        <div className={styles.mobileMenuList}>
          <div className={styles.closeIcon} onClick={handleMobileMenu}>
            <CloseIcon />
          </div>
          <ul className={styles.mobileMenuContainer}>
            <li onClick={handleMobileMenu}>
              <NavLink to="/client/documents">Documents</NavLink>
            </li>
            <li onClick={handleMobileMenu}>
              <NavLink to="/client/newsletter">Newsletter</NavLink>
            </li>
            <li onClick={handleMobileMenu}>
              <NavLink to="/client/referral">Referal</NavLink>
            </li>
            <li onClick={handleMobileMenu}>
              <NavLink to="#" onClick={() => myProfileModal.current.show()}>
                {logedInUserDetails.firstName +
                  " " +
                  logedInUserDetails.lastName}
              </NavLink>
            </li>
          </ul>
        </div>
      )}
      <ul className={styles.mainMenu}>
        <li>
          <NavLink
            to="/client/documents"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            Documents
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/client/newsletter"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            Newsletter
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/client/referral"
            className={(navData) =>
              navData.isActive ? styles.activeNavLink : styles.notActiveNavLink
            }
          >
            Referral
          </NavLink>
        </li>
        <li>
          <NavLink to="#" onClick={() => myProfileModal.current.show()}>
            {logedInUserDetails.firstName + " " + logedInUserDetails.lastName}
          </NavLink>
        </li>
      </ul>
      <Modal
        ref={myProfileModal}
        modalStyle={styles.modalStyle}
        title={"Account details"}
        showOkButton={false}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => {
          resetForm();
          myProfileModal.current.hide();
        }}
      >
        <div className={styles.itemContainer}>
          <p>First name:</p>
          <p>{logedInUserDetails.firstName}</p>
        </div>
        <div className={styles.itemContainer}>
          <p>Last name:</p>
          <p>{logedInUserDetails.lastName}</p>
        </div>
        <div className={styles.itemContainer}>
          <p>Email:</p>
          <p>{logedInUserDetails.email}</p>
        </div>
        <div className={styles.itemContainer}>
          <p>PIN:</p>
          <p>{logedInUserDetails.pin ? logedInUserDetails.pin : "N/A"}</p>
        </div>
        <div className={styles.resetPasswordSection}>
          <p>Reset password:</p>
          <Input
            placeholder={"Old password"}
            name={"Old password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setOldPassword(event)}
            value={oldPassword}
          />
          <Input
            placeholder={"New password"}
            name={"New password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setNewPassword(event)}
            value={newPassword}
          />
          <Input
            placeholder={"Confirm new password"}
            name={"Confirm new password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setConfirmNewPassword(event)}
            value={confirmNewPassword}
          />
          <Button
            title={"Reset password"}
            enabled={resetPasswordBtnEnabled}
            onClick={changePassword}
            classes={styles.resetBtn}
            loading={pendingResetPassword}
          ></Button>
        </div>
      </Modal>
    </>
  );
};

export default ClientNavigation;
