import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Card, Modal } from "../../../components";
import styles from "./OurServicesAdmin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import { selectCreateNewsPending } from "../../../store/slices/news/slice";
import { fetchNews } from "../../../store/slices/news/asyncThunks";

const ourServices = [
  {
    title: "Retirement Planning",
    description: "",
  },
  {
    title: "Investment Planning",
    description:
      "<p><b>Wells Global offers personalized global equity and fixed income management to high net worth individual and institutional clients on a discretionary basis.</b> Minimum account size is $400K. The firm takes an active approach to portfolio management, using information obtained by capital markets research to adjust client portfolios based on our forecasts and opportunities that we see in the markets.<br><br> We provide personalized portfolio management and reporting for each client based on their individual investment goals, time horizon, cash flow requirements, outside holdings and tax status, along with other factors. We take the time to understand our clients’ risk tolerances and goals and combine that with our broad investment philosophy and proven investment acumen. We take an active approach to optimize return relative to risk because we believe that protecting clients from downside volatility, while also participating in market growth during bull markets, is the most effective way to optimize long-term returns for clients.<br><br> We take a holistic approach to investing. This means we analyze a broad spectrum of mainstream investments such as growth versus value, large versus medium versus small cap equities, domestic versus international, high quality versus high yield bonds, convertible bonds as well as the length of their maturity and their duration. We have the experience and insight to capitalize on opportunities in all areas of global markets, including commodities and currencies, and tactically adjust our strategy as appropriate depending on our view of the markets. We may also strategically use options to add value, with a preference to sell options as part of a carefully constructed portfolio. We understand the importance of collecting option premiums to potentially enhance portfolio returns. </p>",
  },
  {
    title: "Financial Planning",
    description: "",
  },
  {
    title: "Estate Planning",
    description:
      "<p><b>Trust services offered by us and affiliated companies include Trustee services and Trust Protector Services. Our goal is to provide exceptional service for anyone who is a grantor and/or beneficiary of a trust.</b><br><br>Wells investment affiliated companies offer Trustee services that are pro-grantor, pro-trustee and pro-beneficiary. Our Trustee Services are designed to provide the grantors, co-trustees and beneficiaries a very affordable alternative to the current standard Trustee arrangements. Most Trustees consider the assets a major source of revenue.<br><br><b>We consider the assets as belonging to the client and a not a major source of revenue. We use the role to facilitate the transition of assets to the beneficiaries in a most reasonable and expeditious manner with the opportunity for the beneficiaries to inherit money that is legally protected against any outside litigation. </b><br><br>For trusts with an existing Trustee, or if a grantor wishes to establish a trust with a specific Trustee, Wells offers a Trust Protector service which can watch over the Trustee and terminate the Trustee for any misconduct. This is also a way to insure that the Trustee does not withhold or delay benefits to trust beneficiaries or unreasonably tie up trust assets in legal disputes. A trust’s “Protector Provision” should be included in any new trust discussions or if trusts are to be updated.<br><br><i>The Wells Family of Investment Companies has the necessary experience and resources to provide you and your family with these important trust and estate services.</i></p>",
  },
  {
    title: "Tax Planning",
    description: "",
  },
  {
    title: "Trust Planning ",
    description: "",
  },
];

const OurServicesAdmin = () => {
  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const dispatch = useDispatch();

  const pendingCreateAction = useSelector(selectCreateNewsPending);

  const editOurServices = useRef();

  const changeHandler = useCallback(
    (text) => {
      dispatch(fetchNews({ search: text }));
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchNews({ search: "" }));
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    resetInputFields();
    editOurServices.current.hide();
  };

  const handeEditOurService = (item) => {
    setTitle(item.title);
    const contentBlock = htmlToDraft(item.description);
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.description))
      );

      setEditorState(editorState);
    }

    editOurServices.current.show();
  };

  const resetInputFields = () => {
    setTitle("");
    setEditorState(EditorState.createEmpty());
  };

  const handleOkModal = () => {
    const foundIndex = ourServices.findIndex((x) => x.title === title);
    ourServices[foundIndex].description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    editOurServices.current.hide();
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}></div>
      <div className={styles.servicesContainer}>
        {ourServices?.map((item, index) => (
          <Card classes={styles.services} key={index}>
            <div className={styles.editContainer}>
              <h2>{item.title}</h2>
              <div
                className={styles.editIcon}
                onClick={() => handeEditOurService(item)}
              >
                <EditIcon />
              </div>
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={editOurServices}
        title={title}
        showCancelButton
        showOkButton
        buttonOkText={"Update"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingCreateAction}
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={styles.editorClassName}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
          }}
          onEditorStateChange={onEditorStateChange}
        />
      </Modal>
    </div>
  );
};

export default OurServicesAdmin;
