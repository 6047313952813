import React, { useRef } from "react";
import classNames from "classnames";
import styles from "./SearchInput.module.scss";

const SearchInput = ({
  value,
  placeholder,
  onChange,
  className,
  disabled,
  onFocus,
}) => {
  const input = useRef();

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div
      className={classNames(
        styles.customField,
        className,
        disabled && styles.disabled
      )}
    >
      <input
        type="text"
        ref={input}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
      />
    </div>
  );
};

export default SearchInput;
