import {
  fetchResearchAndInsight,
  fetchAllResearchAndInsight,
  deleteResearchAndInsight,
  createResearchAndInsight,
} from "./asyncThunks";

export const fetchResearchAndInsightBuilder = (builder) => {
  builder.addCase(fetchResearchAndInsight.pending, (state) => {
    state.fetchResearchAndInsight = true;
  });
  builder.addCase(fetchResearchAndInsight.fulfilled, (state, action) => {
    state.fetchResearchAndInsight = false;
    state.researchAndInsight.data = action.payload.researchAndInsight;
  });
  builder.addCase(fetchResearchAndInsight.rejected, (state) => {
    state.fetchResearchAndInsight = false;
  });
};

export const fetchAllResearchAndInsightBuilder = (builder) => {
  builder.addCase(fetchAllResearchAndInsight.pending, (state) => {
    state.fetchResearchAndInsight = true;
  });
  builder.addCase(fetchAllResearchAndInsight.fulfilled, (state, action) => {
    state.fetchResearchAndInsight = false;
    state.researchAndInsight.data = action.payload.researchAndInsight;
  });
  builder.addCase(fetchAllResearchAndInsight.rejected, (state) => {
    state.fetchResearchAndInsight = false;
  });
};

export const deleteResearchAndInsightBuilder = (builder) => {
  builder.addCase(deleteResearchAndInsight.pending, (state) => {
    state.deleteResearchAndInsight = true;
  });
  builder.addCase(deleteResearchAndInsight.fulfilled, (state, action) => {
    state.deleteResearchAndInsight = false;
    state.researchAndInsight.data = state.researchAndInsight.data.map((s) => {
      if (s.id === action.payload.data.result.id) {
        s.deletedAt = "deleted";
      }
      return s;
    });
  });
  builder.addCase(deleteResearchAndInsight.rejected, (state) => {
    state.deleteResearchAndInsight = false;
  });
};

export const createResearchAndInsightBuilder = (builder) => {
  builder.addCase(createResearchAndInsight.pending, (state) => {
    state.createResearchAndInsight = true;
  });
  builder.addCase(createResearchAndInsight.fulfilled, (state, action) => {
    state.createResearchAndInsight = false;
    state.researchAndInsight.data.unshift(action.payload.data);
  });
  builder.addCase(createResearchAndInsight.rejected, (state) => {
    state.createResearchAndInsight = false;
  });
};
