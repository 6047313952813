import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQuestionsBuilder,
  fetchCategoriesBuilder,
  editQuestionBuilder,
  editCategoryBuilder,
  editAnswerBuilder,
  fetchQuizResultsBuilder,
  deleteQuizResultBuilder,
} from "./builder";

const initialState = {
  questions: {
    data: [],
  },
  categories: {
    data: [],
  },
  quizResults: {
    data: [],
    perPage: null,
    total: 0,
  },
  editQuestion: false,
  editAnswer: false,
  editCategory: false,
  deleteQuizResult: false,
};

export const quiz = createSlice({
  name: "quiz",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchQuestionsBuilder(builder);
    editCategoryBuilder(builder);
    fetchQuizResultsBuilder(builder);
    editAnswerBuilder(builder);
    editQuestionBuilder(builder);
    fetchCategoriesBuilder(builder);
    deleteQuizResultBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectQuestion = (state) => state.quiz.questions.data;
export const selectCategories = (state) => state.quiz.categories.data;
export const selectQuizResults = (state) => state.quiz.quizResults;

export const selectEditQuestionPending = (state) => state.quiz.editQuestion;
export const selectEditAnswerPending = (state) => state.quiz.editAnswer;
export const selectEditCategoryPending = (state) => state.quiz.editCategory;

export const selectDeleteQuizResultPending = (state) =>
  state.quiz.deleteQuizResult;

export default quiz.reducer;
