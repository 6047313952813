import React from "react";
import { usePagination, DOTS } from "./usePagination";
import styles from "./Pagination.module.scss";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron_right.svg";

import classNames from "classnames";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <ul className={styles.paginationContainer}>
      <li
        className={classNames([
          styles.arrow,
          currentPage === 1 && styles.disabled,
        ])}
        onClick={onPrevious}
      >
        <ChevronIcon className={classNames([styles.icon, styles.leftArrow])} />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={pageNumber + index}
              className={classNames([styles.paginationItem, styles.dots])}
            >
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classNames([
              styles.paginationItem,
              pageNumber === currentPage && styles.selected,
            ])}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classNames([
          styles.arrow,
          currentPage === lastPage && styles.disabled,
        ])}
        onClick={onNext}
      >
        <ChevronIcon className={styles.icon} />
      </li>
    </ul>
  );
};

export default Pagination;
