import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNewsBuilder,
  deleteNewsBuilder,
  createNewsBuilder,
  editNewsBuilder,
} from "./builder";

const initialState = {
  news: {
    data: [],
  },
  createNews: false,
  deleteNews: false,
  editNews: false,
  fetchNews: false,
};

export const news = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchNewsBuilder(builder);
    deleteNewsBuilder(builder);
    createNewsBuilder(builder);
    editNewsBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectNews = (state) => state.news.news.data;
export const selectCreateNewsPending = (state) => state.news.createNews;
export const selectEditNewsPending = (state) => state.news.editNews;

export const selectDeleteNewsPending = (state) => state.news.deleteNews;

export default news.reducer;
