import { ReactComponent as Finances } from "../assets/icons/Financial.svg";
import { ReactComponent as Health } from "../assets/icons/Health.svg";
import { ReactComponent as Leisure } from "../assets/icons/Leisure.svg";
import { ReactComponent as Work } from "../assets/icons/employment.svg";
import { ReactComponent as Home } from "../assets/icons/Estate.svg";
import { ReactComponent as Family } from "../assets/icons/Family.svg";

export const LOCAL_STORAGE_ACCESS_TOKEN = "WELLS_ACCESS_TOKEN";
export const LOCAL_STORAGE_REFRESH_TOKEN = "WELLS_REFRESH_TOKEN";

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};

export const DOCUMENT_STATES = {
  UPLOADED: "uploaded",
  TO_SIGN: "toSign",
  SIGNED: "signed",
};

export const PERMISSIONS_FOT_ROUTES = {};

export const optionHours = [
  { id: "0", name: "00" },
  { id: "1", name: "01" },
  { id: "2", name: "02" },
  { id: "3", name: "03" },
  { id: "4", name: "04" },
  { id: "5", name: "05" },
  { id: "6", name: "06" },
  { id: "7", name: "07" },
  { id: "8", name: "08" },
  { id: "9", name: "09" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "13", name: "13" },
  { id: "14", name: "14" },
  { id: "15", name: "15" },
  { id: "16", name: "16" },
  { id: "17", name: "17" },
  { id: "18", name: "18" },
  { id: "19", name: "19" },
  { id: "20", name: "20" },
  { id: "21", name: "21" },
  { id: "22", name: "22" },
  { id: "23", name: "23" },
];

export const optionMinutes = [
  { id: "0", name: "00" },
  { id: "1", name: "01" },
  { id: "2", name: "02" },
  { id: "3", name: "03" },
  { id: "4", name: "04" },
  { id: "5", name: "05" },
  { id: "6", name: "06" },
  { id: "7", name: "07" },
  { id: "8", name: "08" },
  { id: "9", name: "09" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "13", name: "13" },
  { id: "14", name: "14" },
  { id: "15", name: "15" },
  { id: "16", name: "16" },
  { id: "17", name: "17" },
  { id: "18", name: "18" },
  { id: "19", name: "19" },
  { id: "20", name: "20" },
  { id: "21", name: "21" },
  { id: "22", name: "22" },
  { id: "23", name: "23" },
  { id: "24", name: "24" },
  { id: "25", name: "25" },
  { id: "26", name: "26" },
  { id: "27", name: "27" },
  { id: "28", name: "28" },
  { id: "29", name: "29" },
  { id: "30", name: "30" },
  { id: "31", name: "31" },
  { id: "32", name: "32" },
  { id: "33", name: "33" },
  { id: "34", name: "34" },
  { id: "35", name: "35" },
  { id: "36", name: "36" },
  { id: "37", name: "37" },
  { id: "38", name: "38" },
  { id: "39", name: "39" },
  { id: "40", name: "40" },
  { id: "41", name: "41" },
  { id: "42", name: "42" },
  { id: "43", name: "43" },
  { id: "44", name: "44" },
  { id: "45", name: "45" },
  { id: "46", name: "46" },
  { id: "47", name: "47" },
  { id: "48", name: "48" },
  { id: "49", name: "49" },
  { id: "50", name: "50" },
  { id: "51", name: "51" },
  { id: "52", name: "52" },
  { id: "53", name: "53" },
  { id: "54", name: "54" },
  { id: "55", name: "55" },
  { id: "56", name: "56" },
  { id: "57", name: "57" },
  { id: "58", name: "58" },
  { id: "59", name: "59" },
];

export const icons = {
  Finances: <Finances />,
  Health: <Health />,
  Leisure: <Leisure />,
  Work: <Work />,
  Home: <Home />,
  Family: <Family />,
};

export const purposes = [
  { id: "0", name: "Portfolio Analysis" },
  { id: "1", name: "Investment Risk Analysis" },
  { id: "2", name: "Current Retirement" },
  { id: "3", name: "Upcoming Retirement" },
  { id: "4", name: "Tax-Favored Investments" },
  { id: "5", name: "Tax-Free Investments" },
  { id: "6", name: "What's Going on in the Markets" },
  { id: "7", name: "Transfer my Account" },
  { id: "8", name: "Other" },
];

export const investableAssets = [
  { id: "0", name: "Less than $400,000" },
  { id: "1", name: "Between $400,000 and $1,000,000" },
  { id: "2", name: "Greater than $1,000,000" },
];
