import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SearchInput, Table, TableItem, Modal } from "../../../components";
import styles from "./QuizResultsScreenAdmin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron_right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";

import {
  selectQuizResults,
  selectDeleteQuizResultPending,
} from "../../../store/slices/quiz/slice";
import {
  fetchQuizResults,
  deleteQuizResult,
} from "../../../store/slices/quiz/asyncThunks";
import { notifyInfo } from "../../../utils/notify";

const QuizResultsScreenAdmin = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  let navigate = useNavigate();

  const { data, perPage, total } = useSelector(selectQuizResults);
  const pendingAction = useSelector(selectDeleteQuizResultPending);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });
  const [selectedQuizResultForm, setSelectedQuizResultForm] = useState(null);

  const quizResultsModal = useRef();
  const deleteQuizResultsModal = useRef();

  const tableHeader = [
    {
      key: "firstName",
      label: "Name",
      sortable: true,
    },
    {
      key: "email",
      label: "Email",
      sortable: true,
    },
    {
      key: "purpose",
      label: "Purpose",
      sortable: true,
    },
    {
      key: "assets",
      label: "Investable Assets",
      sortable: true,
    },
    {
      key: "result",
      label: "Result",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const handleCloseCreateModal = () => {
    deleteQuizResultsModal.current.hide();
  };

  const handleOkModal = () => {
    dispatch(deleteQuizResult({ id: selectedQuizResultForm.id })).then(
      (promise) => {
        deleteQuizResultsModal.current.hide();
        notifyInfo("Quiz result deleted.");
      }
    );
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/quiz-results",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchQuizResults({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  const location = useLocation();

  useEffect(() => {
    if (!location.search) {
      navigate({
        pathname: "/admin/quiz-results",
        search: "?page=1",
      });
    }
  });

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);

    if (page > 0) {
      dispatch(
        fetchQuizResults({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
        })
      );
    }
    //eslint-disable-next-line
  }, [page, sortOptions, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          onChange={debouncedChangeHandler}
          placeholder="Search email"
        />
      </div>
      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((quizResult, index) => {
            return (
              <Fragment key={index}>
                <TableItem
                  data={quizResult.firstName + " " + quizResult.lastName}
                />
                <TableItem data={quizResult.email} />
                <TableItem data={quizResult.purpose} />
                <TableItem data={quizResult.assets} />
                <TableItem data={quizResult.result} />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedQuizResultForm(quizResult);
                          deleteQuizResultsModal.current.show();
                        }}
                      />
                      <ChevronIcon
                        className={styles.icon}
                        onClick={() => {
                          setSelectedQuizResultForm(quizResult);
                          quizResultsModal.current.show();
                        }}
                      />
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>

      <Modal
        title={"Quiz result details"}
        ref={quizResultsModal}
        showCancelButton
        showOkButton={false}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => quizResultsModal.current.hide()}
      >
        {selectedQuizResultForm !== null && (
          <Fragment>
            <div className={styles.itemContainer}>
              <p>Name:</p>
              <p>
                {selectedQuizResultForm.firstName}{" "}
                {selectedQuizResultForm.lastName}
              </p>
            </div>
            <div className={styles.itemContainer}>
              <p>Email:</p>
              <p> {selectedQuizResultForm.email}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>Phone:</p>
              <p> {selectedQuizResultForm.phoneNumber}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>Address:</p>
              <p> {selectedQuizResultForm.address}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>State:</p>
              <p> {selectedQuizResultForm.state}</p>
            </div>

            <div className={styles.itemContainer}>
              <p>Purpose:</p>
              <p> {selectedQuizResultForm.purpose}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>Investable Assests:</p>
              <p> {selectedQuizResultForm.assets}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>Note:</p>
              <p> {selectedQuizResultForm.note}</p>
            </div>
            <div className={styles.itemContainer}>
              <p>Result:</p>
              <p> {selectedQuizResultForm.result}</p>
            </div>
          </Fragment>
        )}
      </Modal>

      <Modal
        ref={deleteQuizResultsModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
      >
        {"Delete quiz result : " + selectedQuizResultForm?.email}
      </Modal>
    </div>
  );
};

export default QuizResultsScreenAdmin;
