import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Table,
  TableItem,
  Input,
  Modal,
} from "../../../components";
import styles from "./ClientsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";

import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron_right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

import session from "../../../services/sessionStorageService";

import {
  selectClients,
  selectDeleteUserPending,
  selectCreateUserPending,
} from "../../../store/slices/clients/slice";

import {
  fetchUsers,
  deleteUser,
  createUser,
  updateUser,
} from "../../../store/slices/clients/asyncThunks";
import { notifyInfo, notifyError } from "../../../utils/notify";

const ClientsScreen = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  let navigate = useNavigate();

  const { data, perPage, total } = useSelector(selectClients);
  const pendingAction = useSelector(selectDeleteUserPending);
  const pendingCreateAction = useSelector(selectCreateUserPending);

  const [createAction, setCreateAction] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });
  const [selectedClient, setSelectedClient] = useState(null);

  const clientModal = useRef();
  const deleteClientModal = useRef();

  const tableHeader = [
    {
      key: "firstName",
      label: "First Name",
      sortable: true,
    },
    {
      key: "lastName",
      label: "Last Name",
      sortable: true,
    },
    {
      key: "email",
      label: "Email",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const handleCreateUser = () => {
    setSelectedClient({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    });
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setPin("");
    setCreateAction(true);
    clientModal.current.show();
  };

  const handleCloseCreateModal = () => {
    clientModal.current.hide();
  };
  const handleOkModal = () => {
    if (createAction) {
      dispatch(createUser({ firstName, lastName, email, password, pin })).then(
        (promise) => {
          if (promise.type.includes("fulfilled")) {
            clientModal.current.hide();
            notifyInfo("Client created.");
          } else {
            notifyError(promise.error.message);
          }
        }
      );
    } else {
      dispatch(
        updateUser({
          id: selectedClient.id,
          firstName,
          lastName,
          password: password.length > 0 ? password : null,
          pin,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          clientModal.current.hide();
          notifyInfo("Client updated.");
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const handleCloseDeleteModal = () => {
    deleteClientModal.current.hide();
  };
  const handleDeleteOkModal = () => {
    dispatch(deleteUser({ userId: selectedClient.id })).then((promise) => {
      deleteClientModal.current.hide();
      notifyInfo("Client deleted.");
    });
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/clients",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchUsers({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) {
      dispatch(
        fetchUsers({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
        })
      );
    }
    //eslint-disable-next-line
  }, [page, sortOptions, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput onChange={debouncedChangeHandler} placeholder="Search" />
        <button className={styles.createBtn} onClick={handleCreateUser}>
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((client, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={client.firstName} />
                <TableItem data={client.lastName} />
                <TableItem data={client.email} />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedClient(client);
                          deleteClientModal.current.show();
                        }}
                      />
                      <ChevronIcon
                        className={styles.icon}
                        onClick={() => {
                          setSelectedClient(client);
                          setCreateAction(false);

                          setEmail(client.email);
                          setFirstName(client.firstName);
                          setLastName(client.lastName);
                          setPassword("");
                          session.setItem(
                            "client",
                            `${client.firstName} ${client.lastName}`
                          );
                          navigate(`/admin/clients/${client.id}?page=1`);
                        }}
                      />
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>

      <Modal
        ref={clientModal}
        title={createAction ? "Create a new client" : "Edit client"}
        showCancelButton
        showOkButton
        buttonOkText={createAction ? "Create" : "Edit"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingCreateAction}
      >
        <Input
          placeholder={"Email"}
          name={"Email"}
          readOnly={!createAction}
          classes={styles.inputContainer100}
          onChange={(event) => setEmail(event)}
          value={email}
        />
        <Input
          placeholder={"First Name"}
          name={"First Name"}
          classes={styles.inputContainer100}
          onChange={(event) => setFirstName(event)}
          value={firstName}
        />
        <Input
          placeholder={"Last Name"}
          name={"Last Name"}
          classes={styles.inputContainer100}
          onChange={(event) => setLastName(event)}
          value={lastName}
        />
        <Input
          placeholder={"Password"}
          name={"Password"}
          classes={styles.inputContainer100}
          onChange={(event) => setPassword(event)}
          value={password}
        />
        <Input
          placeholder={"PIN"}
          name={"pin"}
          classes={styles.inputContainer100}
          onChange={(event) => setPin(event)}
          value={pin}
        />
      </Modal>
      <Modal
        ref={deleteClientModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseDeleteModal}
        buttonOkOnClick={handleDeleteOkModal}
      >
        {"Delete client : " + selectedClient?.email}
      </Modal>
    </div>
  );
};

export default ClientsScreen;
