import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Table,
  TableItem,
  Modal,
  Input,
  FileUpload,
  DropDownMenuCustom,
  CheckBox,
} from "../../../components";
import styles from "./DocumentsToSignScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron_right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/Download.svg";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

import {
  selectDocumentToSign,
  selectCreateDocumentToSignPending,
  selectDeleteDocumentToSignPending,
  selectEditDocumentToSignPending,
} from "../../../store/slices/documentToSign/slice";
import {
  fetchAllDocumentToSign,
  createDocumentToSign,
  deleteDocumentToSign,
  editDocumentToSign,
} from "../../../store/slices/documentToSign/asyncThunks";
import DocumentToSignService from "../../../api/services/documentToSign";

import { selectClients } from "../../../store/slices/clients/slice";
import { fetchAllUsers } from "../../../store/slices/clients/asyncThunks";
import { notifyInfo, notifyError } from "../../../utils/notify";
import { DOCUMENT_STATES } from "../../../utils/constants";

const DocumentsToSignScreen = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  let navigate = useNavigate();

  const { data, perPage, total } = useSelector(selectDocumentToSign);
  const pendingAction = useSelector(selectDeleteDocumentToSignPending);
  const { allClients } = useSelector(selectClients);

  const pendingCreateAction = useSelector(selectCreateDocumentToSignPending);
  const pendingEditAction = useSelector(selectEditDocumentToSignPending);
  const createDocModal = useRef();

  const [id, setId] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [file, setFile] = useState(null);
  const [client, setClient] = useState(null);
  const [showToClient, setShowToClient] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });

  const [selectedDocumentToSign, setSelectedDocumentToSign] = useState(null);
  const deleteDocumentToSignModal = useRef();

  const tableHeader = [
    {
      key: "documentName",
      label: "Document name",
      sortable: true,
    },
    {
      key: "documentState",
      label: "Document state",
      sortable: true,
    },
    {
      key: "signer",
      label: "Signer",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const resetForm = () => {
    setId("");
    setPdfName("");
    setFile(null);
    setClient({ name: "", id: "" });
  };

  const downloadDocument = async (document) => {
    await DocumentToSignService.downloadDocument(document.id)
      .then((res) => {
        window.open(res, "_blank");
      })
      .finally();
  };
  const handleCloseDeleteModal = () => {
    deleteDocumentToSignModal.current.hide();
  };

  const handleOkDeleteModal = () => {
    dispatch(
      deleteDocumentToSign({ documentToSignId: selectedDocumentToSign.id })
    ).then((promise) => {
      deleteDocumentToSignModal.current.hide();
      notifyInfo("Document deleted.");
    });
  };

  const handleCreateDocument = () => {
    resetForm();
    createDocModal.current.show();
  };

  const handleOkCreateModal = () => {
    if (id) {
      dispatch(
        editDocumentToSign({
          id,
          documentName: pdfName,
          file,
          userId: client?.id,
          showToClient,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          createDocModal.current.hide();
          notifyInfo("Document to sign successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    } else {
      dispatch(
        createDocumentToSign({
          documentName: pdfName,
          file,
          userId: client?.id,
          showToClient,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          createDocModal.current.hide();
          notifyInfo("Document to sign successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const handleCloseCreateModal = () => {
    createDocModal.current.hide();
  };

  const handleEditModal = (document) => {
    setId(document.id);
    setPdfName(document.documentName);
    setFile(null);
    setShowToClient(document.showToClient);
    setClient({
      name: document.user.firstName + " " + document.user.lastName,
      id: document.user.id,
    });

    createDocModal.current.show();
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/documents-to-sign",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchAllDocumentToSign({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) {
      dispatch(
        fetchAllDocumentToSign({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
        })
      );
    }

    dispatch(fetchAllUsers());
    //eslint-disable-next-line
  }, [page, sortOptions, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          onChange={debouncedChangeHandler}
          placeholder="Search email"
        />
        <button className={styles.createDocBtn} onClick={handleCreateDocument}>
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((documentToSign, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={documentToSign.documentName} />
                <TableItem data={documentToSign.documentState} />
                <TableItem
                  data={
                    documentToSign?.user?.firstName +
                    " " +
                    documentToSign?.user?.lastName
                  }
                />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DownloadIcon
                        style={{ filter: "brightness(0)" }}
                        className={styles.deleteIcon}
                        onClick={() => {
                          downloadDocument(documentToSign);
                        }}
                      />
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedDocumentToSign(documentToSign);
                          deleteDocumentToSignModal.current.show();
                        }}
                      />
                      {documentToSign.documentState ===
                        DOCUMENT_STATES.UPLOADED && (
                        <ChevronIcon
                          className={styles.icon}
                          onClick={() => {
                            setSelectedDocumentToSign(documentToSign);
                            handleEditModal(documentToSign);
                          }}
                        />
                      )}
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <Modal
        ref={createDocModal}
        title={id !== "" ? "Edit document to sign" : "Create document to sign"}
        showCancelButton
        showOkButton
        buttonOkText={id !== "" ? "Edit" : "Create"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkCreateModal}
        modalStyle={styles.modalStyle}
        pendingAction={id ? pendingEditAction : pendingCreateAction}
      >
        <div className={styles.inputFields}>
          <FileUpload
            textUploadFile={"Upload document"}
            setFile={setFile}
            setFileName={setPdfName}
            classNameContainer={styles.logoSpace}
          ></FileUpload>
          <Input
            placeholder={"Title"}
            name={"title"}
            classes={styles.inputContainer100}
            onChange={(event) => setPdfName(event)}
            value={pdfName}
          />
          <DropDownMenuCustom
            required
            placeholder={"Select client"}
            options={allClients}
            onChange={(name, id) => {
              setClient({ name, id });
            }}
            classes={styles.inputContainer100}
            defaultValue={client?.name}
          />
          <CheckBox
            className={styles.showToClientWrapper}
            checked={showToClient}
            text={"Show to client"}
            onClick={() => setShowToClient((prev) => !prev)}
          />
        </div>
      </Modal>
      <Modal
        ref={deleteDocumentToSignModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseDeleteModal}
        buttonOkOnClick={handleOkDeleteModal}
      >
        {"Delete document for signing : " +
          selectedDocumentToSign?.documentName}
      </Modal>
    </div>
  );
};

export default DocumentsToSignScreen;
