import React, { useState, useEffect, useRef } from "react";
import styles from "./ContinueTestSection.module.scss";
import { notifyInfo } from "../../../utils/notify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/CheckBox/CheckBox";
import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";
import Form from "../../../components/Form/Form";
import DropDownMenuCustom from "../../../components/DropDownMenuCustom/DropDownMenuCustom";
import { Modal, Input } from "../../../components";

import bannerImg from "../../../assets/images/img2.jpg";

import { icons, purposes, investableAssets } from "../../../utils/constants";

import usaStates from "../../../assets/json/states.json";
import { selectQuestion } from "../../../store/slices/quiz/slice";
import { fetchQuestions } from "../../../store/slices/quiz/asyncThunks";
import QuizService from "../../../api/services/quiz";

const ContinueTestSection = ({ topPrioritiesList }) => {
  const [clickCounter, setClickCounter] = useState(0);
  const dispatch = useDispatch();
  const submitModal = useRef();
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [purpose, setPurpose] = useState("");
  const [assets, setAssets] = useState("");
  const [note, setNote] = useState("");
  const [state, setState] = useState("");

  const [submitBtnEbabled, setSubmitBtnEbabled] = useState(false);

  const questions = useSelector(selectQuestion);
  const [data, setData] = useState(
    JSON.parse(JSON.stringify([...questions.slice(3)]))
  );

  useEffect(() => {
    dispatch(fetchQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      address !== "" &&
      state !== "" &&
      purpose !== "" &&
      assets !== ""
    ) {
      setSubmitBtnEbabled(true);
    } else {
      setSubmitBtnEbabled(false);
    }
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
    state,
    purpose,
    assets,
  ]);

  const handleCheckedAnswer = (answer, questionIndex, answerIndex) => {
    let temp = [...data];

    temp[questionIndex].answers[answerIndex].selected = answer;

    setClickCounter((prevState) => prevState + 1);
    setData(temp);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setState("");
    setPurpose("");
    setAssets("");
    setNote("");
  };

  const computeTopPriorities = () => {
    let count = {
      Finances: 0,
      Health: 0,
      Leisure: 0,
      Work: 0,
      Home: 0,
      Family: 0,
    };

    count[topPrioritiesList[0].name] = 2;
    count[topPrioritiesList[1].name] = 1;

    data.forEach((q) => {
      q.answers.forEach((a) => {
        if (a.selected) {
          count[a.category.name] += 1;
        }
      });
    });

    let sortable = [];
    for (var item in count) {
      sortable.push([item, count[item]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    return sortable[0][0] + ", " + sortable[1][0];
  };

  const confirmAction = async (e) => {
    e.preventDefault();

    await QuizService.submitQuiz({
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      assets,
      purpose,
      note,
      state,
      result: computeTopPriorities(),
    })
      .then((res) => {
        resetForm();
        notifyInfo("Successfully sent");
        submitModal.current.hide();
        navigate({
          pathname: "/",
        });
        window.scrollTo(0, 0);
      })
      .finally();
    e.target.reset();
  };

  const handleCloseModal = () => {
    resetForm();
    submitModal.current.hide();
  };

  return (
    <section className={styles.containerOutside}>
      <div className={styles.headerImg}>
        <img src={bannerImg} alt="banner" />
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Tell us about your goals.</h1>
          <p>
            Now that you've selected your priorities,
            <br /> tell us a little about your goals below.
          </p>
        </div>

        {data.map((q, i) => (
          <div key={i} className={styles.questionContainer}>
            <div className={styles.questionHeader}>
              {icons[q.text]}
              <h2>{q.text}</h2>
            </div>
            <div className={styles.answersContainer}>
              {q.answers.map((answer, index) => (
                <Options
                  key={`${i}-${index}-${clickCounter}`}
                  answer={answer.text}
                  checked={answer.selected}
                  handleCheckedAnswer={(a) => handleCheckedAnswer(a, i, index)}
                />
              ))}
            </div>
          </div>
        ))}
        <div className={styles.submitBtnContainer}>
          <Button
            title={"Submit Answers"}
            classes={styles.submitBtn}
            onClick={() => submitModal.current.show()}
          />
        </div>
      </div>
      <Modal
        ref={submitModal}
        title={""}
        showCancelButton={false}
        showOkButton={false}
        onClose={handleCloseModal}
        modalStyle={styles.modalStyle}
        modalHeader
        modalFooter
        closeIcon
        closeIconStyle={styles.closeIconStyle}
        modalInnerStyle={styles.modalInnerStyle}
      >
        <div className={styles.formWrapper}>
          <h2>Speak With An Advisor</h2>
          <Form onSubmit={confirmAction} classes={styles.formContainer}>
            <Input
              required={true}
              placeholder={"First Name"}
              name={"first-name"}
              classes={styles.inputContainer50}
              onChange={(event) => setFirstName(event)}
            />
            <Input
              required={true}
              placeholder={"Last Name"}
              name={"last-name"}
              classes={styles.inputContainer50}
              onChange={(event) => setLastName(event)}
            />
            <Input
              required={true}
              placeholder={"Email Address"}
              name={"email"}
              classes={styles.inputContainer50}
              onChange={(event) => setEmail(event)}
            />
            <Input
              required={true}
              placeholder={"Phone Number"}
              name={"phone-number"}
              classes={styles.inputContainer50}
              onChange={(event) => setPhoneNumber(event)}
            />
            <Input
              required={true}
              placeholder={"Address"}
              name={"address"}
              classes={styles.inputContainer50}
              onChange={(event) => setAddress(event)}
            />
            <DropDownMenuCustom
              required
              placeholder={"State of residence"}
              options={usaStates}
              onChange={(event) => setState(event)}
              classes={styles.inputContainer50}
            />
            <DropDownMenuCustom
              required
              placeholder={"Purpose of the Contact"}
              options={purposes}
              onChange={(event) => setPurpose(event)}
              classes={styles.inputContainer50}
            />
            <DropDownMenuCustom
              required
              placeholder={"Investable Assets"}
              options={investableAssets}
              onChange={(event) => setAssets(event)}
              classes={styles.inputContainer50}
            />
            <textarea
              placeholder={"Leave a note"}
              name={"note"}
              className={styles.inputContainer100}
              onChange={(event) => setNote(event.target.value)}
            />
            <Button
              enabled={!submitBtnEbabled}
              type={"submit"}
              classes={styles.submitButton}
              title={"Submit"}
            />
            <div className={styles.speakWithAnAdvisorDisclaimer}>
              <h3>Disclosure</h3>
              <p>
                I certify that I am the person that is identified in the
                information entered above, and I give Wells Investments, Inc.
                permission to contact me by email or telephone.
              </p>
            </div>
          </Form>
        </div>
      </Modal>
    </section>
  );
};

const Options = ({ answer, checked, handleCheckedAnswer, disabled }) => {
  const [selected, setSelected] = useState(checked);

  const handleCheck = () => {
    setSelected(!selected);
    handleCheckedAnswer(!selected);
  };
  return (
    <Card classes={styles.answerCard} onClick={() => handleCheck()}>
      <p>{answer}</p>
      <CheckBox
        checked={selected}
        disabled={disabled}
        className={styles.checkbox}
        onClick={() => {}}
      />
    </Card>
  );
};

export default ContinueTestSection;
