import { createAsyncThunk } from "@reduxjs/toolkit";
import NewsletterService from "../../../api/services/newsletter";

export const fetchAllNewsletter = createAsyncThunk(
  "newsletter/fetchAllNewsletter",
  async (requestData) => {
    const { search } = requestData;
    return await NewsletterService.fetchAllNewsletter(search);
  }
);

export const fetchNewsletters = createAsyncThunk(
  "newsletter/fetchNewsletters",
  async () => {
    return await NewsletterService.fetchNewsletters();
  }
);

export const deleteNewsletter = createAsyncThunk(
  "newsletter/deleteNewsletter",
  async (requestData) => {
    const { newsletterId } = requestData;
    return await NewsletterService.deleteNewsletter(newsletterId);
  }
);

export const createNewsletter = createAsyncThunk(
  "newsletter/createNewsletter",
  async (requestData) => {
    const { file, category, text, title, publishDate, description, showMore } =
      requestData;

    let formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("category", category);
    formData.append("text", text);
    formData.append("title", title);
    formData.append("publishDate", publishDate);
    formData.append("description", description);
    formData.append("showMore", showMore);

    return await NewsletterService.createNewsletter(formData);
  }
);

export const editNewsletter = createAsyncThunk(
  "newsletter/editNewsletter",
  async (requestData) => {
    const {
      id,
      file,
      category,
      text,
      title,
      publishDate,
      description,
      showMore,
    } = requestData;

    let formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("category", category);
    formData.append("text", text);
    formData.append("title", title);
    formData.append("publishDate", publishDate);
    formData.append("description", description);
    formData.append("showMore", showMore);
    formData.append("id", id);

    return await NewsletterService.editNewsletter(formData);
  }
);
