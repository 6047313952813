import { createAsyncThunk } from "@reduxjs/toolkit";
import DocumentToSignService from "../../../api/services/documentToSign";

export const fetchAllDocumentToSign = createAsyncThunk(
  "documentToSign/fetchAllDocumentToSign",
  async (requestData) => {
    const { page, sortBy, order, search } = requestData;
    return await DocumentToSignService.fetchAllDocumentToSign(
      page,
      sortBy,
      order,
      search
    );
  }
);

export const fetchDocumentToSign = createAsyncThunk(
  "documentToSign/fetchDocumentToSign",
  async () => {
    return await DocumentToSignService.fetchDocumentToSign();
  }
);

export const deleteDocumentToSign = createAsyncThunk(
  "documentToSign/deleteDocumentToSign",
  async (requestData) => {
    const { documentToSignId } = requestData;
    return await DocumentToSignService.deleteDocumentToSign(documentToSignId);
  }
);

export const createDocumentToSign = createAsyncThunk(
  "documentToSign/createDocumentToSign",
  async (requestData) => {
    const { documentName, file, userId, showToClient } = requestData;
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("documentName", documentName);
    formData.append("userId", userId);
    formData.append("showToClient", showToClient);

    return await DocumentToSignService.createDocumentToSign(formData);
  }
);

export const editDocumentToSign = createAsyncThunk(
  "documentToSign/editDocumentToSign",
  async (requestData) => {
    const { documentName, file, userId, id, showToClient } = requestData;
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("documentName", documentName);
    formData.append("userId", userId);
    formData.append("showToClient", showToClient);

    return await DocumentToSignService.editDocumentToSign(formData, id);
  }
);

export const fetchAllUserDocuments = createAsyncThunk(
  "documentToSign/fetchAllUserDocuments",
  async (requestData) => {
    const { id, page, sortBy, order, search } = requestData;
    return await DocumentToSignService.fetchAllUserDocuments(
      id,
      page,
      sortBy,
      order,
      search
    );
  }
);
