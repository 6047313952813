import React, { useEffect, useRef, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Card, Modal } from "../../../components";
import styles from "./LifePrioritiesAdmin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyInfo } from "../../../utils/notify";

import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import {
  selectLifePriorities,
  selectUpdateLifePrioritiesPending,
} from "../../../store/slices/lifePriorities/slice";
import {
  fetchLifePriorities,
  updateLifePriorities,
} from "../../../store/slices/lifePriorities/asyncThunks";

const LifePrioritiesAdmin = () => {
  const [title, setTitle] = useState("");
  const [key, setKey] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const pendingAction = useSelector(selectUpdateLifePrioritiesPending);
  const lifePriorites = useSelector(selectLifePriorities);

  const editOurServices = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLifePriorities());
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    resetInputFields();
    editOurServices.current.hide();
  };

  const handeEditLifePriorites = (item) => {
    setTitle(
      item.key.charAt(0).toUpperCase() + item.key.slice(1).replace("-", " ")
    );
    setKey(item.key);
    const contentBlock = htmlToDraft(item.text);
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.text))
      );

      setEditorState(editorState);
    }

    editOurServices.current.show();
  };

  const resetInputFields = () => {
    setTitle("");
    setKey("");
    setEditorState(EditorState.createEmpty());
  };

  const handleOkModal = () => {
    dispatch(
      updateLifePriorities({
        key,
        text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      })
    ).then((promise) => {
      if (promise.type.includes("fulfilled")) {
        editOurServices.current.hide();
        notifyInfo("Successfully updated");
      } else {
        notifyError(promise.error.message);
      }
    });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}></div>
      <div className={styles.servicesContainer}>
        {lifePriorites?.map((item, index) => (
          <Card classes={styles.services} key={index}>
            <div className={styles.editContainer}>
              <h2>
                {item.key.charAt(0).toUpperCase() +
                  item.key.slice(1).replace("-", " ")}
              </h2>
              <div
                className={styles.editIcon}
                onClick={() => handeEditLifePriorites(item)}
              >
                <EditIcon />
              </div>
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={editOurServices}
        title={title}
        showCancelButton
        showOkButton
        buttonOkText={"Update"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingAction}
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={styles.editorClassName}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
          }}
          onEditorStateChange={onEditorStateChange}
        />
      </Modal>
    </div>
  );
};

export default LifePrioritiesAdmin;
