import { createSlice } from "@reduxjs/toolkit";
import { fetchContactFormsBuilder, deleteContactFormBuilder } from "./builder";

const initialState = {
  contactForms: {
    data: [],
    perPage: null,
    total: 0,
  },
  fetchContactForms: false,
  deleteContactForm: false,
};

export const contactForms = createSlice({
  name: "contactForms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchContactFormsBuilder(builder);
    deleteContactFormBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectContactForm = (state) => state.contactForms.contactForms;
export const selectFetchContactFormPending = (state) =>
  state.contactForms.fetchContactForms;
export const selectDeleteContactFormPending = (state) =>
  state.contactForms.deleteContactForm;

export default contactForms.reducer;
