import styles from "./Chip.module.scss";

import { stringToHslColor } from "../../utils/util";

const Chip = ({ img, firstName, lastName }) => {
  if (img) {
    return (
      <div className={styles.chipContainer}>
        <img src={img} alt={`${firstName} ${lastName}`} />
      </div>
    );
  } else if (firstName && lastName) {
    return (
      <div
        className={styles.chipContainer}
        style={{
          backgroundColor: stringToHslColor(firstName + lastName, 40, 40),
        }}
      >
        <span>
          {firstName[0].toUpperCase()}
          {lastName[0].toUpperCase()}
        </span>
      </div>
    );
  }
};

export default Chip;
