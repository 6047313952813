import React, { useRef, useState, useEffect } from "react";
import { notifyError, notifyInfo } from "../../../utils/notify";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./CategoriesScreenAdmin.module.scss";

import { Card, Modal } from "../../../components";

import {
  selectCategories,
  selectEditCategoryPending,
} from "../../../store/slices/quiz/slice";
import {
  fetchCategories,
  editCategory,
} from "../../../store/slices/quiz/asyncThunks";

import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import { icons } from "../../../utils/constants";

const CategoriesScreenAdmin = () => {
  const modal = useRef();

  const [name, setName] = useState("");
  const [shortText, setShortText] = useState();
  const [id, setId] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const data = useSelector(selectCategories);
  const pendingAction = useSelector(selectEditCategoryPending);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleCloseModal = () => {
    modal.current.hide();
    resetFields();
  };

  const handleOkModal = () => {
    if (shortText === "" || description === "") {
      notifyError("Fill the input field");
    } else {
      dispatch(
        editCategory({
          id,
          shortText,
          description,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          modal.current.hide();
          notifyInfo("Successfully updated");
          resetFields();
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const resetFields = () => {
    setName("");
    setDescription("");
    setShortText("");
    setId(null);
  };

  const handleEdit = (item) => {
    setName(item.name);
    setId(item.id);
    setDescription(item.description);
    setShortText(item.shortText);
    modal.current.show();
  };

  return (
    <div className={styles.container}>
      <div className={styles.questionsListContainer}>
        {data.map((q, index) => {
          return (
            <Card key={index} classes={styles.questionContainer}>
              <div className={classNames(styles.category)}>
                <div>
                  <div className={styles.categoryHeader}>
                    <div>{icons[q.name]}</div>
                    <h2>{q.name}</h2>
                  </div>
                  <h3 className={styles.shortText}>{q.shortText}</h3>
                  <p className={styles.description}>{q.description}</p>
                </div>
                <div className={styles.actionBtnContainer}>
                  <div
                    className={styles.editIcon}
                    onClick={() => handleEdit(q)}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      <Modal
        ref={modal}
        modalHeader
        showCancelButton
        showOkButton
        buttonOkText={"Update"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        modalInnerStyle={styles.modalInnerStyle}
        pendingAction={pendingAction}
      >
        <div className={styles.categoryHeader}>
          {icons[name]}
          <h2>{name}</h2>
        </div>

        <textarea
          value={shortText}
          type={"textarea"}
          classes={styles.inputEdit}
          onChange={(e) => setShortText(e.target.value)}
          className={styles.inputContainer100}
          rows={3}
          placeholde={"Short Text"}
        />
        <textarea
          value={description}
          type={"textarea"}
          classes={styles.inputEdit}
          onChange={(e) => setDescription(e.target.value)}
          className={styles.inputContainer100}
          rows={7}
          placeholde={"Description"}
        />
      </Modal>
    </div>
  );
};

export default CategoriesScreenAdmin;
