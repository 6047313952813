import React from "react";
import styles from "./FinancialPlanning.module.scss";

import image from "./../../assets/images/ourServicesFinancial.jpg";

const FinancialPlanning = () => {
  return (
    <section className={styles.financialPlanning}>
      <img src={image} alt="Financial Planning" />

      <h1>Financial Planning</h1>
      <div>
        <p>
          Certified Financial Planner (CFP®) professionals undergo thorough
          initial and continuing education and testing to obtain and maintain
          this professional designation. This training helps prepare an advisor
          to assist clients with solving financial challenges. Wells Investments
          provides our clients access to the knowledge and expertise of a
          Certified Financial Planner (CFP®) professional who can help you build
          a roadmap for your future financial success. Financial planning is a
          broad, multi-faceted holistic approach that addresses a client’s
          complete financial picture. Working with an experienced and competent
          financial planner can help you achieve your short and long-term
          financial goals. Every client is unique. “Financial success” is
          uniquely defined by you and your goals. Whether you are looking to
          manage taxes, improve your estate planning, acquire insurance, save
          for retirement, or fund an education, financial planning can help you
          achieve your goals.
          <br />
          <br />
          We understand that no “one-size-fits-all” model can encompass
          everyone’s situation when it comes to financial planning. Because of
          that, we start every financial plan from scratch, learning about each
          new client and developing a relationship focused on a client’s needs
          at the center of all that we do.
          <br />
          <br />
          “Financial behaviors and decisions related to personal investments,
          mortgages, retirement, and savings require quite complex knowledge and
          skills, and healthy financial decisions require an ability to
          recognize long-term consequences of current choices” (Brown and
          Poterba, 2006).
          <br />
          <br />
          Our team can work closely with you to help you have a firm grasp on
          things like your monthly cash inflows and outflows. This may include
          routine expenses, unexpected major upcoming expenses, or income
          changes. Monitoring your cash flow is one step that helps you evaluate
          your overall progress in achieving your financial planning goals.
          Contact us to find out other ways we may help you.
          <br />
          <br />
          If financial planning is something that interests you or something
          that you want to learn more about, fill out our contact form to speak
          one-on-one with a member of our team.
          <br />
          <br />
          <br />
          Reference:
          <br />
          Brown, J. R., and Poterba, J. M. (2006). Household ownership of
          variable annuities. Tax Policy Econ. 20, 163–191. doi:
          10.1086/tpe.20.20061907
        </p>
      </div>
    </section>
  );
};

export default FinancialPlanning;
