import { axiosAuthClient } from "..";
import { handleError } from "../../utils/errors";

class AuthService {
  signUpWithEmail = async (
    email,
    firstName,
    lastName,
    password,
    accessCode
  ) => {
    try {
      const body = {
        email,
        firstName,
        lastName,
        password,
        accessCode,
      };

      const response = await axiosAuthClient.post("/sign-up", body);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, "Error occured while signing up new user");
    }
  };

  loginWithEmail = async (email, password) => {
    try {
      const response = await axiosAuthClient.post("/login", {
        email,
        password,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, user } = response.data.data;
      return { accessToken, refreshToken, user };
    } catch (err) {
      throw handleError(err, "Error occured while logging in");
    }
  };

  logout = async (refreshToken) => {
    try {
      const response = await axiosAuthClient.post("/logout", { refreshToken });
      if (response.status !== 200) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occured while logging out");
    }
  };

  //   verifyEmail = async (email, code) => {
  //     try {
  //       const response = await axiosAuthClient.post('/verify/email', { email, code });
  //       if (response.status !== 201) {
  //         throw new Error();
  //       }
  //       const { accessToken, refreshToken, user } = response.data.data;
  //       return { accessToken, refreshToken, user };
  //     } catch (err) {
  //       throw handleError(err, 'Error occured while verifying email');
  //     }
  //   };

  forgotPasswordSendCode = async (email) => {
    try {
      const response = await axiosAuthClient.post(
        "/forgotten-password/send-code",
        { email }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occured while sending email");
    }
  };

  forgotPassword = async (email, newPassword, code) => {
    try {
      const response = await axiosAuthClient.post("/forgotten-password", {
        email,
        newPassword,
        code,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occured while sending email");
    }
  };
}

export default new AuthService();
