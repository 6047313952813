import React from "react";
import { useSelector } from "react-redux";

import styles from "./YourAdvisorTeam.module.scss";
import { Link, useLocation } from "react-router-dom";

import { selectAdvisorTeam } from "../../store/slices/advisorTeam/slice";

import Card from "../../components/Card/Card";

const YourAdvisorTeam = () => {
  let location = useLocation();

  const advisorTeam = useSelector(selectAdvisorTeam);

  return (
    <section className={styles.yourAdvisorTeam}>
      <h1>Your advisor team</h1>
      <p>
        <b>
          Across the spectrum of the Wells Family of Investment Companies, you
          will find a professional, friendly support staff to serve you. They
          are highly qualified in their education and industry experience, and
          trained in the Wells “I Will” philosophy of creating a positive,
          caring client experience and getting the job done.
        </b>
      </p>
      <div className={styles.sectionTeam}>
        <Card>
          <div className={styles.imageContainer}>
            <img src={advisorTeam[0]?.profilePhoto} alt={advisorTeam?.name} />
          </div>
          <h3>{advisorTeam[0]?.name}</h3>
          <p>{advisorTeam[0]?.position}</p>
          <span>{advisorTeam[0]?.school}</span>
          <Link to={`${location.pathname}/${advisorTeam[0]?.id}`}>
            See more
          </Link>
        </Card>
        <Card>
          <div className={styles.imageContainer}>
            <img src={advisorTeam[1]?.profilePhoto} alt={advisorTeam?.name} />
          </div>
          <h3>{advisorTeam[1]?.name}</h3>
          <p>{advisorTeam[1]?.position}</p>
          <span>{advisorTeam[1]?.school}</span>
          <Link to={`${location.pathname}/${advisorTeam[1]?.id}`}>
            See more
          </Link>
        </Card>
        <Card>
          <div className={styles.imageContainer}>
            <img src={advisorTeam[2]?.profilePhoto} alt={advisorTeam?.name} />
          </div>
          <h3>{advisorTeam[2]?.name}</h3>
          <p>{advisorTeam[2]?.position}</p>
          <span>{advisorTeam[2]?.school}</span>
          <Link to={`${location.pathname}/${advisorTeam[2]?.id}`}>
            See more
          </Link>
        </Card>
        <Card>
          <div className={styles.imageContainer}>
            <img src={advisorTeam[3]?.profilePhoto} alt={advisorTeam?.name} />
          </div>
          <h3>{advisorTeam[3]?.name}</h3>
          <p>{advisorTeam[3]?.position}</p>
          <span>{advisorTeam[3]?.school}</span>
          <Link to={`${location.pathname}/${advisorTeam[3]?.id}`}>
            See more
          </Link>
        </Card>
      </div>
    </section>
  );
};

export default YourAdvisorTeam;
