import React, { useState } from "react";
import { Document, Page } from "react-pdf";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./PdfViewer.module.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ document }) => {
  const [numPages, setNumPages] = useState(null);
  return document !== null ? (
    <div>
      <Document
        file={document?.documentUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        loading={
          <div className={styles.ring}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
      >
        {numPages !== null &&
          Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page, index) => (
              <Page
                key={index++}
                pageNumber={page}
                className={styles.pageStyle}
                loading={<></>}
                width={1250}
              ></Page>
            ))}
      </Document>
    </div>
  ) : (
    <div></div>
  );
};

export default PdfViewer;
