import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/documentToSign";

class DocumentToSignService {
  downloadDocument = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/download/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.url;
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching url for download document."
      );
    }
  };

  signDocument = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/sign/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.url;
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching url for signing document."
      );
    }
  };

  signedDocument = async (id) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/signed/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching url for signing document."
      );
    }
  };

  fetchDocumentToSign = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while fetching documentToSign.");
    }
  };

  fetchAllDocumentToSign = async (page, sortBy, order, search) => {
    try {
      if (!sortBy) {
        sortBy = "";
      }
      if (!order) {
        order = "ASC";
      }

      const response = await axiosApiClient.get(
        `${BASE_URL}/all?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching all documentToSign."
      );
    }
  };

  deleteDocumentToSign = async (documentToSignId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/${documentToSignId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting documentToSign.");
    }
  };

  createDocumentToSign = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while creating documentToSign.");
    }
  };

  editDocumentToSign = async (formData, id) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/${id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing documentToSign.");
    }
  };

  fetchAllUserDocuments = async (id, page, sortBy, order, search) => {
    try {
      if (!sortBy) {
        sortBy = "";
      }
      if (!order) {
        order = "ASC";
      }

      const response = await axiosApiClient.get(
        `${BASE_URL}/client/${id}?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage, client } = response.data;
      return { data, total, perPage, client };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching all documentToSign."
      );
    }
  };
}

export default new DocumentToSignService();
