import React, { useState, useEffect } from "react";
import styles from "./Tabs.module.scss";
import classNames from "classnames";
import { Document, Page } from "react-pdf";
import { Card, Button } from "../";
import { useDispatch } from "react-redux";

import { ReactComponent as DownloadIcon } from "../../assets/icons/Download.svg";
import { ReactComponent as SignIcon } from "../../assets/icons/sign.svg";
import { ReactComponent as NoDataIcon } from "../../assets/icons/no.svg";
import DocumentToSignService from "../../api/services/documentToSign";
import { fetchDocumentToSign } from "../../store/slices/documentToSign/asyncThunks";
import { isoToDate } from "../../utils/util";
import { DOCUMENT_STATES } from "../../utils/constants";
import doc1 from "../../assets/lorem-ipsum1.pdf";
import docPage from "./../../assets/images/docPage.png";

const SIGNING_DONE = "signing_complete";

const Tabs = ({ data, documents }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("New");
  const [id, setId] = useState("");
  const [pendingAction, setPendingAction] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("event") === SIGNING_DONE) {
      setId(+params.get("documentId"));
      setPendingAction(true);
      DocumentToSignService.signedDocument(params.get("documentId"))
        .then(async (res) => {
          dispatch(fetchDocumentToSign({})).then((res) => {
            setId(0);
            setPendingAction(false);
            setActiveTab("Signed");
          });
        })
        .finally();
    }
  }, [window.location.search]);

  const handleOpenDoc = async (document) => {
    await DocumentToSignService.downloadDocument(document.id)
      .then((res) => {
        window.open(res, "_blank");
      })
      .finally(() => {
        setTimeout(() => {
          setId("");
          setPendingAction(false);
        }, 3000);
      });
  };

  const handleSignDoc = async (document) => {
    setId(document.id);
    setPendingAction(true);
    await DocumentToSignService.signDocument(document.id)
      .then((res) => {
        window.location.href = res;
      })
      .finally(() => {
        setTimeout(() => {
          setId("");
          setPendingAction(false);
        }, 3000);
      });
  };

  return (
    <div className={styles.tabs}>
      <ul className={styles.tabList}>
        {data.map((item) => {
          const label = item.label;
          return (
            <Tab
              setActiveTabHandler={(val) => setActiveTab(val)}
              key={item.id}
              label={label}
              activeTab={activeTab}
            />
          );
        })}
        <li className={styles.fillTheRest}></li>
      </ul>
      <div className={styles.tabContent}>
        {data.map((item) => {
          if (item.label === activeTab) {
            let docs =
              item.label === "New" ? documents.toSign : documents.signed;

            if (docs.length > 0) {
              return docs.map((doc) => (
                <div key={doc.id} className={styles.cardWrapper}>
                  <Card classes={styles.pdfPreviewDocument}>
                    <img className={styles.pdfPage} src={docPage}></img>
                  </Card>
                  <p className={styles.docName}>{doc.documentName}</p>
                  <span className={styles.docDate}>
                    {isoToDate(doc.createdAt)}
                  </span>
                  {item.label.toLowerCase() !== DOCUMENT_STATES.SIGNED && (
                    <Button
                      title={"Sign"}
                      classes={styles.btnSign}
                      loading={pendingAction && id === doc.id}
                      iconLeft={<SignIcon />}
                      onClick={() => handleSignDoc(doc)}
                    ></Button>
                  )}
                  <Button
                    title={"Download"}
                    classes={styles.btnDownload}
                    iconLeft={<DownloadIcon />}
                    onClick={() => handleOpenDoc(doc)}
                  ></Button>
                </div>
              ));
            } else {
              return (
                <div className={styles.noDataContainer} key={"0"}>
                  <NoDataIcon className={styles.noDataIcon} />
                  <span>No Data</span>
                </div>
              );
            }
          } else return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, setActiveTabHandler, activeTab }) => {
  return (
    <li
      className={classNames(
        styles.listItemElement,
        activeTab === label ? styles.activeTab : ""
      )}
      onClick={() => setActiveTabHandler(label)}
    >
      {label}
    </li>
  );
};

export default Tabs;
