import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../../api/services/user";

export const fetchUsers = createAsyncThunk(
  "clients/fetchUsers",
  async (requestData) => {
    const { page, sortBy, order, search } = requestData;
    return await userService.fetchUsers(page, sortBy, order, search);
  }
);

export const fetchAllUsers = createAsyncThunk(
  "clients/fetchAllUsers",
  async () => {
    return await userService.fetchAllUsers();
  }
);

export const deleteUser = createAsyncThunk(
  "clients/deleteUser",
  async (requestData) => {
    const { userId } = requestData;
    return await userService.deleteUser(userId);
  }
);

export const createUser = createAsyncThunk(
  "clients/createUser",
  async (requestData) => {
    return await userService.createUser(requestData);
  }
);

export const updateUser = createAsyncThunk(
  "clients/updateUser",
  async (requestData) => {
    return await userService.updateUser(requestData);
  }
);
