import { fetchLifePriorities, updateLifePriorities } from "./asyncThunks";

export const fetchLifePrioritiesBuilder = (builder) => {
  builder.addCase(fetchLifePriorities.pending, (state) => {
    state.fetchLifePrioritiesPending = true;
  });
  builder.addCase(fetchLifePriorities.fulfilled, (state, action) => {
    state.fetchLifePrioritiesPending = false;
    state.lifePriorities.data = action.payload.data.lifePriorities;
  });
  builder.addCase(fetchLifePriorities.rejected, (state) => {
    state.fetchLifePrioritiesPending = false;
  });
};

export const updateLifePrioritiesBuilder = (builder) => {
  builder.addCase(updateLifePriorities.pending, (state) => {
    state.updateLifePrioritiesPending = true;
  });
  builder.addCase(updateLifePriorities.fulfilled, (state, action) => {
    state.updateLifePrioritiesPending = false;
    state.lifePriorities.data = state.lifePriorities.data.map((s) => {
      if (s.id === action.payload.data.lifePriorities.id) {
        return action.payload.data.lifePriorities;
      } else {
        return s;
      }
    });
  });
  builder.addCase(updateLifePriorities.rejected, (state) => {
    state.updateLifePrioritiesPending = false;
  });
};
