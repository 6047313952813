import { createSlice } from "@reduxjs/toolkit";
import { fetchAdvisorTeamBuilder, updateAdvisorTeamBuilder } from "./builder";

export const initialState = {
  advisorTeam: {
    data: [],
  },
  fetchAdvisorTeamPending: false,
  updateAdvisorTeamPending: false,
};

export const advisorTeamSlice = createSlice({
  name: "advisorTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchAdvisorTeamBuilder(builder);
    updateAdvisorTeamBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

// export const {} = userSlice.actions;

export const selectAdvisorTeam = (state) => state.advisorTeam.advisorTeam.data;

export const selectFetchAdvisorTeamPending = (state) =>
  state.advisorTeam.fetchAdvisorTeamPending;
export const selectUpdateAdvisorTeamPending = (state) =>
  state.advisorTeam.updateAdvisorTeamPending;

export default advisorTeamSlice.reducer;
