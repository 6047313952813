import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/event";

class EventsService {
  fetchEvents = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { events } = response.data.data;
      return { events };
    } catch (err) {
      throw handleError(err, "Error occured while fetching events.");
    }
  };

  fetchAllEvents = async (search) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/all?search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { events } = response.data.data;
      return { events };
    } catch (err) {
      throw handleError(err, "Error occured while fetching all events.");
    }
  };

  deleteEvents = async (eventsId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${eventsId}`);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting events.");
    }
  };

  createEvents = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while creating events.");
    }
  };

  editEvents = async (formData) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/${formData.id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing events.");
    }
  };
}

export default new EventsService();
