import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/users";

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/me`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, "Error occured while fetching user's info.");
    }
  };

  editUserInfo = async (formData) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/me`, formData);
      if (response.status !== 200) {
        throw new Error();
      }
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, "Error occured while editing user's info.");
    }
  };

  createUser = async (formData) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}`, formData);
      if (response.status !== 201) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while signing up new student.");
    }
  };

  fetchUsers = async (page, sortBy, order, search) => {
    try {
      if (!sortBy) {
        sortBy = "";
      }
      if (!order) {
        order = "ASC";
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, "Error occured while fetching users.");
    }
  };

  fetchAllUsers = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/all`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, "Error occured while fetching users.");
    }
  };

  updateUser = async (formData) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/${formData.id}`,
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing user.");
    }
  };

  deleteUser = async (userId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${userId}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting user.");
    }
  };

  resetPassword = async (formData) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/reset-password`,
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error occured while password reset.");
    }
  };

  changePassword = async (formData) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}/change-password`,
        formData
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occured while password change.");
    }
  };
}

export default new UserService();
