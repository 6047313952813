import React, { useEffect, useState } from "react";
import styles from "./NewsletterDetailScreen.module.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { isoToDate } from "../../../utils/util";

import arrowLeft from "../../../assets/images/left-arrow-svgrepo-com.svg";

import img from "../../../assets/images/newsletter.png";
import NewsletterService from "../../../api/services/newsletter";

const NewsletterDetilsScreen = () => {
  const params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await NewsletterService.fetchNewsletter(params.id)
        .then((res) => {
          setData(res.newsletter);
        })
        .finally();
    };

    fetchData();
  }, [params]);

  return (
    <div className={styles.container}>
      {data?.photoUrl && (
        <img
          src={data?.photoUrl ? data?.photoUrl : img}
          alt={"banner"}
          className={styles.bannerImage}
        />
      )}
      <div className={styles.insideContainer}>
        <div className={styles.back}>
          <Link to="/client/newsletter">
            <img src={arrowLeft} alt="back" />
            Return to Newsletter
          </Link>
        </div>
        <h1 dangerouslySetInnerHTML={{ __html: data?.title }}></h1>
        <div className={styles.details}>
          <span dangerouslySetInnerHTML={{ __html: data?.category }}></span>
          <span
            dangerouslySetInnerHTML={{ __html: isoToDate(data?.publishDate) }}
          ></span>
        </div>
        <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
      </div>
    </div>
  );
};

export default NewsletterDetilsScreen;
