import React from "react";

import { ReactComponent as CheckboxUnChecked } from "../../assets/icons/check-box-outline-blank-sharp-24px copy.svg";
import { ReactComponent as CheckboxChecked } from "../../assets/icons/check-box-sharp-24px.svg";

import styles from "./CheckBox.module.scss";
import classNames from "classnames";

const CheckBox = ({ checked, onClick, className, text, disabled }) => {
  return (
    <div
      className={classNames(
        styles.checkBoxContainer,
        className,
        disabled ? styles.disabled : ""
      )}
      onClick={disabled ? () => {} : onClick}
    >
      {checked ? <CheckboxChecked /> : <CheckboxUnChecked />} <p>{text}</p>
    </div>
  );
};

CheckBox.defaultProps = {
  checked: false,
  onClick: () => alert("onClick"),
};

export default CheckBox;
