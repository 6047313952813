import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/news";

class NewsService {
  fetchNews = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { news } = response.data.data;
      return { news };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching news.");
    }
  };

  fetchAllNews = async (search) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/all?search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { news } = response.data.data;
      return { news };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching all news.");
    }
  };

  deleteNews = async (newsId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${newsId}`);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occurred while deleting news.");
    }
  };

  createNews = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occurred while creating news.");
    }
  };

  editNews = async (formData) => {
    try {
      const response = await axiosApiFreeClient.put(
        `${BASE_URL}/${formData.id}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occurred while creating news.");
    }
  };
}

export default new NewsService();
