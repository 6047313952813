import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import styles from "./PublicNavigation.module.scss";

import { ReactComponent as RetirementPlaning } from "../../assets/icons/Retirement.svg";
import { ReactComponent as FinancialPlaning } from "../../assets/icons/Financial.svg";
import { ReactComponent as InvestmentPlaning } from "../../assets/icons/Investments.svg";
import { ReactComponent as EstatePlaning } from "../../assets/icons/Estate.svg";
import { ReactComponent as TaxPlaning } from "../../assets/icons/Tax.svg";
import { ReactComponent as TrustPlaning } from "../../assets/icons/Trust.svg";

import { ReactComponent as LFFinances } from "../../assets/icons/FInance.svg";
import { ReactComponent as LFLeisure } from "../../assets/icons/Leisure.svg";
import { ReactComponent as LFFamily } from "../../assets/icons/Family.svg";
import { ReactComponent as LFEstatePlaning } from "../../assets/icons/Estate.svg";
import { ReactComponent as LFHealth } from "../../assets/icons/Health.svg";
import { ReactComponent as LFEployment } from "../../assets/icons/employment.svg";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon2-01.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_icon.svg";

import Button from "../Button/Button";

const PublicNavigation = ({ showSub, footer }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu((prev) => !prev);
  };

  const handleClick = () => {
    if (footer) {
      window.scrollTo(0, 0);
    }
  };

  const location = useLocation();

  return (
    <>
      {!footer && (
        <div className={styles.mobileMenu} onClick={handleMobileMenu}>
          <MenuIcon />
        </div>
      )}
      {mobileMenu && (
        <div className={styles.mobileMenuList}>
          <div className={styles.closeIcon} onClick={handleMobileMenu}>
            <CloseIcon />
          </div>
          <ul className={styles.mobileMenuContainer}>
            <li>
              <Link to="#">Our Services</Link>
              <ul className={styles.secondLevel}>
                <li onClick={handleMobileMenu}>
                  <Link to="/our-services/retirement-planning">
                    Retirement Planning
                  </Link>
                </li>
                <li onClick={handleMobileMenu}>
                  {" "}
                  <Link to="/our-services/investment-planning">
                    Investment Planning
                  </Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/our-services/financial-planning">
                    Financial Planning
                  </Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/our-services/estate-planning">
                    Estate Planning
                  </Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/our-services/tax-planning">Tax Planning</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/our-services/trust-planning">Trust Planning</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">Life priorities</Link>
              <ul className={styles.secondLevel}>
                <li onClick={handleMobileMenu}>
                  <Link to="/quiz">Take a Quiz</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/finances">Finances</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/family">Family</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/health">Health</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/leisure">Leisure</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/estate-planning">
                    Estate Planning
                  </Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/life-priorities/employment">Employment</Link>
                </li>
              </ul>
            </li>
            <li onClick={handleMobileMenu}>
              <Link to="/research-and-insights">Research and Insights</Link>
            </li>
            <li onClick={handleMobileMenu}>
              <Link to="/your-advisor-team">Your advisor team</Link>
            </li>
            <li onClick={handleMobileMenu}>
              <Link to="/about-us">About Us</Link>
            </li>
          </ul>
        </div>
      )}
      <ul className={classNames(styles.mainMenu)}>
        {!footer && (
          <li className={styles.containsSubMenu}>
            <Link
              to="#"
              className={classNames(
                styles.tabLink,
                location.pathname.includes("our-services") &&
                  styles.activeTabLink
              )}
            >
              Our Services
            </Link>
            {showSub && <OurServicesSubMenu />}
          </li>
        )}
        {!footer && (
          <li className={styles.containsSubMenu}>
            <Link
              to="#"
              className={classNames(
                styles.tabLink,
                (location.pathname.includes("life-priorities") ||
                  location.pathname.includes("quiz")) &&
                  styles.activeTabLink
              )}
            >
              Life priorities
            </Link>
            {showSub && <LifePrioritiesSubMenu />}
          </li>
        )}
        <li>
          <Link
            onClick={handleClick}
            to="/research-and-insights"
            className={classNames(
              styles.tabLink,
              location.pathname.includes("research-and-insights") &&
                (footer ? styles.activeTabLinkFooter : styles.activeTabLink)
            )}
          >
            Research and Insights
          </Link>
        </li>
        <li>
          <Link
            onClick={handleClick}
            to="/your-advisor-team"
            className={classNames(
              styles.tabLink,
              location.pathname.includes("your-advisor-team") &&
                (footer ? styles.activeTabLinkFooter : styles.activeTabLink)
            )}
          >
            Your advisor team
          </Link>
        </li>
        <li>
          <Link
            onClick={handleClick}
            to="/about-us"
            className={classNames(
              styles.tabLink,
              location.pathname.includes("about-us") &&
                (footer ? styles.activeTabLinkFooter : styles.activeTabLink)
            )}
          >
            About Us
          </Link>
        </li>
      </ul>
    </>
  );
};

const OurServicesSubMenu = ({ footer }) => {
  const handleClick = () => {
    if (footer) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <div
      className={classNames(
        footer ? styles.subMenuFooter : styles.subMenu,
        "subMenu"
      )}
      id="our-services"
    >
      <div className={styles.rightPart}>
        <div className={styles.spanWrapper}>
          <span className={styles.spanText}>Our Services</span>
        </div>
        <nav>
          <ul>
            <li>
              {!footer && <RetirementPlaning className={styles.logo} />}
              <Link
                onClick={handleClick}
                to="/our-services/retirement-planning"
              >
                Retirement Planning
              </Link>
            </li>
            <li>
              {!footer && <InvestmentPlaning className={styles.logo} />}
              <Link
                onClick={handleClick}
                to="/our-services/investment-planning"
              >
                Investment Planning
              </Link>
            </li>
            <li>
              {!footer && <FinancialPlaning className={styles.logo} />}
              <Link onClick={handleClick} to="/our-services/financial-planning">
                Financial Planning
              </Link>
            </li>
            <li>
              {!footer && <EstatePlaning className={styles.logo} />}
              <Link onClick={handleClick} to="/our-services/estate-planning">
                Estate Planning
              </Link>
            </li>
            <li>
              {!footer && <TaxPlaning className={styles.logo} />}
              <Link onClick={handleClick} to="/our-services/tax-planning">
                Tax Planning
              </Link>
            </li>
            <li>
              {!footer && <TrustPlaning className={styles.logo} />}
              <Link onClick={handleClick} to="/our-services/trust-planning">
                Trust Planning
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const LifePrioritiesSubMenu = ({ footer }) => {
  const handleClick = () => {
    if (footer) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <div
      className={classNames(
        footer ? styles.subMenuFooter : styles.subMenu,
        styles.twoPartsSubMenuHeight
      )}
      id="life-priorities"
    >
      <div className={styles.twoPartsSubMenu}>
        {!footer && (
          <div className={styles.leftPart}>
            <span>Our goal is to help you reach yours.</span>
            <p>
              Together we will design a strategic plan that focuses on you and
              your needs.
            </p>
            <Link to="quiz">
              <Button classes={styles.btnQuiz} title={"Take a Quiz"} />
            </Link>
          </div>
        )}
        <div className={styles.rightPart}>
          <div className={styles.spanWrapper}>
            <span>Life Priorities</span>
          </div>
          <nav>
            <ul>
              <li>
                {!footer && <LFFinances className={styles.logo} />}
                <Link onClick={handleClick} to="/life-priorities/finances">
                  Finances
                </Link>
              </li>
              <li>
                {!footer && <LFFamily className={styles.logo} />}
                <Link onClick={handleClick} to="/life-priorities/family">
                  Family
                </Link>
              </li>
              <li>
                {!footer && <LFHealth className={styles.logo} />}
                <Link onClick={handleClick} to="/life-priorities/health">
                  Health
                </Link>
              </li>
              <li>
                {!footer && <LFLeisure className={styles.logo} />}
                <Link onClick={handleClick} to="/life-priorities/leisure">
                  Leisure
                </Link>
              </li>

              <li>
                {!footer && <LFEstatePlaning className={styles.logo} />}
                <Link
                  onClick={handleClick}
                  to="/life-priorities/estate-planning"
                >
                  Estate Planning
                </Link>
              </li>

              <li>
                {!footer && <LFEployment className={styles.logo} />}
                <Link onClick={handleClick} to="/life-priorities/employment">
                  Employment
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export { PublicNavigation, OurServicesSubMenu, LifePrioritiesSubMenu };
