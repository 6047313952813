import localStorageService from "../../../services/localStorageService";
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "../../../utils/constants";
import { fetchUserInfo } from "../user/asyncThunks";
import {
  login,
  logout,
  signup,
  forgotPassword,
  forgotPasswordSendCode,
} from "./asyncThunks";

export const loginBuilder = (builder) => {
  builder.addCase(login.pending, (state) => {
    state.loginPending = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken, refreshToken } = action.payload;
    state.loginPending = false;

    state.loggedIn = true;
    localStorageService.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
    localStorageService.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  });
  builder.addCase(login.rejected, (state, action) => {
    state.loginPending = false;
  });
};

export const fetchUserInfoBuilder = (builder) => {
  builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
    state.loggedIn = true;
  });
  builder.addCase(fetchUserInfo.rejected, (state, action) => {
    state.loggedIn = false;
  });
};

export const logoutBuilder = (builder) => {
  builder.addCase(logout.fulfilled, (state) => {
    localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    state.loggedIn = false;
  });
  builder.addCase(logout.rejected, (state) => {
    localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    state.loggedIn = false;
  });
};

export const signupBuilder = (builder) => {
  builder.addCase(signup.pending, (state) => {
    state.signupPending = true;
  });
  builder.addCase(signup.fulfilled, (state, action) => {
    state.signupPending = false;
  });
  builder.addCase(signup.rejected, (state, action) => {
    state.signupPending = false;
  });
};

export const forgotPasswordBuilder = (builder) => {
  builder.addCase(forgotPassword.pending, (state) => {
    state.forgotPasswordPending = true;
  });
  builder.addCase(forgotPassword.fulfilled, (state, action) => {
    state.forgotPasswordPending = false;
  });
  builder.addCase(forgotPassword.rejected, (state, action) => {
    state.forgotPasswordPending = false;
  });
};

export const forgotPasswordSendCodeBuilder = (builder) => {
  builder.addCase(forgotPasswordSendCode.pending, (state) => {
    state.forgotPasswordSendCodePending = true;
  });
  builder.addCase(forgotPasswordSendCode.fulfilled, (state, action) => {
    state.forgotPasswordSendCodePending = false;
  });
  builder.addCase(forgotPasswordSendCode.rejected, (state, action) => {
    state.forgotPasswordSendCodePending = false;
  });
};
