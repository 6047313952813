import {
  fetchNewsletters,
  fetchAllNewsletter,
  deleteNewsletter,
  createNewsletter,
  editNewsletter,
} from "./asyncThunks";

export const fetchAllNewsletterBuilder = (builder) => {
  builder.addCase(fetchAllNewsletter.pending, (state) => {
    state.fetchNewsletter = true;
  });
  builder.addCase(fetchAllNewsletter.fulfilled, (state, action) => {
    state.fetchNewsletter = false;
    state.newsletter.data = action.payload.newsletter;
  });
  builder.addCase(fetchAllNewsletter.rejected, (state) => {
    state.fetchNewsletter = false;
  });
};

export const fetchNewsletterBuilder = (builder) => {
  builder.addCase(fetchNewsletters.pending, (state) => {
    state.fetchNewsletter = true;
  });
  builder.addCase(fetchNewsletters.fulfilled, (state, action) => {
    state.fetchNewsletter = false;
    state.newsletter.data = action.payload.newsletter;
  });
  builder.addCase(fetchNewsletters.rejected, (state) => {
    state.fetchNewsletter = false;
  });
};

export const deleteNewsletterBuilder = (builder) => {
  builder.addCase(deleteNewsletter.pending, (state) => {
    state.deleteNewsletter = true;
  });
  builder.addCase(deleteNewsletter.fulfilled, (state, action) => {
    state.deleteNewsletter = false;
    state.newsletter.data = state.newsletter.data.map((s) => {
      if (s.id === action.payload.data.result.id) {
        s.deletedAt = "deleted";
      }
      return s;
    });
  });
  builder.addCase(deleteNewsletter.rejected, (state) => {
    state.deleteNewsletter = false;
  });
};

export const createNewsletterBuilder = (builder) => {
  builder.addCase(createNewsletter.pending, (state) => {
    state.createNewsletter = true;
  });
  builder.addCase(createNewsletter.fulfilled, (state, action) => {
    state.createNewsletter = false;
    state.newsletter.data.unshift(action.payload.data.newsletter);
  });
  builder.addCase(createNewsletter.rejected, (state) => {
    state.createNewsletter = false;
  });
};

export const editNewsletterBuilder = (builder) => {
  builder.addCase(editNewsletter.pending, (state) => {
    state.editNewsletter = true;
  });
  builder.addCase(editNewsletter.fulfilled, (state, action) => {
    state.editNewsletter = false;
    state.newsletter.data = state.newsletter.data.map((s) => {
      if (s.id === action.payload.data.newsletter.id) {
        return action.payload.data.newsletter;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editNewsletter.rejected, (state) => {
    state.editNewsletter = false;
  });
};
