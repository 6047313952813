import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import styles from "./PdfViewerScreen.module.scss";
import arrowLeft from "../../assets/images/left-arrow-svgrepo-com.svg";
import ResearchAndInsightService from "../../api/services/researchAndInsight";

import { PdfViewer } from "../../components";

const PdfViewerScreen = () => {
  const params = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await ResearchAndInsightService.fetchResearchAndInsightDocument(params.id)
        .then((res) => {
          setData(res);
        })
        .finally();
    };

    fetchData();
  }, [params]);

  return (
    <div className={styles.pdfViewerContainer}>
      <div className={styles.back}>
        <Link to="/research-and-insights">
          <img src={arrowLeft} alt="back" />
          Return to research and insights
        </Link>
      </div>
      <h1>{data?.title}</h1>
      <PdfViewer document={data}> </PdfViewer>
    </div>
  );
};

export default PdfViewerScreen;
