import { createAsyncThunk } from "@reduxjs/toolkit";
import LifePriorities from "../../../api/services/lifePriorities";

export const fetchLifePriorities = createAsyncThunk(
  "lifePriorities/fetchLifePriorities",
  async () => {
    return await LifePriorities.fetchLifePriorities();
  }
);

export const updateLifePriorities = createAsyncThunk(
  "lifePriorities/updateLifePriorities",
  async (requestData) => {
    return await LifePriorities.updateLifePriorities(requestData);
  }
);
