import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../../api/services/user";

export const fetchUserInfo = createAsyncThunk(
  "userInfo/fetchUserInfo",
  async () => await userService.fetchUserInfo()
);

export const editUserInfo = createAsyncThunk(
  "userInfo/editUserInfo",
  async (requestData) => {
    const { firstName, lastName, imageFile, oldPassword, newPassword } =
      requestData;
    let formData = new FormData();

    if (imageFile) {
      formData.append("file", imageFile);
    }
    if (oldPassword) {
      formData.append("oldPassword", oldPassword);
    }
    if (newPassword) {
      formData.append("newPassword", newPassword);
    }
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);

    return await userService.editUserInfo(formData);
  }
);
