import React from "react";

const ContactUs = () => {
  return (
    <>
      <h2>ContactUs</h2>
    </>
  );
};

export default ContactUs;
