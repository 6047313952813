import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import { PublicLayout, PrivateLayout, ClientLayout } from "../../layout";

import {
  Home,
  ErrorScreen,
  YourAdvisorTeam,
  AboutUs,
  ResearchAndInsights,
  ResearchAndInsightsDetailScreen,
  LifePriorities,
  ContactUs,
  ClientLogin,
  RetirementPlanning,
  InvestmentPlanning,
  FinancialPlanning,
  EstatePlanning,
  TaxPlanning,
  TrustPlanning,
  AdvisorTeamMember,
  HealthScreen,
  LPEstatePlaning,
  LeisureScreen,
  FinanceScreen,
  EmploymentScreen,
  FamilyScreen,
  LoginScreen,
  NewsScreen,
  ContactFormScreen,
  DocumentsScreen,
  NewsletterScreen,
  YourAdvisorTeamAdmin,
  OurServicesAdmin,
  LifePrioritiesAdmin,
  PdfViewerScreen,
  ClientsScreen,
  ReferralScreen,
  EventsScreen,
  ReferralAdminScreen,
  NewsletterAdminScreen,
  NewsletterDetailScreen,
  QuizScreen,
  DocumentsToSignScreen,
  QuizScreenAdmin,
  ResearchAndInsightsScreenAdmin,
  CategoriesScreenAdmin,
  QuizResultsScreenAdmin,
  ClientDetailsScreen,
  MutualFundScreen,
} from "../../screens";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedIn } from "../../store/slices/auth/slice";
import { fetchUserInfo } from "../../store/slices/user/asyncThunks";

function App() {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectLoggedIn);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {isAuthenticated !== null && minimumDurationPassed ? (
        <div
          className="app"
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <ToastContainer
            newestOnTop={true}
            autoClose={3500}
            position={toast.POSITION.TOP_RIGHT}
          />
          <Routes>
            <Route element={<PrivateLayout />}>
              <Route path="/admin/news" element={<NewsScreen />} />
              <Route
                path="/admin/contact-form"
                element={<ContactFormScreen />}
              />
              <Route
                path="/admin/your-advisor-team"
                element={<YourAdvisorTeamAdmin />}
              />
              <Route
                path="/admin/our-services"
                element={<OurServicesAdmin />}
              />
              <Route
                path="/admin/life-priorities"
                element={<LifePrioritiesAdmin />}
              />
              <Route path="/admin/events" element={<EventsScreen />} />
              <Route path="/admin/clients" element={<ClientsScreen />} />
              <Route
                path="/admin/clients/:id"
                element={<ClientDetailsScreen />}
              />

              <Route
                path="/admin/referrals"
                element={<ReferralAdminScreen />}
              />
              <Route
                path="/admin/newsletter"
                element={<NewsletterAdminScreen />}
              />
              <Route
                path="/admin/documents-to-sign"
                element={<DocumentsToSignScreen />}
              />
              <Route path="/admin/quiz" element={<QuizScreenAdmin />} />
              <Route
                path="/admin/research-and-insights"
                element={<ResearchAndInsightsScreenAdmin />}
              />
              <Route
                path="/admin/categories"
                element={<CategoriesScreenAdmin />}
              />
              <Route
                path="/admin/quiz-results"
                element={<QuizResultsScreenAdmin />}
              />
            </Route>
            <Route element={<ClientLayout />}>
              <Route path="/client/documents" element={<DocumentsScreen />} />
              <Route path="/client/newsletter" element={<NewsletterScreen />} />
              <Route path="/client/referral" element={<ReferralScreen />} />
              <Route
                path="/client/newsletter/:id"
                element={<NewsletterDetailScreen />}
              />
            </Route>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/life-priorities" element={<LifePriorities />} />
              <Route
                path="/research-and-insights"
                element={<ResearchAndInsights />}
              />
              <Route
                path="/research-and-insights/:id"
                element={<ResearchAndInsightsDetailScreen />}
              />
              <Route path="/your-advisor-team" element={<YourAdvisorTeam />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/login" element={<ClientLogin />} />
              <Route path="/mutual-funds" element={<MutualFundScreen />} />
              <Route
                path="/research-and-insights/pdf/:id"
                element={<PdfViewerScreen />}
              />

              <Route
                path="/our-services/retirement-planning"
                element={<RetirementPlanning />}
              />
              <Route
                path="/our-services/investment-planning"
                element={<InvestmentPlanning />}
              />
              <Route
                path="/our-services/financial-planning"
                element={<FinancialPlanning />}
              />
              <Route
                path="/our-services/estate-planning"
                element={<EstatePlanning />}
              />
              <Route
                path="/our-services/tax-planning"
                element={<TaxPlanning />}
              />
              <Route
                path="/our-services/trust-planning"
                element={<TrustPlanning />}
              />
              <Route
                path="/your-advisor-team/:id"
                element={<AdvisorTeamMember />}
              />

              <Route
                path="/life-priorities/finances"
                element={<FinanceScreen />}
              />
              <Route
                path="/life-priorities/family"
                element={<FamilyScreen />}
              />
              <Route
                path="/life-priorities/health"
                element={<HealthScreen />}
              />
              <Route
                path="/life-priorities/leisure"
                element={<LeisureScreen />}
              />
              <Route
                path="/life-priorities/estate-planning"
                element={<LPEstatePlaning />}
              />
              <Route
                path="/life-priorities/employment"
                element={<EmploymentScreen />}
              />
              <Route path="/quiz" element={<QuizScreen />}>
                <Route path="/quiz/:id" element={<QuizScreen />} />
              </Route>
            </Route>
            <Route path="/admin/login" element={<LoginScreen />} />
            <Route path="*" element={<ErrorScreen />} />
          </Routes>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
}

export default App;
