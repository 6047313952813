import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PublicNavigation } from "../../components/PublicNavigation/PublicNavigation";
import PublicNavigationTop from "../../components/PublicNavigation/PublicNavigationTop";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { fetchLifePriorities } from "../../store/slices/lifePriorities/asyncThunks";
import { fetchAdvisorTeam } from "../../store/slices/advisorTeam/asyncThunks";

const PublicLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLifePriorities());
    dispatch(fetchAdvisorTeam());
  }, [dispatch]);

  return (
    <>
      <Header
        topNavNavigation={<PublicNavigationTop />}
        mainNavigation={<PublicNavigation showSub />}
      />
      <Outlet />
      <Footer />
    </>
  );
};

export default PublicLayout;
