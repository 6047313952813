import React from "react";
import styles from "./DisclaimerAndDisclosure.module.scss";

const DisclaimerAndDisclosure = () => {
  return (
    <section className={styles.disclaimerAndDisclosure}>
      <h1>Disclaimer &amp; Disclosure</h1>
      <p>
        Investing involves risk. There is always the potential of losing money
        when you invest in securities. Past performance does not guarantee
        future results. Asset allocation, rebalancing and diversification do not
        guarantee against risk in broadly declining markets. Asset allocation,
        rebalancing and diversification do not guarantee your portfolio will not
        decline in broadly declining markets. Wells Investments, Inc., its
        affiliates, and financial advisors do not provide legal, tax, or
        accounting advice. You should consult your legal and/or tax advisors
        before making any financial decisions. Wells Investments, Inc. or
        affiliated companies offer a broad range of investment advisory services
        (including financial and estate planning) and other services. The
        material presented here does not take into account a client’s or
        potential client’s particular financial situations, investment
        objectives, desires, or needs. This material is not intended as a
        recommendation, offer, or solicitation for the purchase or sale of any
        security or investment strategy. Our advisory services are fee-based.
        Wells Investments, Inc. does not charge commissions. For more
        information about our services, speak with a Wells Investments, Inc.
        financial advisor. Investments, insurance and annuity products: Are Not
        FDIC Insured; Are Not Bank Guaranteed; May Lose Value; Are Not Deposits;
        Are Not Insured by Any Federal Government Agency; Are Not a Condition to
        Any Banking Service or Activity. Advisory services are offered through
        Wells Investments, Inc., a registered investment advisor. Wells
        Investments, Inc. and investment advisory representatives may discuss
        and/or transact business only with residents of the states in which that
        are properly registered or licensed. No offers may be made to or
        accepted from any resident of any other state.
      </p>
    </section>
  );
};

export default DisclaimerAndDisclosure;
