import { fetchNews, deleteNews, createNews, editNews } from "./asyncThunks";

export const fetchNewsBuilder = (builder) => {
  builder.addCase(fetchNews.pending, (state) => {
    state.fetchNews = true;
  });
  builder.addCase(fetchNews.fulfilled, (state, action) => {
    state.fetchNews = false;
    state.news.data = action.payload.news;
  });
  builder.addCase(fetchNews.rejected, (state) => {
    state.fetchNews = false;
  });
};

export const deleteNewsBuilder = (builder) => {
  builder.addCase(deleteNews.pending, (state) => {
    state.deleteNews = true;
  });
  builder.addCase(deleteNews.fulfilled, (state, action) => {
    state.deleteNews = false;
    state.news.data = state.news.data.map((s) => {
      if (s.id === action.payload.data.result.id) {
        s.deletedAt = "deleted";
      }
      return s;
    });
  });
  builder.addCase(deleteNews.rejected, (state) => {
    state.deleteNews = false;
  });
};

export const createNewsBuilder = (builder) => {
  builder.addCase(createNews.pending, (state) => {
    state.createNews = true;
  });
  builder.addCase(createNews.fulfilled, (state, action) => {
    state.createNews = false;
    state.news.data.unshift(action.payload.data.news);
  });
  builder.addCase(createNews.rejected, (state) => {
    state.createNews = false;
  });
};

export const editNewsBuilder = (builder) => {
  builder.addCase(editNews.pending, (state) => {
    state.editNews = true;
  });
  builder.addCase(editNews.fulfilled, (state, action) => {
    state.editNews = false;
    state.news.data = state.news.data.map((item) => {
      if (item.id === action.payload.data.news.id) {
        return action.payload.data.news;
      } else {
        return item;
      }
    });
  });
  builder.addCase(editNews.rejected, (state) => {
    state.editNews = false;
  });
};
