import React, { useEffect, useRef, useState } from "react";
import { notifyError, notifyInfo } from "../../../utils/notify";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import styles from "./QuizScreenAdmin.module.scss";

import { Card, Modal, Input } from "../../../components";
import DropDownMenuCustom from "../../../components/DropDownMenuCustom/DropDownMenuCustom";

import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import { icons } from "../../../utils/constants";

import {
  selectQuestion,
  selectCategories,
} from "../../../store/slices/quiz/slice";
import {
  fetchQuestions,
  fetchCategories,
  editQuestion,
} from "../../../store/slices/quiz/asyncThunks";

const QuizScreenAdmin = () => {
  const modal = useRef();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");
  const [type, setType] = useState();
  const [id, setId] = useState(null);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    dispatch(fetchQuestions());
    dispatch(fetchCategories());
  }, [dispatch]);

  const questions = useSelector(selectQuestion);
  const categories = useSelector(selectCategories);

  const handleCloseModal = () => {
    modal.current.hide();
    resetFields();
  };

  const handleOkModal = () => {
    if (selected === "") {
      notifyError("Fill the input field");
    } else {
      dispatch(
        editQuestion({
          id,
          text: selected,
          answers,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          notifyInfo("Successfully updated");
          modal.current.hide();
          resetFields();
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const resetFields = () => {
    setSelected("");
    setType("");
    setAnswers([]);
    setId(null);
  };

  const handleChange = (type, answer, event) => {
    let newAnswer = { ...answer, category: { ...answer.category } };
    if (type === "input") {
      newAnswer.text = event;
    } else {
      const newCategory = categories.find((c) => {
        if (c.name === event) {
          return true;
        }
        return false;
      });
      newAnswer.category.id = newCategory.id;
      newAnswer.category.name = newCategory.name;
    }
    console.log(newAnswer);

    let newAnswers = answers.map((a) => {
      if (a.id === newAnswer.id) {
        return newAnswer;
      } else {
        return a;
      }
    });

    setAnswers(newAnswers);
  };

  const handleSelected = (item, typeInput) => {
    setSelected(item.text);
    setType(typeInput);
    setId(item.id);
    setAnswers(item.answers);
    modal.current.show();
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1>Simple questions section</h1>
      </div>
      <div className={styles.questionsListContainer}>
        {questions.slice(0, 3).map((q, index) => {
          return (
            <Card key={index} classes={styles.questionContainer}>
              <div className={styles.question}>
                <h2>{q.text}</h2>
                <div
                  className={styles.editIcon}
                  onClick={() => handleSelected(q, "Question")}
                >
                  <EditIcon />
                </div>
              </div>
              <ul className={styles.answers}>
                {q.answers.map((a, aIndex) => {
                  return (
                    <li
                      key={`${index}-${aIndex}`}
                      className={styles.answerConrainer}
                    >
                      <span>{a.text}</span>
                      <span className={styles.category}>
                        <b>
                          <i>{a.category.name}</i>
                        </b>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Card>
          );
        })}
      </div>
      <div className={styles.headerContainer}>
        <h1>Goals section</h1>
      </div>
      <div className={styles.questionsListContainer}>
        {questions.slice(3).map((q, index) => {
          return (
            <Card key={index} classes={styles.questionContainer}>
              <div className={classNames(styles.question, styles.icon)}>
                <div>{icons[q.text]}</div>
                <h2>{q.text}</h2>
                <div
                  className={styles.editIcon}
                  onClick={() => handleSelected(q, "Goals")}
                >
                  <EditIcon />
                </div>
              </div>
              <ul className={styles.answers}>
                {q.answers.map((a, aIndex) => {
                  return (
                    <li
                      key={`${index}-${aIndex}`}
                      className={styles.answerConrainer}
                    >
                      <span>{a.text}</span>
                    </li>
                  );
                })}
              </ul>
            </Card>
          );
        })}
      </div>
      <Modal
        ref={modal}
        modalHeader
        showCancelButton
        showOkButton
        buttonOkText={"Update"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        modalInnerStyle={styles.modalInnerStyle}
      >
        <div>
          <h2>{type}:</h2>
          <Input
            value={selected}
            type={"text"}
            classes={styles.inputEdit}
            onChange={(e) => setSelected(e)}
            readOnly={type !== "Question"}
          />
          <h2 style={{ paddingTop: "20px" }}>Answers:</h2>
          {answers.map((answer) => {
            return (
              <div key={answer.id} style={{ paddingBottom: "20px" }}>
                <Input
                  value={answer.text}
                  type={"text"}
                  classes={styles.inputEdit}
                  onChange={(e) => handleChange("input", answer, e)}
                />
                {type === "Question" && (
                  <DropDownMenuCustom
                    required
                    placeholder={"Category"}
                    onChange={(event) =>
                      handleChange("category", answer, event)
                    }
                    classes={classNames(
                      styles.dropDown,
                      styles.inputContainer50
                    )}
                    options={categories}
                    defaultValue={answer.category?.name}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default QuizScreenAdmin;
