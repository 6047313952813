import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Card,
  Modal,
  Input,
  CheckBox,
  FileUpload,
} from "../../../components";
import moment from "moment";
import styles from "./NewsletterAdminScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyInfo } from "../../../utils/notify";
import debounce from "lodash.debounce";
import classNames from "classnames";
import { isoToDate } from "../../../utils/util";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import {
  selectNewsletter,
  selectDeleteNewsletterPending,
  selectCreateNewsletterPending,
  selectEditNewsletterPending,
} from "../../../store/slices/newsletter/slice";
import {
  fetchAllNewsletter,
  deleteNewsletter,
  createNewsletter,
  editNewsletter,
} from "../../../store/slices/newsletter/asyncThunks";

const NewsletterScreen = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const [publishDate, setPublishDate] = useState("");
  const [category, setCategory] = useState("");
  const [showMore, setShowMore] = useState(false);

  const [description, setDescription] = useState(EditorState.createEmpty());

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const dispatch = useDispatch();
  const newsletters = useSelector(selectNewsletter);

  const pendingDeleteAction = useSelector(selectDeleteNewsletterPending);
  const pendingCreateAction = useSelector(selectCreateNewsletterPending);
  const pendingEditAction = useSelector(selectEditNewsletterPending);

  const createNewsletterModal = useRef();
  const deleteNewsletterModal = useRef();

  const changeHandler = useCallback(
    (text) => {
      dispatch(fetchAllNewsletter({ search: text }));
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchAllNewsletter({ search: "" }));
  }, [dispatch]);

  const handleCreateNewsletter = () => {
    resetInputFields();
    createNewsletterModal.current.show();
  };

  const handleCloseCreateModal = () => {
    resetInputFields();
    createNewsletterModal.current.hide();
  };

  const handleEditNewsletter = (item) => {
    setPublishDate(moment(item.publishDate).format("yyyy-MM-DD"));
    setTitle(item.title);
    setCategory(item.category);
    setText(item.text);
    setId(item.id);
    setShowMore(item.showMore);
    setImageName("");
    setImage(null);

    const contentBlock = htmlToDraft(item.text);
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.description))
      );

      setDescription(editorState);
    }

    createNewsletterModal.current.show();
  };

  const handleDeleteNewsletter = (item) => {
    setId(item.id);
    setTitle(item.title);

    deleteNewsletterModal.current.show();
  };

  const handleDeleteOkModal = () => {
    dispatch(deleteNewsletter({ newsletterId: id })).then((promise) => {
      deleteNewsletterModal.current.hide();
      notifyInfo("Newsletter deleted.");
    });
  };

  const handleDeleteCloseModal = () => {
    setTitle("");
    deleteNewsletterModal.current.hide();
  };

  const resetInputFields = () => {
    setTitle("");
    setCategory("");
    setPublishDate("");
    setText("");
    setId("");
    setShowMore(false);
    setImageName("");
    setImage(null);
    setDescription(EditorState.createEmpty());
  };

  const handleOkModal = () => {
    if (id) {
      dispatch(
        editNewsletter({
          category,
          text,
          title,
          publishDate,
          description: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ),
          showMore,
          id,
          file: image,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createNewsletterModal.current.hide();
          notifyInfo("Newsletter successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    } else {
      dispatch(
        createNewsletter({
          category,
          text,
          title,
          publishDate,
          description: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ),
          showMore,
          file: image,
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createNewsletterModal.current.hide();
          notifyInfo("Newsletter successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <SearchInput onChange={debouncedChangeHandler} placeholder="Search" />
        </div>
        <button
          className={styles.createNewsletterBtn}
          onClick={handleCreateNewsletter}
        >
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.newsletterCardContainer}>
        {newsletters?.map((item) => (
          <Card
            classes={classNames(
              styles.categories,
              item.deletedAt ? styles.deletedNewsletter : ""
            )}
            key={item.id}
          >
            <div className={styles.header}>
              <span>{item.category}</span>
              <span>{isoToDate(item.publishDate)}</span>
            </div>
            <h2>{item.title}</h2>
            <div className={styles.cardContent}>
              <p>{item.text}</p>

              {!item.deletedAt && (
                <div className={styles.actionBtns}>
                  <div
                    className={styles.editIcon}
                    onClick={() => handleEditNewsletter(item)}
                  >
                    <EditIcon fill="#fff" />
                  </div>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => handleDeleteNewsletter(item)}
                  >
                    <DeleteIcon fill="#fff" />
                  </div>
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={createNewsletterModal}
        title={id !== "" ? "Edit newsletter" : "Create a newsletter"}
        showCancelButton
        showOkButton
        buttonOkText={id !== "" ? "Edit" : "Create"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={id !== "" ? pendingEditAction : pendingCreateAction}
      >
        <div className={styles.inputFields}>
          <div>
            <div className={styles.fileLoaderContainer}>
              <FileUpload
                textUploadFile={"Upload image *"}
                setFile={setImage}
                setFileName={(e) => setImageName(e)}
                classNameContainer={styles.logoSpace}
              ></FileUpload>
              <span className={styles.imageName}>{imageName}</span>
            </div>
            <Input
              placeholder={"Title"}
              name={"title"}
              classes={styles.inputContainer100}
              onChange={(event) => setTitle(event)}
              value={title}
            />
            <Input
              placeholder={"Category"}
              name={"category"}
              type={"text"}
              classes={styles.inputContainer100}
              onChange={(event) => setCategory(event)}
              value={category}
            />
            <Input
              placeholder={"Publish date"}
              name={"publish-date"}
              type={"date"}
              classes={styles.inputContainer100}
              onChange={(event) => setPublishDate(event)}
              value={publishDate}
            />
            <CheckBox
              checked={showMore}
              text={"Enable show more"}
              className={styles.checkbox}
              disabled={false}
              onClick={() => setShowMore((prevCheck) => !prevCheck)}
            />
            <textarea
              placeholder={"Text"}
              name={"text"}
              className={styles.inputContainer100}
              onChange={(event) => setText(event.target.value)}
              value={text}
            />
          </div>
          <div style={{ minHeight: "100%" }}>
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName={styles.wrapperClassName}
              editorClassName={styles.editorClassName}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                ],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                blockType: {
                  options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                },
              }}
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ref={deleteNewsletterModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingDeleteAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleDeleteCloseModal}
        buttonOkOnClick={handleDeleteOkModal}
      >
        {"Delete newsletter : " + title}
      </Modal>
    </div>
  );
};

export default NewsletterScreen;
