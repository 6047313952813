import { createSlice } from "@reduxjs/toolkit";
import { fetchReferralsBuilder, deleteReferralBuilder } from "./builder";

const initialState = {
  referrals: {
    data: [],
    perPage: null,
    total: 0,
  },
  fetchReferrals: false,
  deleteReferral: false,
};

export const referrals = createSlice({
  name: "referrals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchReferralsBuilder(builder);
    deleteReferralBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectReferral = (state) => state.referrals.referrals;
export const selectFetchReferralPending = (state) =>
  state.referrals.fetchReferrals;
export const selectDeleteReferralPending = (state) =>
  state.referrals.deleteReferral;

export default referrals.reducer;
