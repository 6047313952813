import axios from "axios";
import localStorageService from "../services/localStorageService";
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "../utils/constants.js";

let store;

export const injectStore = (_store) => {
  store = _store;
};

// const BASE_URL = "http://localhost:5000";
const BASE_URL = "https://api.wellsvfs.com";

const API_BASE_URL = `${BASE_URL}/api/`;
const AUTH_BASE_URL = `${BASE_URL}/auth/`;

const axiosAuthClient = axios.create({
  baseURL: AUTH_BASE_URL,
});

const axiosApiFreeClient = axios.create({
  baseURL: API_BASE_URL,
});

const axiosApiClient = axios.create({
  baseURL: API_BASE_URL,
});

axiosApiClient.interceptors.request.use(
  async (config) => {
    const token = localStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axiosApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      const currentRefreshToken = localStorageService.getItem(
        LOCAL_STORAGE_REFRESH_TOKEN
      );
      if (
        currentRefreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const refreshResponse = await axiosAuthClient.post("/refresh-token", {
          refreshToken: currentRefreshToken,
        });
        if (refreshResponse.status !== 200) {
          return Promise.reject(error);
        }

        if (store.getState().userInfo.id !== refreshResponse.data.data.userId) {
          store.dispatch({ type: "logout" });
        }

        const { accessToken, refreshToken } = refreshResponse.data.data;
        localStorageService.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
        localStorageService.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } else if (error.response.status === 401) {
        // No refresh token in storage
        localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        store.dispatch({ type: "logout" });
      }
      return Promise.reject(error);
    } catch (err) {
      if (err.response.status === 400) {
        localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        store.dispatch({ type: "logout" });
      }
      return Promise.reject(err);
    }
  }
);

export default axiosApiClient;
export { axiosAuthClient, axiosApiFreeClient };
