import { createAsyncThunk } from "@reduxjs/toolkit";
import NewsService from "../../../api/services/news";

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async (requestData) => {
    const { search } = requestData;
    return await NewsService.fetchAllNews(search);
  }
);

export const deleteNews = createAsyncThunk(
  "news/deleteNews",
  async (requestData) => {
    const { newsId } = requestData;
    return await NewsService.deleteNews(newsId);
  }
);

export const createNews = createAsyncThunk(
  "news/createNews",
  async (requestData) => {
    return await NewsService.createNews(requestData);
  }
);

export const editNews = createAsyncThunk(
  "news/editNews",
  async (requestData) => {
    return await NewsService.editNews(requestData);
  }
);
