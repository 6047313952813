import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/researchAndInsight";

class ResearchAndInsightService {
  fetchResearchAndInsight = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { researchAndInsight } = response.data.data;
      return { researchAndInsight };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching researchAndInsight."
      );
    }
  };

  fetchResearchAndInsightDocument = async (id) => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const data = response.data.data;
      return data;
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching researchAndInsight document."
      );
    }
  };

  fetchAllResearchAndInsight = async (search) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/all?search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }

      const { researchAndInsight } = response.data.data;
      return { researchAndInsight };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while fetching all researchAndInsight."
      );
    }
  };

  deleteResearchAndInsight = async (researchAndInsightId) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/${researchAndInsightId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while deleting researchAndInsight."
      );
    }
  };

  createResearchAndInsight = async (formData) => {
    try {
      console.log(formData);
      const response = await axiosApiClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(
        err,
        "Error occured while creating researchAndInsight."
      );
    }
  };
}

export default new ResearchAndInsightService();
