import { fetchReferrals, deleteReferral } from "./asyncThunks";

export const fetchReferralsBuilder = (builder) => {
  builder.addCase(fetchReferrals.pending, (state) => {
    state.fetchReferrals = true;
  });
  builder.addCase(fetchReferrals.fulfilled, (state, action) => {
    state.fetchReferrals = false;
    state.referrals.data = action.payload.data;
    state.referrals.total = action.payload.total;
    state.referrals.perPage = action.payload.perPage;
  });
  builder.addCase(fetchReferrals.rejected, (state) => {
    state.fetchReferrals = false;
  });
};

export const deleteReferralBuilder = (builder) => {
  builder.addCase(deleteReferral.pending, (state) => {
    state.deleteReferral = true;
  });
  builder.addCase(deleteReferral.fulfilled, (state, action) => {
    state.deleteReferral = false;
    state.referrals.data = state.referrals.data.filter(
      (s) => s.id !== action.payload.data.result.id
    );
  });
  builder.addCase(deleteReferral.rejected, (state) => {
    state.deleteReferral = false;
  });
};
