import { React, useState, useEffect } from "react";
import styles from "./News.module.scss";

import { isoToDate } from "../../utils/util";

import NewsService from "../../api/services/news";

import Card from "../Card/Card.js";

const News = () => {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const resultData = async () => {
      if (loading) {
        NewsService.fetchNews()
          .then((res) => {
            setLoading(false);
            setNews(res.news);
          })
          .finally();
      }
    };
    resultData();
  });

  return (
    <section className={styles.newsContainer}>
      <div className={styles.news}>
        <h1>News</h1>
        <div className={styles.newsCardContainer}>
          {news?.map((item, index) => (
            <Card classes={styles.categories} key={index}>
              <div>
                <span>{item.category}</span>
                <span>{isoToDate(item.publishDate)}</span>
              </div>
              <h2>{item.title}</h2>
              <p>{item.text}</p>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
