import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsersBuilder,
  fetchAllUsersBuilder,
  deleteUsersBuilder,
  createUserBuilder,
  updateUserBuilder,
} from "./builder";

export const initialState = {
  clients: {
    data: [],
    allClients: [],
    perPage: null,
    total: 0,
  },
  fetchUsersPending: false,
  deleteUserPending: false,
  updateUserPending: false,
  createUserPending: false,
};

export const userSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchUsersBuilder(builder);
    fetchAllUsersBuilder(builder);
    deleteUsersBuilder(builder);
    createUserBuilder(builder);
    updateUserBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

// export const {} = userSlice.actions;

export const selectClients = (state) => state.clients.clients;
export const selectFetchUsersPending = (state) =>
  state.clients.fetchUsersPending;
export const selectDeleteUserPending = (state) =>
  state.clients.deleteUserPending;
export const selectUpdateUserPending = (state) =>
  state.clients.updateUserPending;
export const selectCreateUserPending = (state) =>
  state.clients.createUserPending;

export default userSlice.reducer;
