import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Table,
  TableItem,
  Modal,
  CheckBox,
} from "../../../components";
import styles from "./ReferralAdminScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";

import {
  selectReferral,
  selectDeleteReferralPending,
} from "../../../store/slices/referral/slice";
import {
  fetchReferrals,
  deleteReferral,
} from "../../../store/slices/referral/asyncThunks";
import { notifyInfo } from "../../../utils/notify";

const ReferralAdminScreen = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  let navigate = useNavigate();

  const { data, perPage, total } = useSelector(selectReferral);
  const pendingAction = useSelector(selectDeleteReferralPending);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });
  const [selectedReferral, setSelectedReferral] = useState(null);

  const deleteReferralModal = useRef();

  const tableHeader = [
    {
      key: "firstName",
      label: "First name",
      sortable: true,
    },
    {
      key: "lastName",
      label: "Last name",
      sortable: true,
    },
    {
      key: "phoneNumber",
      label: "Phone number",
      sortable: true,
    },
    {
      key: "permissionToCall",
      label: "Permission to call",
      sortable: true,
    },
    {
      key: "clientName",
      label: "Client name",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const handleCloseCreateModal = () => {
    deleteReferralModal.current.hide();
  };

  const handleOkModal = () => {
    dispatch(deleteReferral({ referralId: selectedReferral.id })).then(
      (promise) => {
        deleteReferralModal.current.hide();
        notifyInfo("Referral deleted.");
      }
    );
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/referrals",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchReferrals({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) {
      dispatch(
        fetchReferrals({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
        })
      );
    }
    //eslint-disable-next-line
  }, [page, sortOptions, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          onChange={debouncedChangeHandler}
          placeholder="Search email"
        />
      </div>
      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((referral, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={referral.firstName} />
                <TableItem data={referral.lastName} />
                <TableItem data={referral.phoneNumber} />
                <TableItem
                  data={
                    <CheckBox
                      checked={referral.permissionToCall}
                      text={""}
                      className={styles.checkbox}
                      disabled={true}
                    />
                  }
                />
                <TableItem
                  data={referral.user.firstName + " " + referral.user.lastName}
                />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedReferral(referral);
                          deleteReferralModal.current.show();
                        }}
                      />
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <Modal
        ref={deleteReferralModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
      >
        {"Delete referral : " +
          selectedReferral?.firstName +
          " " +
          selectedReferral?.lastName}
      </Modal>
    </div>
  );
};

export default ReferralAdminScreen;
