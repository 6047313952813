import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import classNames from "classnames";
import { notifyError, notifyInfo } from "../../../utils/notify";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import styles from "./ResearchAndInsightsScreenAdmin.module.scss";

import {
  SearchInput,
  Modal,
  Input,
  FileUpload,
  Card,
} from "../../../components";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";

import {
  selectResearchAndInsight,
  selectCreateResearchAndInsightPending,
} from "../../../store/slices/researchAndInsights/slice";
import {
  fetchAllResearchAndInsight,
  createResearchAndInsight,
  deleteResearchAndInsight,
} from "../../../store/slices/researchAndInsights/asyncThunks";

const ResearchAndInsightsScreenAdmin = () => {
  const createModal = useRef();
  const deleteModal = useRef();

  const [title, setTitle] = useState("");
  const [id, setId] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imageName, setImageName] = useState("");

  const [, setSearch] = useState("");

  const dispatch = useDispatch();
  const data = useSelector(selectResearchAndInsight);
  const pendingCreateAction = useSelector(
    selectCreateResearchAndInsightPending
  );

  useEffect(() => {
    dispatch(fetchAllResearchAndInsight({ search: "" }));
  }, [dispatch]);

  const handleCreateDocument = () => {
    createModal.current.show();
  };

  const handleDelete = (item) => {
    setTitle(item.title);
    setId(item.id);
    deleteModal.current.show();
  };

  const handleDeleteCloseModal = () => {
    deleteModal.current.hide();
    resetForm();
  };

  const handleDeleteOkModal = () => {
    dispatch(deleteResearchAndInsight({ id })).then((promise) => {
      if (promise.type.includes("fulfilled")) {
        notifyInfo("Successully deleted");
        deleteModal.current.hide();
        resetForm();
      } else {
        notifyError(promise.error.message);
      }
    });
  };

  const handleCloseCreateModal = () => {
    createModal.current.hide();
    resetForm();
  };

  const handleOkCreateModal = () => {
    if (title === "" || file === null || image === null) {
      notifyError("Fill required fields");
    } else {
      dispatch(createResearchAndInsight({ image, file, title })).then(
        (promise) => {
          if (promise.type.includes("fulfilled")) {
            notifyInfo("Successully created");
            createModal.current.hide();
            resetForm();
          } else {
            notifyError(promise.error.message);
          }
        }
      );
    }
  };

  const resetForm = () => {
    setId(null);
    setTitle("");
    setFileName("");
    setImageName("");
    setFile(null);
    setImage(null);
  };
  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(fetchAllResearchAndInsight({ search: text }));
    },
    [dispatch]
  );
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput onChange={debouncedChangeHandler} placeholder="Search" />
        <button className={styles.createDocBtn} onClick={handleCreateDocument}>
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.documentsCardContainer}>
        {data?.map((item) => (
          <Card
            key={item.id}
            classes={classNames(
              styles.card,
              item.deletedAt ? styles.deletedCard : ""
            )}
          >
            <img src={item.photoUrl} alt={item.title} />
            <h2>{item.title}</h2>
            <div className={styles.actionBtns}>
              <a href={item.documentUrl} target="_blank" rel="noreferrer">
                PDF
              </a>

              {!item.deletedAt && (
                <div
                  className={styles.deleteIcon}
                  onClick={() => handleDelete(item)}
                >
                  <DeleteIcon fill="#fff" />
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={createModal}
        title={"Create research and insight"}
        showCancelButton
        showOkButton
        buttonOkText={"Create"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkCreateModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingCreateAction}
      >
        <div className={styles.inputFields}>
          <FileUpload
            textUploadFile={"Upload document *"}
            setFile={setFile}
            setFileName={(e) => setFileName(e)}
            classNameContainer={styles.logoSpace}
          ></FileUpload>
          <span>{fileName}</span>
          <FileUpload
            textUploadFile={"Upload image *"}
            setFile={setImage}
            setFileName={(e) => setImageName(e)}
            classNameContainer={styles.logoSpace}
          ></FileUpload>
          <span>{imageName}</span>
          <Input
            placeholder={"Research And Insights title"}
            name={"title"}
            classes={classNames(styles.inputContainer100, styles.inputField)}
            onChange={(event) => setTitle(event)}
            value={title}
            required
          />
        </div>
      </Modal>
      <Modal
        ref={deleteModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleDeleteCloseModal}
        buttonOkOnClick={handleDeleteOkModal}
      >
        <span>Delete research and insight with title:</span>
        <br />
        <br />
        <b>{title}</b>
      </Modal>
    </div>
  );
};

export default ResearchAndInsightsScreenAdmin;
