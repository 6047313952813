import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDocumentToSignBuilder,
  fetchAllDocumentToSignBuilder,
  deleteDocumentToSignBuilder,
  createDocumentToSignBuilder,
  editDocumentToSignBuilder,
  fetchAllUserDocumentsBuilder,
} from "./builder";

const initialState = {
  documentToSign: {
    data: [],
    userDocuments: {
      toSign: [],
      signed: [],
    },
    perPage: null,
    total: 0,
  },
  allUserDocuments: {
    data: [],
    perPage: null,
    total: 0,
    client: {},
  },
  createDocumentToSign: false,
  deleteDocumentToSign: false,
  fetchDocumentToSign: false,
  editDocumentToSign: false,
};

export const documentToSign = createSlice({
  name: "documentToSign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchDocumentToSignBuilder(builder);
    deleteDocumentToSignBuilder(builder);
    createDocumentToSignBuilder(builder);
    editDocumentToSignBuilder(builder);
    fetchAllDocumentToSignBuilder(builder);
    fetchAllUserDocumentsBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectDocumentToSign = (state) =>
  state.documentToSign.documentToSign;
export const selectCreateDocumentToSignPending = (state) =>
  state.documentToSign.createDocumentToSign;
export const selectDeleteDocumentToSignPending = (state) =>
  state.documentToSign.deleteDocumentToSign;
export const selectEditDocumentToSignPending = (state) =>
  state.documentToSign.editDocumentToSign;
export const selectAllUserDocuments = (state) =>
  state.documentToSign.allUserDocuments;

export default documentToSign.reducer;
