import React, { useEffect } from "react";
import styles from "./NewsletterScreen.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsletters } from "../../../store/slices/newsletter/asyncThunks";
import { isoToDate } from "../../../utils/util";

import { Card } from "../../../components";
import { selectNewsletter } from "../../../store/slices/newsletter/slice";

const NewsletterScreen = () => {
  const dispatch = useDispatch();
  const newsletters = useSelector(selectNewsletter);

  useEffect(() => {
    dispatch(fetchNewsletters());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <h1>Newsletter</h1>
      <div className={styles.cardsContainer}>
        {newsletters.map((item) => (
          <Card classes={styles.categories} key={item.id}>
            <div>
              <span>{item.category}</span>
              <span>{isoToDate(item.publishDate)}</span>
            </div>
            <h2>{item.title}</h2>
            <p>{item.text}</p>
            <span className={styles.emptyField}></span>
            {item.showMore && (
              <Link className={styles.seeMore} to={`${item.id}`}>
                See more
              </Link>
            )}
          </Card>
        ))}
      </div>
    </div>
  );
};

export default NewsletterScreen;
