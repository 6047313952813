import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEventsBuilder,
  fetchAllEventsBuilder,
  deleteEventsBuilder,
  createEventsBuilder,
  editEventsBuilder,
} from "./builder";

const initialState = {
  events: {
    data: [],
  },
  createEvents: false,
  deleteEvents: false,
  editEvents: false,
  fetchEvents: false,
};

export const events = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchEventsBuilder(builder);
    fetchAllEventsBuilder(builder);
    deleteEventsBuilder(builder);
    createEventsBuilder(builder);
    editEventsBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectEvents = (state) => state.events.events.data;
export const selectCreateEventsPending = (state) => state.events.createEvents;
export const selectDeleteEventsPending = (state) => state.events.deleteEvents;
export const selectEditEventsPending = (state) => state.events.editEvents;

export default events.reducer;
