import axiosApiFreeClient from "..";
import axiosApiClient from "..";
import { handleError } from "../../utils/errors";

const BASE_URL = "/advisorTeam";

class AdvisorTeamService {
  fetchAdvisorTeam = async () => {
    try {
      const response = await axiosApiFreeClient.get(`${BASE_URL}`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while fetching Advisor Team.");
    }
  };

  updateAdvisorTeam = async (formData) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/${formData.get("id")}`,
        formData
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while editing Advisor Team.");
    }
  };
}

export default new AdvisorTeamService();
