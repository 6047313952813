import { fetchAdvisorTeam, updateAdvisorTeam } from "./asyncThunks";

export const fetchAdvisorTeamBuilder = (builder) => {
  builder.addCase(fetchAdvisorTeam.pending, (state) => {
    state.fetchAdvisorTeamPending = true;
  });
  builder.addCase(fetchAdvisorTeam.fulfilled, (state, action) => {
    state.fetchAdvisorTeamPending = false;
    state.advisorTeam.data = action.payload.data.advisorTeam;
  });
  builder.addCase(fetchAdvisorTeam.rejected, (state) => {
    state.fetchAdvisorTeamPending = false;
  });
};

export const updateAdvisorTeamBuilder = (builder) => {
  builder.addCase(updateAdvisorTeam.pending, (state) => {
    state.updateAdvisorTeamPending = true;
  });
  builder.addCase(updateAdvisorTeam.fulfilled, (state, action) => {
    state.updateAdvisorTeamPending = false;

    state.advisorTeam.data = state.advisorTeam.data.map((s) => {
      if (s.id === action.payload.data.advisorTeam.id) {
        return action.payload.data.advisorTeam;
      } else {
        return s;
      }
    });
  });
  builder.addCase(updateAdvisorTeam.rejected, (state) => {
    state.updateAdvisorTeamPending = false;
  });
};
