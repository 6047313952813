import { createAsyncThunk } from "@reduxjs/toolkit";
import ResearchAndInsightService from "../../../api/services/researchAndInsight";

export const fetchResearchAndInsight = createAsyncThunk(
  "researchAndInsight/fetchResearchAndInsight",
  async () => {
    return await ResearchAndInsightService.fetchResearchAndInsight();
  }
);

export const fetchAllResearchAndInsight = createAsyncThunk(
  "researchAndInsight/fetchAllResearchAndInsight",
  async (requestData) => {
    const { search } = requestData;
    return await ResearchAndInsightService.fetchAllResearchAndInsight(search);
  }
);

export const deleteResearchAndInsight = createAsyncThunk(
  "researchAndInsight/deleteResearchAndInsight",
  async (requestData) => {
    const { id } = requestData;
    return await ResearchAndInsightService.deleteResearchAndInsight(id);
  }
);

export const createResearchAndInsight = createAsyncThunk(
  "researchAndInsight/createResearchAndInsight",
  async (requestData) => {
    const { image, file, title } = requestData;
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    if (image) {
      formData.append("photo", image);
    }

    formData.append("title", title);
    for (const value of formData.values()) {
      console.log(value);
    }
    return await ResearchAndInsightService.createResearchAndInsight(formData);
  }
);
