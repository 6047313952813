import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserInfoBuilder,
  loginBuilder,
  editUserInfoBuilder,
  logoutBuilder,
} from "./builder";

export const initialState = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  profilePhoto: null,
  editUserInfoPending: false,
  pin: null,
};

export const userSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.id = payload.user.id;
      state.email = payload.user.email;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.role = payload.user.role;
      state.profilePhoto = payload.user.profilePhoto;
      state.pin = payload.user.pin;
    },
    editUserInfo: (state, { payload }) => {
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.profilePhoto = payload.user.profilePhoto;
    },
  },
  extraReducers: (builder) => {
    loginBuilder(builder);
    fetchUserInfoBuilder(builder);
    logoutBuilder(builder);
    editUserInfoBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

// export const {} = userSlice.actions;

export const selectLoggedInUserRole = (state) => state.userInfo.role;
export const selectLoggedInUserDetails = (state) => state.userInfo;
export const selectEditUserInfoPending = (state) =>
  state.userInfo.editUserInfoPending;

export default userSlice.reducer;
