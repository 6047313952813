import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth/slice";
import userSlice from "./slices/user/slice";
import contactFormsSlice from "./slices/contactForm/slice";
import newsSlice from "./slices/news/slice";
import clientsSlice from "./slices/clients/slice";
import advisorTeamSlice from "./slices/advisorTeam/slice";
import lifePrioritiesSlice from "./slices/lifePriorities/slice";
import referralsSlice from "./slices/referral/slice";
import newsletterSlice from "./slices/newsletter/slice";
import eventsSlice from "./slices/events/slice";
import documentToSignSlice from "./slices/documentToSign/slice";
import quizSlice from "./slices/quiz/slice";
import researchAndInsightsSlice from "./slices/researchAndInsights/slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    contactForms: contactFormsSlice,
    news: newsSlice,
    clients: clientsSlice,
    advisorTeam: advisorTeamSlice,
    lifePriorities: lifePrioritiesSlice,
    referrals: referralsSlice,
    newsletter: newsletterSlice,
    events: eventsSlice,
    documentToSign: documentToSignSlice,
    quiz: quizSlice,
    researchAndInsight: researchAndInsightsSlice,
  },
});
