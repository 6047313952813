import React from "react";

const Form = ({ children, classes, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className={classes}>
      {children}
    </form>
  );
};

export default Form;
