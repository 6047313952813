import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Table,
  TableItem,
  Modal,
  Input,
  FileUpload,
  SearchInput,
  Button,
  CheckBox,
} from "../../../components";
import styles from "./ClientDetailsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { ORDER } from "../../../components/Table/Table";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron_right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/Download.svg";

import {
  selectCreateDocumentToSignPending,
  selectDeleteDocumentToSignPending,
  selectEditDocumentToSignPending,
  selectAllUserDocuments,
} from "../../../store/slices/documentToSign/slice";
import {
  deleteDocumentToSign,
  editDocumentToSign,
  fetchAllUserDocuments,
} from "../../../store/slices/documentToSign/asyncThunks";
import DocumentToSignService from "../../../api/services/documentToSign";

import { updateUser } from "../../../store/slices/clients/asyncThunks";

import { notifyInfo, notifyError } from "../../../utils/notify";
import { DOCUMENT_STATES } from "../../../utils/constants";

import { stringToHslColor } from "../../../utils/util";

import { Link } from "react-router-dom";

import arrowLeft from "../../../assets/images/left-arrow-svgrepo-com.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";
import UserService from "../../../api/services/user";

const ClientDetailsScreen = () => {
  const dispatch = useDispatch();
  let page = +useLocation().search.split("=")[1];
  const params = useParams();
  let navigate = useNavigate();

  const { data, perPage, total, client } = useSelector(selectAllUserDocuments);

  const pendingAction = useSelector(selectDeleteDocumentToSignPending);

  const pendingCreateAction = useSelector(selectCreateDocumentToSignPending);
  const pendingEditAction = useSelector(selectEditDocumentToSignPending);
  const createDocModal = useRef();

  const clientModal = useRef();

  const requestResetPasswordModal = useRef();

  const [id, setId] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [file, setFile] = useState(null);
  const [cliendId, setClientId] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [showToClient, setShowToClient] = useState(true);

  const [pendigResetPassword, setPendigResetPassword] = useState(false);

  const [newPasswordGenerated, setNewPasswordGenerated] = useState("");

  useEffect(() => {
    setFirstName(client.firstName);
    setLastName(client.lastName);
    setPassword("");
    setPin(client.pin);
    setEmail(client.email);
  }, [client]);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    order: ORDER.ASC,
  });

  const [selectedDocumentToSign, setSelectedDocumentToSign] = useState(null);
  const deleteDocumentToSignModal = useRef();

  const tableHeader = [
    {
      key: "documentName",
      label: "Document name",
      sortable: true,
    },
    {
      key: "documentState",
      label: "Document state",
      sortable: true,
    },
    {
      key: "signer",
      label: "Signer",
      sortable: true,
    },
    {
      key: "",
      label: "",
    },
  ];

  const resetForm = () => {
    setId("");
    setPdfName("");
    setFile(null);
    setFirstName("");
    setLastName("");
    setPassword("");
    setEmail("");
  };

  const downloadDocument = async (document) => {
    await DocumentToSignService.downloadDocument(document.id)
      .then((res) => {
        window.open(res, "_blank");
      })
      .finally();
  };

  const handleOkDeleteModal = () => {
    dispatch(
      deleteDocumentToSign({ documentToSignId: selectedDocumentToSign.id })
    ).then((promise) => {
      deleteDocumentToSignModal.current.hide();
      notifyInfo("Document deleted.");
    });
  };

  const handleOkCreateModal = () => {
    dispatch(
      editDocumentToSign({
        id,
        documentName: pdfName,
        file,
        userId: client?.id,
        showToClient,
      })
    ).then((promise) => {
      if (promise.type.includes("fulfilled")) {
        createDocModal.current.hide();
        notifyInfo("Document to sign successfully created");
      } else {
        notifyError(promise.error.message);
      }
    });
  };

  const handleEditModal = (document) => {
    setId(document.id);
    setPdfName(document.documentName);
    setFile(null);
    setShowToClient(document.showToClient);

    createDocModal.current.show();
  };

  const handleOkModal = () => {
    dispatch(
      updateUser({
        id: cliendId,
        firstName,
        lastName,
        password: password.length > 0 ? password : null,
        pin,
      })
    ).then((promise) => {
      if (promise.type.includes("fulfilled")) {
        clientModal.current.hide();
        notifyInfo("Client updated.");
        dispatch(
          fetchAllUserDocuments({
            page: Number(page),
            sortBy: sortOptions.sortBy,
            order: sortOptions.order,
            search: search,
            id: Number(params.id),
          })
        );
      } else {
        notifyError(promise.error.message);
      }
    });
  };

  const setPage = async (page) => {
    setCurrentPage(page);
    navigate({
      pathname: "/admin/contact-form",
      search: `?page=${page}`,
    });
  };

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(
        fetchAllUserDocuments({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: text,
          id: params.id,
        })
      );
    },
    [page, sortOptions, dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(page);
    setClientId(params.id);

    if (page > 0) {
      dispatch(
        fetchAllUserDocuments({
          page: Number(page),
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          search: search,
          id: Number(params.id),
        })
      );
    } else {
      navigate(`/admin/clients/${params.id}?page=1`);
    }

    //eslint-disable-next-line
  }, [page, sortOptions, cliendId, dispatch]);

  const resetPassword = async () => {
    setPendigResetPassword(true);
    await UserService.resetPassword({
      clientId: client.id,
    })
      .then((res) => {
        setNewPasswordGenerated(res.password);
      })
      .catch((res) => {
        notifyError("Error while changing passwords");
      })
      .finally();

    setPendigResetPassword(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.back}>
          <Link
            to={"/admin/clients?page=1"}
            onClick={() => {
              resetForm();
            }}
          >
            <img src={arrowLeft} alt="back" />
            Return to Clients
          </Link>
        </div>
        <div className={styles.clientDetailsContainer}>
          <div
            className={styles.userPhoto}
            style={{
              backgroundColor: stringToHslColor(
                client.firstName + client.lastName,
                40,
                40
              ),
            }}
          >
            {client.profilePhoto && client.profilePhoto !== null ? (
              <img src={client.profilePhoto} alt={"profile"} />
            ) : (
              <span className={styles.initials}>
                {client.firstName && client.firstName.substring(0, 1)}
                {client.lastName && client.lastName.substring(0, 1)}
              </span>
            )}
          </div>
          <div>
            <p>
              <b>Name: </b>
              <span>{client.firstName}</span>
            </p>
            <p>
              <b>Surname: </b>
              <span>{client.lastName}</span>
            </p>
            <p>
              <b>Email: </b>
              <span>{client.email}</span>
            </p>
            <p>
              <b>PIN: </b>
              <span>{client.pin}</span>
            </p>
          </div>
          <div
            className={styles.editIcon}
            onClick={() => clientModal.current.show()}
          >
            <EditIcon />
          </div>
          <Button
            onClick={() => requestResetPasswordModal.current.show()}
            classes={styles.btn}
            title="Reset password"
          ></Button>
        </div>
        <SearchInput
          onChange={debouncedChangeHandler}
          placeholder="Search documents"
        />
      </div>

      <div className={styles.content}>
        <Table
          currentPage={currentPage}
          totalCount={total}
          pageSize={perPage}
          setPage={setPage}
          noDataContent="No Data"
          setSortOptions={setSortOptions}
          sortOptions={sortOptions}
          headerInformation={tableHeader}
        >
          {data.map((documentToSign, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={documentToSign.documentName} />
                <TableItem data={documentToSign.documentState} />
                <TableItem
                  data={
                    documentToSign.user.firstName +
                    " " +
                    documentToSign.user.lastName
                  }
                />
                <TableItem
                  data={
                    <div className={styles.iconContainer}>
                      <DownloadIcon
                        style={{ filter: "brightness(0)" }}
                        className={styles.deleteIcon}
                        onClick={() => {
                          downloadDocument(documentToSign);
                        }}
                      />
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => {
                          setSelectedDocumentToSign(documentToSign);
                          deleteDocumentToSignModal.current.show();
                        }}
                      />
                      {documentToSign.documentState ===
                        DOCUMENT_STATES.UPLOADED && (
                        <ChevronIcon
                          className={styles.icon}
                          onClick={() => {
                            setSelectedDocumentToSign(documentToSign);
                            handleEditModal(documentToSign);
                          }}
                        />
                      )}
                    </div>
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <Modal
        ref={createDocModal}
        title={"Edit document to sign"}
        showCancelButton
        showOkButton
        buttonOkText={"Edit"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => createDocModal.current.hide()}
        buttonOkOnClick={handleOkCreateModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingEditAction}
      >
        <div className={styles.inputFields}>
          <FileUpload
            textUploadFile={"Upload document"}
            setFile={setFile}
            setFileName={setPdfName}
            classNameContainer={styles.logoSpace}
          ></FileUpload>
          <Input
            placeholder={"Title"}
            name={"title"}
            classes={styles.inputContainer100}
            onChange={(event) => setPdfName(event)}
            value={pdfName}
          />
          <CheckBox
            className={styles.showToClientWrapper}
            checked={showToClient}
            text={"Show to client"}
            onClick={() => setShowToClient((prev) => !prev)}
          />
        </div>
      </Modal>
      <Modal
        ref={deleteDocumentToSignModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => deleteDocumentToSignModal.current.hide()}
        buttonOkOnClick={handleOkDeleteModal}
      >
        {"Delete document for signing : " +
          selectedDocumentToSign?.documentName}
      </Modal>
      <Modal
        ref={clientModal}
        title={"Edit client"}
        showCancelButton
        showOkButton
        buttonOkText={"Edit"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => clientModal.current.hide()}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingCreateAction}
      >
        <Input
          placeholder={"Email"}
          name={"Email"}
          classes={styles.inputContainer100}
          onChange={(event) => setEmail(event)}
          value={email}
        />
        <Input
          placeholder={"First Name"}
          name={"First Name"}
          classes={styles.inputContainer100}
          onChange={(event) => setFirstName(event)}
          value={firstName}
        />
        <Input
          placeholder={"Last Name"}
          name={"Last Name"}
          classes={styles.inputContainer100}
          onChange={(event) => setLastName(event)}
          value={lastName}
        />
        <Input
          placeholder={"PIN"}
          name={"pin"}
          classes={styles.inputContainer100}
          onChange={(event) => setPin(event)}
          value={pin}
        />
      </Modal>

      <Modal
        ref={requestResetPasswordModal}
        title={"Reset password"}
        buttonOkText={"Yes"}
        pendingAction={pendigResetPassword}
        buttonCloseText={newPasswordGenerated ? "Close" : "No"}
        modalStyle={styles.modalStyle}
        showOkButton={newPasswordGenerated === ""}
        buttonCloseOnClick={() => {
          requestResetPasswordModal.current.hide();
          setNewPasswordGenerated("");
        }}
        buttonOkOnClick={resetPassword}
      >
        {newPasswordGenerated === "" && (
          <span>Please, press Yes if you want to reset your password</span>
        )}
        {newPasswordGenerated !== "" && (
          <span>
            New password is: <h2>{newPasswordGenerated}</h2>{" "}
          </span>
        )}
      </Modal>
    </div>
  );
};

export default ClientDetailsScreen;
