import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNewsletterBuilder,
  fetchAllNewsletterBuilder,
  deleteNewsletterBuilder,
  createNewsletterBuilder,
  editNewsletterBuilder,
} from "./builder";

const initialState = {
  newsletter: {
    data: [],
  },
  createNewsletter: false,
  deleteNewsletter: false,
  fetchNewsletter: false,
  editNewsletter: false,
};

export const newsletter = createSlice({
  name: "newsletter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchNewsletterBuilder(builder);
    deleteNewsletterBuilder(builder);
    createNewsletterBuilder(builder);
    editNewsletterBuilder(builder);
    fetchAllNewsletterBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

export const selectNewsletter = (state) => state.newsletter.newsletter.data;
export const selectCreateNewsletterPending = (state) =>
  state.newsletter.createNewsletter;
export const selectDeleteNewsletterPending = (state) =>
  state.newsletter.deleteNewsletter;
export const selectEditNewsletterPending = (state) =>
  state.newsletter.editNewsletter;

export default newsletter.reducer;
