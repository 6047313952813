import React, { useEffect, useRef, useState } from "react";
import { Card, Modal, Input } from "../../../components";
import styles from "./YourAdvisorTeamAdmin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyInfo } from "../../../utils/notify";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-sharp-24px copy.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

import {
  selectAdvisorTeam,
  selectUpdateAdvisorTeamPending,
} from "../../../store/slices/advisorTeam/slice";
import {
  fetchAdvisorTeam,
  updateAdvisorTeam,
} from "../../../store/slices/advisorTeam/asyncThunks";

const YourAdvisorTeamAdmin = () => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [university, setUniversity] = useState("");
  const [position, setPosition] = useState("");
  const [image, setImage] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const dispatch = useDispatch();
  const advisorTeam = useSelector(selectAdvisorTeam);
  const pendingAction = useSelector(selectUpdateAdvisorTeamPending);
  const editAdvisorMember = useRef();

  useEffect(() => {
    dispatch(fetchAdvisorTeam());
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    resetInputFields();
    editAdvisorMember.current.hide();
  };

  const resetInputFields = () => {
    setName("");
    setUniversity("");
    setPosition("");
    setImage("");
    setImageSrc("");
    setId("");
  };

  const handleOkModal = () => {
    dispatch(
      updateAdvisorTeam({
        id,
        name,
        position,
        school: university,
        text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        file: image,
      })
    ).then((promise) => {
      if (promise.type.includes("fulfilled")) {
        resetInputFields();
        editAdvisorMember.current.hide();
        notifyInfo("Successfully updated");
      } else {
        notifyError(promise.error.message);
      }
    });
  };

  const handleEditMember = (item, index) => {
    setName(item.name);
    setPosition(item.position);
    setUniversity(item.school);
    setImage(item.profilePhoto);
    setId(item.id);

    const contentBlock = htmlToDraft(item.text);
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.text))
      );

      setEditorState(editorState);
    }

    editAdvisorMember.current.show();
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const remvovePhoto = () => {
    setImage("");
    setImageSrc("");
  };

  const previewImage = (event) => {
    const imageFiles = event.target.files;
    const imageFilesLength = imageFiles.length;

    if (imageFilesLength > 0) {
      const imageSrc = URL.createObjectURL(imageFiles[0]);

      const imagePreviewElement = document.querySelector(
        "#preview-selected-image"
      );

      imagePreviewElement.src = imageSrc;
      setImageSrc(imageSrc);
      setImage(event.target.files[0]);

      imagePreviewElement.style.display = "block";
    }

    event.target.value = "";
  };

  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        {advisorTeam?.map((item, index) => (
          <Card classes={styles.item} key={item.id}>
            <div className={styles.imageContainer}>
              {item.profilePhoto && (
                <img src={item.profilePhoto} alt={item?.name} />
              )}
            </div>
            <h2>{item.name}</h2>
            <span className={styles.position}>{item.position}</span>
            <span className={styles.university}>{item.school}</span>
            <div className={styles.editIconContainer}>
              <div
                className={styles.editIcon}
                onClick={() => handleEditMember(item, index)}
              >
                <EditIcon />
              </div>
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={editAdvisorMember}
        title={name}
        showCancelButton
        showOkButton
        buttonOkText={"Update"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingAction}
      >
        <div className={styles.imgAndInputs}>
          <div className={styles.imagePreviewContainer}>
            <div className={styles.preview}>
              {imageSrc !== "" ? (
                <div className={styles.cancelIcon} onClick={remvovePhoto}>
                  <CloseIcon />
                </div>
              ) : null}
              <img
                id={"preview-selected-image"}
                alt=""
                src={imageSrc}
                style={{ display: imageSrc !== "" ? "block" : "none" }}
              />
            </div>
            <label htmlFor="file-upload">
              Upload <UploadIcon />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={(e) => previewImage(e)}
            />
          </div>
          <div className={styles.inputsCaontainer}>
            <Input
              placeholder={"Full name"}
              classes={styles.inputDetails}
              value={name}
              onChange={(e) => setName(e)}
            />
            <Input
              placeholder={"Position in an organization"}
              classes={styles.inputDetails}
              value={position}
              onChange={(e) => setPosition(e)}
              maxLength={255}
            />
            <Input
              placeholder={"The highest education"}
              classes={styles.inputDetails}
              value={university}
              onChange={(e) => setUniversity(e)}
            />
          </div>
        </div>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName={styles.editorClassName}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "fontFamily",
            ],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
            fontFamily: {
              options: ["Source Sans Pro", "Libre Baskerville"],
            },
          }}
          onEditorStateChange={onEditorStateChange}
        />
      </Modal>
    </div>
  );
};

export default YourAdvisorTeamAdmin;
