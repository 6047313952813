import React from "react";
import styles from "./TaxPlanning.module.scss";

import image from "./../../assets/images/ourServicesTax.jpg";

const TaxPlanning = () => {
  return (
    <section className={styles.taxPlanning}>
      <img src={image} alt="Tax Planning" />
      <h1>Tax Planning</h1>
      <div>
        <p>
          Would you like to reduce your taxes? Many people would like to reduce
          taxes but do not hire a tax professional to help them. If you are our
          client, we may be able to provide you with information to help you
          reduce your tax bill. We have relationships with income tax and estate
          tax professionals that we consult with and use to help our clients
          improve their tax efficiency. We define tax efficiency as a potential
          reduction in taxes, as much as possible, while maintaining compliance
          with the IRS and state revenue offices.
          <br />
          <br />
          Tax planning is part of financial planning. We can evaluate your tax
          situation and see if approved methods are available to help you reduce
          your overall tax bill. We may be able to help you keep more of your
          money. Keeping more of your money is like making money. We enjoy
          helping our clients make money. They like it too.
          <br />
          <br />
          Tax planning involves a careful analysis of your financial profile
          including your income, deductions, credits, types of accounts, and
          other special factors to optimize your tax savings. Tax savings allow
          you the flexibility to save more money for retirement. Tax savings
          allow you to invest more money to help you achieve your investment
          goals or even set aside more money to cover other necessary expenses.
          A financial professional who understands your tax situation may be
          better equipped to help you in other areas, such as investment
          management or estate planning.
          <br />
          <br />
          “For individual investors, tax management also plays a significant
          role in maximizing wealth, but it typically does not receive the
          attention it deserves” (Reichenstein, 2006). Minimizing your tax
          obligation is a worthwhile endeavor. A member of Wells Investments can
          help you explore this endeavor.
          <br />
          <br />
          We have decades of experience working with certified public
          accountants (CPAs), tax professionals, and clients on tax planning
          from a financial planning perspective. This is information intensive.
          We ask you questions to help you determine what may be a more
          tax-efficient way to handle your finances and financial plan. We are
          not tax professionals. We do not give tax advice. We educate you on
          tax strategies. You need to confirm these strategies with your tax
          professional. If you wish to receive tax advice, you should hire tax
          professionals for more specific information. Because we approach tax
          planning from an overall financial planning perspective, we believe
          that adds value.
          <br />
          <br />
          <br />
          Reference:
          <br />
          Reichenstein, William. “Trends and Issues.” TRENDS AND ISSUES:
          TAX-EFFICIENT SAVING AND INVESTING, Feb. 2006.
        </p>
      </div>
    </section>
  );
};

export default TaxPlanning;
