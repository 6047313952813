import React from "react";
import classNames from "classnames";
import styles from "./Table.module.scss";
import Pagination from "../Pagination/Pagination";

export const ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const Table = ({
  currentPage,
  totalCount,
  pageSize,
  setPage,
  children,
  hasExtraActions,
  headerInformation,
  noDataContent,
  sortOptions,
  setSortOptions,
}) => {
  const fullWidth = hasExtraActions
    ? headerInformation.length + 1
    : headerInformation.length;

  const onSort = (key) => {
    if (sortOptions.order === ORDER.ASC && !sortOptions.sortBy) {
      setSortOptions({ ...sortOptions, sortBy: key });
    } else if (key !== sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.ASC });
    } else if (sortOptions.order === ORDER.ASC && sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.DESC });
    } else {
      setSortOptions({ sortBy: "", order: ORDER.ASC });
    }
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              {headerInformation.map((item, index) => {
                return (
                  <th
                    className={classNames([
                      styles.th,
                      item.key && styles.sortable,
                      item.key === sortOptions.sortBy && styles.sorted,
                    ])}
                    key={index}
                    onClick={() => {
                      item.key && item.sortable && onSort(item.key);
                    }}
                  >
                    {item.label}
                    {item.key && item.sortable && (
                      <span
                        className={classNames([
                          styles.chevron,
                          sortOptions.order === ORDER.DESC &&
                          item.key === sortOptions.sortBy
                            ? styles.top
                            : styles.bottom,
                        ])}
                      />
                    )}
                  </th>
                );
              })}
              {hasExtraActions && <th className={styles.th}>Actions</th>}
            </tr>
          </thead>
          {children?.length ? (
            <tbody className={styles.tbody}>
              {children?.map((child, index) => {
                return (
                  <tr className={styles.tr} key={index}>
                    {child}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className={styles.tbody}>
              <tr className={styles.tr}>
                <td className={styles.noDataTd} colSpan={fullWidth}>
                  {noDataContent}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {totalCount !== 0 && (
        <div className={styles.paginationContainer}>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Table;
