import React from "react";
import styles from "./AboutUs.module.scss";

import Map1 from "../../assets/images/map1.jpg";
import Map2 from "../../assets/images/map2.jpg";

const AboutUs = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <section className={styles.contactsContainer}>
        <div className={styles.address}>
          <h1>About Us</h1>
          <div className={styles.addresses}>
            <div className={styles.adressesContainer}>
              <h2>Home Office</h2>
              <p>521 West Central Avenue</p>
              <p>Winter Haven, FL 33880</p>
            </div>
            <div className={styles.adressesContainer}>
              <h2>Tampa Office</h2>
              <p>10150 Highland Manor Drive, Suite 200</p>
              <p>Tampa, FL 33610</p>
            </div>
          </div>

          <div className={styles.addresses}>
            <div>
              <h2>Email Us</h2>
              <span>
                <a href={`mailto:joinus@wellsinvestments.com`}>
                  joinus@wellsinvestments.com
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.map}>
          <a
            href="https://maps.google.com/maps?q=10150+Highland+Manor+Dr.,+Suite+200+Tampa,+FL+33610&amp;ie=UTF-8&amp;hq=&amp;hnear=0x88c2cece3af4f963:0xd4c2ef3aa82bf0df,10150+Highland+Manor+Dr+%23200,+Tampa,+FL+33610&amp;gl=us&amp;ei=ixsWUuCmOPG84AOWoIDYDg&amp;ved=0CCsQ8gEwAA"
            target="_blank"
            rel="noreferrer"
          >
            <img height="350" src={Map1} alt={"map"} />
          </a>

          <a
            href="https://maps.google.com/maps?q=521+West+Central+Avenue,+Winter+Haven+FL+33880&amp;ie=UTF-8&amp;ei=4LGKUqqTNe2w4AOh-oH4DQ&amp;ved=0CAoQ_AUoAg"
            target="_blank"
            rel="noreferrer"
          >
            <img height="350" alt={"map"} src={Map2} />
          </a>
        </div>
      </section>
      <section className={styles.list}>
        <h1>What We Bring to the Table</h1>
        <p>
          Our total firm philosophy starts with six simple points that we
          believe are essential to create the basis of a solid business
          relationship in the investment industry.
        </p>
        <ul>
          <li>
            <span>
              From knowledge comes wisdom, from wisdom comes understanding. We
              understand and care for our clients needs.
            </span>
          </li>
          <li>
            <span>
              The necessary education, training, continuing education, and
              investment experience to make suitable recommendations.
            </span>
          </li>
          <li>
            <span>
              The demonstrated expertise and track record to implement a plan
              and adjust and react accordingly.
            </span>
          </li>
          <li>
            <span>
              Initiate and maintain quality and meaningful contact with our
              clients in good times and especially during difficult times.
            </span>
          </li>
          <li>
            <span>We work within the rules.</span>
          </li>
          <li>
            <span>
              <b>
                I Will&reg; Get The Job Done, I Will&reg; Not Make Excuses, I
                Will&reg; Not Quit.
              </b>
            </span>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default AboutUs;
