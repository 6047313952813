import React, { useEffect, useState } from "react";
import styles from "./ReferralScreen.module.scss";

import Form from "../../../components/Form/Form";
import Input from "../../../components/Input/Input";
import { Button, CheckBox } from "../../../components";
import { notifyInfo } from "../../../utils/notify";

import ReferralService from "../../../api/services/referral";

const ReferralScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [permission, setPermision] = useState(false);
  const [btnEnabled, setBtnEnabled] = useState(false);

  useEffect(() => {
    if (firstName !== "" && lastName !== "" && phoneNumber !== "") {
      setBtnEnabled(true);
    }
  }, [firstName, lastName, phoneNumber]);

  const hadleSubmitForm = async (e) => {
    e.preventDefault();

    await ReferralService.createReferral({
      firstName,
      lastName,
      phoneNumber,
      permissionToCall: permission,
    })
      .then((res) => {
        notifyInfo("Form was successfully sent");
      })
      .finally();

    resetFields();
  };

  const handleCLickPermision = (val) => {
    setPermision(val);
  };

  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setPermision(false);
    setBtnEnabled(false);
  };
  return (
    <div className={styles.containerOutside}>
      <div className={styles.containerInside}>
        <div className={styles.item}>
          <h1>Referral</h1>
          <p>
            Many of our clients send us referrals. We wish to express our
            sincere appreciation to all of our clients who have sent us a
            referral.
          </p>
        </div>
        <div className={styles.item}>
          <Form classes={styles.formContainer}>
            <Input
              placeholder={"First Name"}
              required
              value={firstName}
              onChange={(value) => setFirstName(value)}
            />
            <Input
              placeholder={"Last Name"}
              required
              value={lastName}
              onChange={(value) => setLastName(value)}
            />
            <Input
              placeholder={"Phone Number"}
              required
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
            <span>Has your referral granted us permission to call them? </span>
            <div className={styles.checkboxContainer}>
              <CheckBox
                checked={permission}
                text={"Yes"}
                className={styles.checkbox}
                onClick={() => handleCLickPermision(true)}
              />
              <CheckBox
                checked={!permission}
                text={"No"}
                className={styles.checkbox}
                onClick={() => handleCLickPermision(false)}
              />
            </div>
             
            <Button
              title={"Submit"}
              onClick={hadleSubmitForm}
              enabled={!btnEnabled}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ReferralScreen;
