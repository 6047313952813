import { createAsyncThunk } from "@reduxjs/toolkit";
import QuizService from "../../../api/services/quiz";

export const fetchQuestions = createAsyncThunk(
  "quiz/fetchQuestions",
  async () => {
    return await QuizService.fetchQuestions();
  }
);

export const fetchCategories = createAsyncThunk(
  "quiz/fetchCategories",
  async () => {
    return await QuizService.fetchCategories();
  }
);

export const fetchQuizResults = createAsyncThunk(
  "quiz/fetchQuizResults",
  async (requestData) => {
    const { page, sortBy, order, search } = requestData;
    return await QuizService.fetchQuizResults(page, sortBy, order, search);
  }
);

export const editQuestion = createAsyncThunk(
  "quiz/editQuestion",
  async (requestData) => {
    return await QuizService.editQuestion(requestData);
  }
);

export const editCategory = createAsyncThunk(
  "quiz/editCategory",
  async (requestData) => {
    return await QuizService.editCategory(requestData);
  }
);

export const editAnswer = createAsyncThunk(
  "quiz/editAnswer",
  async (requestData) => {
    return await QuizService.editAnswer(requestData);
  }
);

export const deleteQuizResult = createAsyncThunk(
  "quiz/QuizResult",
  async (requestData) => {
    const { id } = requestData;
    return await QuizService.deleteQuizResult(id);
  }
);
