import {
  fetchEvents,
  fetchAllEvents,
  deleteEvents,
  createEvents,
  editEvents,
} from "./asyncThunks";

export const fetchEventsBuilder = (builder) => {
  builder.addCase(fetchEvents.pending, (state) => {
    state.fetchEvents = true;
  });
  builder.addCase(fetchEvents.fulfilled, (state, action) => {
    state.fetchEvents = false;
    state.events.data = action.payload.events;
  });
  builder.addCase(fetchEvents.rejected, (state) => {
    state.fetchEvents = false;
  });
};

export const fetchAllEventsBuilder = (builder) => {
  builder.addCase(fetchAllEvents.pending, (state) => {
    state.fetchEvents = true;
  });
  builder.addCase(fetchAllEvents.fulfilled, (state, action) => {
    state.fetchEvents = false;
    state.events.data = action.payload.events;
  });
  builder.addCase(fetchAllEvents.rejected, (state) => {
    state.fetchEvents = false;
  });
};

export const deleteEventsBuilder = (builder) => {
  builder.addCase(deleteEvents.pending, (state) => {
    state.deleteEvents = true;
  });
  builder.addCase(deleteEvents.fulfilled, (state, action) => {
    state.deleteEvents = false;
    state.events.data = state.events.data.map((s) => {
      if (s.id === action.payload.data.result.id) {
        s.deletedAt = "deleted";
      }
      return s;
    });
  });
  builder.addCase(deleteEvents.rejected, (state) => {
    state.deleteEvents = false;
  });
};

export const createEventsBuilder = (builder) => {
  builder.addCase(createEvents.pending, (state) => {
    state.createEvents = true;
  });
  builder.addCase(createEvents.fulfilled, (state, action) => {
    state.createEvents = false;
    state.events.data.unshift(action.payload.data.event);
  });
  builder.addCase(createEvents.rejected, (state) => {
    state.createEvents = false;
  });
};

export const editEventsBuilder = (builder) => {
  builder.addCase(editEvents.pending, (state) => {
    state.editEvents = true;
  });
  builder.addCase(editEvents.fulfilled, (state, action) => {
    state.editEvents = false;
    state.events.data = state.events.data.map((s) => {
      if (s.id === action.payload.data.event.id) {
        return action.payload.data.event;
      } else {
        return s;
      }
    });
  });
  builder.addCase(editEvents.rejected, (state) => {
    state.editEvents = false;
  });
};
