import { React, useEffect, useState } from "react";
import styles from "./SpeakWithAnAdvisor.module.scss";

import ContactFormService from "../../api/services/contact";

import { notifyInfo } from "../../utils/notify";

import Form from "../Form/Form";
import Input from "../Input/Input";
import Button from "../Button/Button.js";
import DropDownMenuCustom from "../DropDownMenuCustom/DropDownMenuCustom";

import usaStates from "../../assets/json/states.json";

import { purposes, investableAssets } from "../../utils/constants";

const SpeakWithAnAdvisor = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [purpose, setPurpose] = useState("");
  const [assets, setAssets] = useState("");
  const [note, setNote] = useState("");

  const [submitBtnEbabled, setSubmitBtnEbabled] = useState(false);

  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      address !== "" &&
      state !== "" &&
      purpose !== "" &&
      assets !== ""
    ) {
      setSubmitBtnEbabled(true);
    } else {
      setSubmitBtnEbabled(false);
    }
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
    state,
    purpose,
    assets,
  ]);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setState("");
    setPurpose("");
    setAssets("");
    setNote("");
  };

  const confirmAction = async (e) => {
    e.preventDefault();
    await ContactFormService.contactForm({
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      assets,
      purpose,
      note,
      state,
    })
      .then((res) => {
        notifyInfo("Form was successfully sent");
      })
      .finally();

    e.target.reset();
    resetForm();
  };

  return (
    <section className={styles.speakWithAnAdvisorContainer}>
      <div className={styles.formWrapper}>
        <h1>Speak With An Advisor</h1>
        <Form onSubmit={confirmAction} classes={styles.formContainer}>
          <Input
            required={true}
            placeholder={"First Name"}
            name={"first-name"}
            classes={styles.inputContainer50}
            onChange={(event) => setFirstName(event)}
          />
          <Input
            required={true}
            placeholder={"Last Name"}
            name={"last-name"}
            classes={styles.inputContainer50}
            onChange={(event) => setLastName(event)}
          />
          <Input
            required={true}
            placeholder={"Email Address"}
            name={"email"}
            classes={styles.inputContainer50}
            onChange={(event) => setEmail(event)}
          />
          <Input
            required={true}
            placeholder={"Phone Number"}
            name={"phone-number"}
            classes={styles.inputContainer50}
            onChange={(event) => setPhoneNumber(event)}
          />
          <Input
            required={true}
            placeholder={"Address"}
            name={"address"}
            classes={styles.inputContainer50}
            onChange={(event) => setAddress(event)}
          />
          <DropDownMenuCustom
            required
            placeholder={"State of residence"}
            options={usaStates}
            onChange={(event) => setState(event)}
            classes={styles.inputContainer50}
          />
          <DropDownMenuCustom
            required
            placeholder={"Purpose of the Contact"}
            options={purposes}
            onChange={(event) => setPurpose(event)}
            classes={styles.inputContainer50}
          />
          <DropDownMenuCustom
            required
            placeholder={"Investable Assets"}
            options={investableAssets}
            onChange={(event) => setAssets(event)}
            classes={styles.inputContainer50}
          />
          <textarea
            placeholder={"Leave a note"}
            name={"note"}
            className={styles.inputContainer100}
            onChange={(event) => setNote(event.target.value)}
          />
          <Button
            enabled={!submitBtnEbabled}
            type={"submit"}
            classes={styles.submitButton}
            title={"Submit"}
          />
        </Form>
        <div className={styles.speakWithAnAdvisorDisclaimer}>
          <h3>Disclosure</h3>
          <p>
            I certify that I am the person that is identified in the information
            entered above, and I give Wells Investments, Inc. permission to
            contact me by email or telephone.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SpeakWithAnAdvisor;
