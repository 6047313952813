import React, { useEffect, useState } from "react";
import styles from "./PrioritiesSection.module.scss";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import sessionStorage from "../../../services/sessionStorageService";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";

import { icons } from "../../../utils/constants";

import { ReactComponent as ArrowsUpDown } from "../../../assets/icons/arrows-down-up.svg";

import { selectCategories } from "../../../store/slices/quiz/slice";
import { fetchCategories } from "../../../store/slices/quiz/asyncThunks";

const PrioritiesScren = ({ changeActiveSection, topPrioritiesList }) => {
  const dispatch = useDispatch();
  const session = sessionStorage;
  const categories = useSelector(selectCategories);

  const [state, setState] = useState({
    widgets: {
      "column-1": topPrioritiesList.slice(0, 3),
      "column-2": topPrioritiesList.slice(3),
    },
  });

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleNextSection = () => {
    let topPrioritiesList = state.widgets["column-1"];

    topPrioritiesList.push(...state.widgets["column-2"]);

    changeActiveSection(topPrioritiesList);

    session.setItem("top-order", topPrioritiesList);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      if (destination.index === source.index) {
        return;
      }

      const widgets = reorder(
        state.widgets[source.droppableId],
        source.index,
        destination.index
      );

      const updateState = {
        widgets: {
          ...state.widgets,
          [source.droppableId]: widgets,
        },
      };

      setState(updateState);
    } else {
      let startColumn = [...state.widgets[source.droppableId]];
      let finishColumn = [...state.widgets[destination.droppableId]];

      const [removed] = startColumn.splice(source.index, 1);
      finishColumn.splice(destination.index, 0, removed);

      if (finishColumn.length > 3) {
        if (destination.droppableId === "column-1") {
          startColumn = [finishColumn[finishColumn.length - 1], ...startColumn];
          finishColumn.pop();
        }
      }

      if (destination.droppableId === "column-2" && finishColumn.length > 3) {
        startColumn = [...startColumn, finishColumn[0]];
        finishColumn.shift();
      }

      const updateState = {
        widgets: {
          ...state.widgets,
          [source.droppableId]: startColumn,
          [destination.droppableId]: finishColumn,
        },
      };
      setState(updateState);
    }
  };

  return (
    <>
      <section className={classNames(styles.grayBg)}>
        <div className={styles.prioritiesSection}>
          <div className={styles.textPart}>
            <h1>In order of priority </h1>
            <p>
              Based on your selections, it seems like this is the order of your
              priorities. <br />
              Please adjust as you see fit.
            </p>
          </div>
          <div className={styles.dragAndDropContainer}>
            <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
              <div className={styles.dragDropContainer}>
                <Column
                  widgets={state.widgets["column-1"]}
                  droppableId={"column-1"}
                  className={styles.column}
                  column={0}
                ></Column>
                <Column
                  widgets={state.widgets["column-2"]}
                  droppableId={"column-2"}
                  column={state.widgets["column-1"].length}
                  className={styles.columnRight}
                ></Column>
              </div>
            </DragDropContext>
            <div className={styles.btnContainer}>
              <Button
                title={"Continue"}
                onClick={handleNextSection}
                classes={styles.btnContinue}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.containerQuestions}>
        <h1>Why are we asking these questions?</h1>
        <p className={styles.subText}>
          The statements you selected help you uncover what’s most important to
          you, from among seven key life priorities. The insights can help an
          advisor work with you to achieve your goals.
        </p>
        <div className={styles.cardsContainer}>
          {categories.map((item, index) => (
            <Card classes={styles.card} key={index}>
              <div className={styles.header}>
                <div className={styles.iconQuestion}>{icons[item.name]}</div>
                <h2>{item.name}</h2>
              </div>
              <p>{item.shortText}</p>
            </Card>
          ))}
        </div>
      </section>
    </>
  );
};

const Column = ({ droppableId, widgets, className, column }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={className}
        >
          <WidgetList widgets={widgets} column={column} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const WidgetList = ({ widgets, column }) => {
  return widgets.map((widget, index) => (
    <Widget
      widget={widget}
      index={index}
      key={widget.id}
      length={widgets.length}
      column={column}
    />
  ));
};

const Widget = ({ widget, index, length, column }) => {
  return (
    <Draggable draggableId={widget.id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={styles.cardWrapper}>
            <span className={styles.indexNumber}>{index + column + 1}</span>
            <Card classes={styles.cardContent}>
              <div className={styles.icon}>{icons[widget.name]}</div>
              <span>{widget.name}</span>
            </Card>
            <ArrowsUpDown className={styles.arrowsUpDown} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default PrioritiesScren;
