import React from "react";
import styles from "./ModalContactForm.module.scss";
import Modal from "../Modal/Modal";

const ModalContactForm = ({ modalRef, title, selectedContactForm }) => {
  return (
    <Modal
      ref={modalRef}
      title={title}
      buttonCloseText="CANCEL"
      buttonCloseOnClick={() => {
        modalRef.current.hide();
      }}
      showOkButton={false}
    >
      <div className={styles.itemContainer}>
        <p>Name: </p>
        <p>
          {selectedContactForm?.firstName + " " + selectedContactForm?.lastName}
        </p>
      </div>
      <div className={styles.itemContainer}>
        <p>Email: </p>
        <p>{selectedContactForm?.email}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>Phone: </p>
        <p>{selectedContactForm?.phoneNumber}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>Purpose: </p>
        <p>{selectedContactForm?.purpose}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>Investable Assets: </p>
        <p>{selectedContactForm?.assets}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>Note: </p>
        <p>{selectedContactForm?.note}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>Address: </p>
        <p>{selectedContactForm?.address}</p>
      </div>
      <div className={styles.itemContainer}>
        <p>State of residence: </p>
        <p>{selectedContactForm?.state}</p>
      </div>
    </Modal>
  );
};

export default ModalContactForm;
