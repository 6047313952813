import React from "react";
import styles from "./TheWellsClients.module.scss";

import image from "./../../assets/images/homeIWill.jpg";
import image2 from "./../../assets/images/homeFee.jpg";
import image3 from "./../../assets/images/homeImp.jpg";
import image4 from "./../../assets/images/homeGeneration.jpg";

import Card from "../Card/Card.js";

const TheWellsClients = () => {
  return (
    <section className={styles.listContainer}>
      <h1>The Wells Investments Commitment to Clients</h1>
      <p>
        Our client’s financial well-being is one of the most important
        responsibilities that we have. We know how important it is for you to be
        able to talk with us, gain from our experience and guidance and to help
        you in the following ways:
      </p>
      <div className={styles.listItems}>
        <ul>
          <li>Listen to you. I will understand your goals and objectives.</li>
          <li>Follow procedures, regulations, and compliance.</li>
          <li>Always return your calls.</li>
          <li>Work hard for you.</li>
          <li>Help you establish your risk tolerance.</li>
          <li>Act as a fiduciary.</li>
          <li>Not make excuses.</li>
        </ul>
        <img
          src={image}
          alt="The Wells Investments Commitment to Clients"
          className={styles.imgW50}
        />
      </div>
      <div className={styles.cardContainer}>
        <Card classes={styles.card}>
          <h2>Fee-Based Advisor</h2>
          <img src={image2} alt="Fee-Based Advisor" />
          <p>
            Wells Investments is a fee-based advisor. We are not a brokerage
            firm that charges commissions. We are happy to disclose our
            reasonable fee-based schedule.
          </p>
        </Card>
        <Card classes={styles.card}>
          <h2>You are Important</h2>
          <img src={image3} alt="You are Important" />
          <p>
            Our plans are based on your personal needs. Risk management should
            be one of your most important objectives to achieve your financial
            goals.
          </p>
        </Card>
        <Card classes={styles.card}>
          <h2>Generational Planning</h2>
          <img src={image4} alt="Generational Planning" />
          <p>
            We help clients with generational needs. This includes how to pass
            on family wealth to who you want to receive it the way you want them
            to receive it.
          </p>
        </Card>
      </div>
    </section>
  );
};

export default TheWellsClients;
