import React, { useEffect } from "react";
import styles from "./AdvisorTeamMember.module.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import arrowLeft from "./../../assets/images/left-arrow-svgrepo-com.svg";
import { selectAdvisorTeam } from "../../store/slices/advisorTeam/slice";

const AdvisorTeamMember = () => {
  const params = useParams();

  const advisorTeam = useSelector(selectAdvisorTeam).find(
    (el) => el.id === parseInt(params.id)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.advisorTeamMemberContainer}>
      <div className={styles.back}>
        <Link to={"/your-advisor-team"}>
          <img src={arrowLeft} alt="back" />
          Return to Your Advisor Team
        </Link>
      </div>
      <div className={styles.teamMemberDetailsContainer}>
        <img src={advisorTeam?.profilePhoto} alt={advisorTeam?.name} />

        <div>
          <h1>{advisorTeam?.name}</h1>
          <p className={styles.blueText}>{advisorTeam?.position}</p>
          <p className={styles.blueText} style={{ paddingBottom: "40px" }}>
            {advisorTeam?.school}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: advisorTeam?.text,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default AdvisorTeamMember;
