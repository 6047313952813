import React from "react";

import TopNav from "../TopNav/TopNav";
import MainNav from "../MainNav/MainNav";

const Header = ({ topNavNavigation, mainNavigation }) => {
  return (
    <header>
      <TopNav navigation={topNavNavigation} />
      <MainNav navigation={mainNavigation} />
    </header>
  );
};

export default Header;
