import React, { useEffect } from "react";
import styles from "./ResearchAndInsights.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";

import { Card } from "../../components";
import { isoToDateTime } from "../../utils/util";

import { fetchEvents } from "../../store/slices/events/asyncThunks";
import { selectEvents } from "../../store/slices/events/slice";

import { fetchResearchAndInsight } from "../../store/slices/researchAndInsights/asyncThunks";
import { selectResearchAndInsight } from "../../store/slices/researchAndInsights/slice";

const ResearchAndInsights = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEvents());
    dispatch(fetchResearchAndInsight());
  }, [dispatch]);

  const events = useSelector(selectEvents);
  const researchAndInsight = useSelector(selectResearchAndInsight);

  let width = window.innerWidth;

  return (
    <section className={styles.researchAndInsightsContainer}>
      {researchAndInsight.length > 0 && (
        <>
          <div className={styles.cardContainer}>
            <h1>Research And Insights</h1>
            <Swiper
              slidesPerView={width < 768 ? 1 : 3}
              spaceBetween={20}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
            >
              {researchAndInsight.length > 0 &&
                researchAndInsight.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card classes={styles.card}>
                        <img src={item.photoUrl} alt={item.text} />
                        <h3>{item.title}</h3>
                        <NavLink to={"pdf/" + item.id}>Read More</NavLink>
                      </Card>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </>
      )}
      <div className={styles.upcomingEventsContainer}>
        <div>
          <h2>Upcoming Events</h2>
          <div className={styles.upcomingEventsCardContainer}>
            {events.map((item) => (
              <Card key={item.id} classes={styles.upcomingEventCard}>
                <div className={styles.cardHeader}>
                  <h3>{item.title}</h3>
                  <span>{isoToDateTime(item.eventDate)}</span>
                </div>
                <p>{item.text}</p>
                {item.showMore && (
                  <NavLink className={styles.seeMore} to={`${item.id}`}>
                    See more
                  </NavLink>
                )}
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResearchAndInsights;
