import React from "react";
import styles from "./EmploymentScreen.module.scss";

import { useSelector } from "react-redux";

import { selectLifePriorities } from "../../../store/slices/lifePriorities/slice";

import img from "../../../assets/images/lifeEmployment.jpg";

const EmploymentScreen = () => {
  const lifePriorites = useSelector(selectLifePriorities);
  return (
    <section className={styles.container}>
      <img src={img} alt="family" />
      <h1>Employment</h1>
      {lifePriorites && (
        <div
          dangerouslySetInnerHTML={{
            __html: lifePriorites.find(
              (element) => element.key === "employment"
            )?.text,
          }}
        ></div>
      )}
    </section>
  );
};

export default EmploymentScreen;
