import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import classNames from "classnames";
import styles from "./PrivateLayout.module.scss";
import PrivateNavigation from "../../components/PrivateNavigation/PrivateNavigation";
import { selectLoggedIn } from "../../store/slices/auth/slice";
import { selectLoggedInUserRole } from "../../store/slices/user/slice";
import { PERMISSIONS_FOT_ROUTES } from "../../utils/constants";
import { Button, Chip, Input, Modal } from "../../components";
import { selectLoggedInUserDetails } from "../../store/slices/user/slice";
import { ROLES } from "../../utils/constants";
import session from "../../services/sessionStorageService";
import UserService from "../../api/services/user";
import { notifyError, notifyInfo } from "../../utils/notify";

const PrivateLayout = () => {
  const isAuthenticated = useSelector(selectLoggedIn);
  const location = useLocation();

  const loggedInUserDetails = useSelector(selectLoggedInUserDetails);

  const userRole = useSelector(selectLoggedInUserRole);
  const [userHasRequiredRole, setUserHasRequiredRole] = useState(false);

  const myProfileModal = useRef();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetPasswordBtnEnabled, setResetPasswordBtnEnabled] = useState(true);

  const [pendingResetPassword, setPendingResetPassword] = useState(false);

  const changePassword = async () => {
    setPendingResetPassword(true);
    await UserService.changePassword({
      oldPassword: oldPassword,
      password: newPassword,
    })
      .then((res) => {
        notifyInfo("Password changed successfully");
        resetForm();
      })
      .catch((res) => {
        notifyError(res.message);
      })
      .finally();
  };

  const resetForm = async () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setResetPasswordBtnEnabled(true);
    setPendingResetPassword(false);
  };

  useEffect(() => {
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmNewPassword !== "" &&
      newPassword === confirmNewPassword
    ) {
      setResetPasswordBtnEnabled(false);
    } else {
      setResetPasswordBtnEnabled(true);
    }
  }, [oldPassword, newPassword, confirmNewPassword]);

  useEffect(() => {
    if (PERMISSIONS_FOT_ROUTES[location.pathname]) {
      if (PERMISSIONS_FOT_ROUTES[location.pathname].includes(userRole)) {
        setUserHasRequiredRole(true);
      } else {
        setUserHasRequiredRole(false);
      }
    } else {
      setUserHasRequiredRole(true);
    }
  }, [location]);

  const titleWithSlash =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  let title = "";
  if (location.pathname.includes("clients?")) {
    title = "Client";
  } else if (location.pathname.includes("clients/")) {
    title = session.getItem("client");
  } else {
    title = titleWithSlash.replaceAll("-", " ");
  }

  return isAuthenticated && userRole === ROLES.ADMIN ? (
    <div className={classNames(styles.container)}>
      <PrivateNavigation />
      <div className={classNames(styles.screenContainer)}>
        <div className={styles.topBar}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.bottomContainerProfile}>
            <div
              className={styles.userContainer}
              onClick={() => myProfileModal.current.show()}
            >
              <Chip
                {...loggedInUserDetails}
                img={loggedInUserDetails.profilePhoto}
              />
              {loggedInUserDetails.firstName +
                " " +
                loggedInUserDetails.lastName}
            </div>
          </div>
        </div>
        {userHasRequiredRole ? <Outlet /> : <div>FORBIDDEN</div>}
      </div>

      <Modal
        ref={myProfileModal}
        modalStyle={styles.modalStyle}
        title={"Account details"}
        showOkButton={false}
        buttonCloseText={"Close"}
        buttonCloseOnClick={() => {
          resetForm();
          myProfileModal.current.hide();
        }}
      >
        <div className={styles.itemContainer}>
          <p>First name:</p>
          <p>{loggedInUserDetails.firstName}</p>
        </div>
        <div className={styles.itemContainer}>
          <p>Last name:</p>
          <p>{loggedInUserDetails.lastName}</p>
        </div>
        <div className={styles.itemContainer}>
          <p>Email:</p>
          <p>{loggedInUserDetails.email}</p>
        </div>
        <div className={styles.resetPasswordSection}>
          <p>Reset password:</p>
          <Input
            placeholder={"Old password"}
            name={"Old password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setOldPassword(event)}
            value={oldPassword}
          />
          <Input
            placeholder={"New password"}
            name={"New password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setNewPassword(event)}
            value={newPassword}
          />
          <Input
            placeholder={"Confirm new password"}
            name={"Confirm new password"}
            type={"password"}
            classes={styles.inputContainer100}
            onChange={(event) => setConfirmNewPassword(event)}
            value={confirmNewPassword}
          />
          <Button
            title={"Reset password"}
            enabled={resetPasswordBtnEnabled}
            onClick={changePassword}
            classes={styles.resetBtn}
            loading={pendingResetPassword}
          ></Button>
        </div>
      </Modal>
    </div>
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} replace />
  );
};

export default PrivateLayout;
