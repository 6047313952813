import axiosApiFreeClient from "..";
import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "/contactForm";

class ContactFormService {
  contactForm = async (formData) => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}`, formData);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, "Error occured while creating contact form.");
    }
  };
  fetchContactForms = async (page, sortBy, order, search) => {
    try {
      if (!sortBy) {
        sortBy = "";
      }
      if (!order) {
        order = "ASC";
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}?page=${page}&sortBy=${sortBy}&order=${order}&search=${search}`
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, "Error occured while fetching contact forms.");
    }
  };
  deleteContactForm = async (contactFormId) => {
    try {
      const response = await axiosApiFreeClient.delete(
        `${BASE_URL}/${contactFormId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error occured while deleting contact form.");
    }
  };
}

export default new ContactFormService();
