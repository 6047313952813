import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLifePrioritiesBuilder,
  updateLifePrioritiesBuilder,
} from "./builder";

export const initialState = {
  lifePriorities: {
    data: [],
  },
  fetchLifePrioritiesPending: false,
  updateLifePrioritiesPending: false,
};

export const lifePrioritiesSlice = createSlice({
  name: "lifePriorities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchLifePrioritiesBuilder(builder);
    updateLifePrioritiesBuilder(builder);
    builder.addCase("logout", () => initialState);
  },
});

// export const {} = userSlice.actions;

export const selectLifePriorities = (state) =>
  state.lifePriorities.lifePriorities.data;

export const selectFetchLifePrioritiesPending = (state) =>
  state.lifePriorities.fetchLifePrioritiesPending;
export const selectUpdateLifePrioritiesPending = (state) =>
  state.lifePriorities.updateLifePrioritiesPending;

export default lifePrioritiesSlice.reducer;
