import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchInput,
  Card,
  Modal,
  Input,
  CheckBox,
  DropDownMenuCustom,
} from "../../../components";
import moment from "moment";
import styles from "./EventsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyInfo } from "../../../utils/notify";
import debounce from "lodash.debounce";
import classNames from "classnames";
import { isoToDateTime } from "../../../utils/util";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import {
  selectEvents,
  selectDeleteEventsPending,
  selectCreateEventsPending,
} from "../../../store/slices/events/slice";
import {
  fetchAllEvents,
  deleteEvents,
  createEvents,
  editEvents,
} from "../../../store/slices/events/asyncThunks";

import { optionHours, optionMinutes } from "../../../utils/constants";

const EventsScreen = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());

  const [minutes, setMinutes] = useState("00");
  const [hours, setHours] = useState("20");

  const dispatch = useDispatch();
  const events = useSelector(selectEvents);

  const pendingDeleteAction = useSelector(selectDeleteEventsPending);
  const pendingCreateAction = useSelector(selectCreateEventsPending);

  const createEventsModal = useRef();
  const deleteEventsModal = useRef();

  const [, setSearch] = useState("");

  const changeHandler = useCallback(
    (text) => {
      setSearch(text);
      dispatch(fetchAllEvents({ search: text }));
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [changeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchAllEvents({ search: "" }));
  }, [dispatch]);

  const handleCreateEvents = () => {
    createEventsModal.current.show();
  };

  const handleCloseCreateModal = () => {
    resetInputFields();
    createEventsModal.current.hide();
  };

  const handleEditEvents = (item) => {
    resetInputFields();
    let eventDate = new Date(item.eventDate);

    setHours(
      eventDate.getHours().toString().length === 1
        ? "0" + eventDate.getHours()
        : eventDate.getHours()
    );

    setMinutes(
      eventDate.getMinutes().toString().length === 1
        ? "0" + eventDate.getMinutes()
        : eventDate.getMinutes()
    );

    setEventDate(moment(item.eventDate).format("yyyy-MM-DD"));
    setTitle(item.title);
    setText(item.text);
    setId(item.id);
    setShowMore(item.showMore);

    const contentBlock = htmlToDraft(item.text);
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.description))
      );

      setDescription(editorState);
    }
    createEventsModal.current.show();
  };

  const handleDeleteEvents = (item) => {
    setId(item.id);
    setTitle(item.title);

    deleteEventsModal.current.show();
  };

  const handleDeleteOkModal = () => {
    dispatch(deleteEvents({ eventsId: id })).then((promise) => {
      deleteEventsModal.current.hide();
      notifyInfo("Event deleted.");
    });
  };

  const handleDeleteCloseModal = () => {
    setTitle("");
    deleteEventsModal.current.hide();
  };

  const resetInputFields = () => {
    setTitle("");
    setText("");
    setEventDate("");
    setMinutes("00");
    setHours("20");
    setId("");
    setShowMore(false);
    setDescription(EditorState.createEmpty());
  };

  const handleOkModal = () => {
    let formatDate = new Date(eventDate);
    formatDate.setHours(hours);
    formatDate.setMinutes(minutes);

    if (id) {
      dispatch(
        editEvents({
          id,
          title,
          text,
          eventDate: formatDate.toISOString(),
          showMore,
          description: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ),
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createEventsModal.current.hide();
          notifyInfo("Events successfully updated");
        } else {
          notifyError(promise.error.message);
        }
      });
    } else {
      dispatch(
        createEvents({
          title,
          text,
          eventDate: formatDate.toISOString(),
          showMore,
          description: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ),
        })
      ).then((promise) => {
        if (promise.type.includes("fulfilled")) {
          resetInputFields();
          createEventsModal.current.hide();
          notifyInfo("Events successfully created");
        } else {
          notifyError(promise.error.message);
        }
      });
    }
  };

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <SearchInput onChange={debouncedChangeHandler} placeholder="Search" />
        </div>
        <button className={styles.createEventsBtn} onClick={handleCreateEvents}>
          <span>Add new</span>
          <PlusIcon className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.eventsCardContainer}>
        {events?.map((item) => (
          <Card
            classes={classNames(
              styles.categories,
              item.deletedAt ? styles.deletedEvents : ""
            )}
            key={item.id}
          >
            <div>
              <h2>{item.title}</h2>
              <span>{isoToDateTime(item.eventDate)}</span>
            </div>

            <div className={styles.cardContent}>
              <p>{item.text}</p>

              {!item.deletedAt && (
                <div className={styles.actionBtns}>
                  <div
                    className={styles.editIcon}
                    onClick={() => handleEditEvents(item)}
                  >
                    <EditIcon fill="#fff" />
                  </div>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => handleDeleteEvents(item)}
                  >
                    <DeleteIcon fill="#fff" />
                  </div>
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>
      <Modal
        ref={createEventsModal}
        title={id !== "" ? "Edit event" : "Create event"}
        showCancelButton
        showOkButton
        buttonOkText={id !== "" ? "Edit" : "Create"}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleCloseCreateModal}
        buttonOkOnClick={handleOkModal}
        modalStyle={styles.modalStyle}
        pendingAction={pendingCreateAction}
      >
        <div className={styles.inputFields}>
          <div>
            <Input
              placeholder={"Title"}
              name={"title"}
              classes={styles.inputContainer100}
              onChange={(event) => setTitle(event)}
              value={title}
            />
            <textarea
              placeholder={"Text"}
              name={"text"}
              className={styles.inputContainer100}
              onChange={(event) => setText(event.target.value)}
              value={text}
            />
            <Input
              placeholder={"Event date"}
              name={"event-date"}
              type={"date"}
              classes={styles.inputContainer100}
              onChange={(event) => setEventDate(event)}
              value={eventDate}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingBottom: "20px",
                gap: "13px",
              }}
            >
              <DropDownMenuCustom
                required
                placeholder={"Hours"}
                options={optionHours}
                onChange={(event) => {
                  setHours(event);
                }}
                defaultValue={hours}
                classes={styles.inputContainer50}
              />

              <DropDownMenuCustom
                required
                placeholder={"Minutes"}
                options={optionMinutes}
                onChange={(event) => setMinutes(event)}
                classes={styles.inputContainer50}
                defaultValue={minutes}
              />
            </div>

            <CheckBox
              checked={showMore}
              text={"Enable show more"}
              className={styles.checkbox}
              disabled={false}
              onClick={() => setShowMore((prevCheck) => !prevCheck)}
            />
          </div>
          <div style={{ minHeight: "100%" }}>
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName={styles.wrapperClassName}
              editorClassName={styles.editorClassName}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                ],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                blockType: {
                  options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                },
              }}
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ref={deleteEventsModal}
        title={"Confirm delete action"}
        buttonOkText={"Delete"}
        pendingAction={pendingDeleteAction}
        buttonCloseText={"Close"}
        buttonCloseOnClick={handleDeleteCloseModal}
        buttonOkOnClick={handleDeleteOkModal}
      >
        {"Delete event : " + title}
      </Modal>
    </div>
  );
};

export default EventsScreen;
