import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../../api/services/auth";
import localStorageService from "../../../services/localStorageService";
import { LOCAL_STORAGE_REFRESH_TOKEN } from "../../../utils/constants";

export const login = createAsyncThunk(
  "auth/login",
  async (requestData, thunkApi) => {
    const { email, password } = requestData;
    return await authService.loginWithEmail(email, password);
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const refreshToken = localStorageService.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
  return await authService.logout(refreshToken);
});

export const signup = createAsyncThunk(
  "auth/signup",
  async (requestData, thunkApi) => {
    const { email, firstName, lastName, password, accessCode } = requestData;
    return await authService.signUpWithEmail(
      email,
      firstName,
      lastName,
      password,
      accessCode
    );
  }
);

export const forgotPasswordSendCode = createAsyncThunk(
  "auth/forgotPasswordSendCode",
  async (requestData, thunkApi) => {
    const { email } = requestData;
    return await authService.forgotPasswordSendCode(email);
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (requestData, thunkApi) => {
    const { email, newPassword, code } = requestData;
    return await authService.forgotPassword(email, newPassword, code);
  }
);
