import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import styles from "./ClientLogin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { notifyError } from "../../../utils/notify";
import { Input, Button, Card, Modal } from "../../../components";

import clientLoginImage from "../../../assets/images/clientLogin.jpg";

import mutualFundPng from "../../../assets/images/Mutual Fund Prospect@3x.png";

import { ReactComponent as NetxInvestor } from "../../../assets/icons/site_logo.svg";
import { ReactComponent as JohnHancock } from "../../../assets/icons/John_Hancock.svg";
import { ReactComponent as Venerable } from "../../../assets/icons/Venerable_Logo for Aleks.svg";

import { isValidEmail } from "../../../utils/util";
import { login } from "../../../store/slices/auth/asyncThunks";
import { selectLoggedIn } from "../../../store/slices/auth/slice";
import { selectLoggedInUserRole } from "../../../store/slices/user/slice";
import { ROLES } from "../../../utils/constants";

const ClientLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const resetPasswordModal = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isAuthenticated = useSelector(selectLoggedIn);
  const userRole = useSelector(selectLoggedInUserRole);

  const validateInputData = useMemo(() => {
    if (
      email.trim().length > 0 &&
      password.trim().length > 0 &&
      isValidEmail(email)
    ) {
      return true;
    } else {
      return false;
    }
  }, [email, password]);

  const makeLogin = useCallback(() => {
    dispatch(login({ email, password })).then((promise) => {
      if (promise.payload && promise.payload.user.role === ROLES.USER) {
        if (location.state?.from) {
          navigate(location.state.from);
        } else {
          navigate("/client/documents");
        }
      } else {
        notifyError("Wrong email or password");
      }
    });
  }, [dispatch, email, location, navigate, password]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (validateInputData) {
          makeLogin();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [makeLogin, validateInputData]);

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const goToMutualFundsPage = () => {
    navigate("/mutual-funds");
  };

  const handleCloseModal = () => {
    resetPasswordModal.current.hide();
  };

  const showResetPasswordModal = () => {
    resetPasswordModal.current.show();
  };

  if (!isAuthenticated || userRole === ROLES.ADMIN) {
    return (
      <section className={styles.clientLoginContainer}>
        <div className={styles.formAndImgContainer}>
          <div className={styles.formContainer}>
            <p>Welcome To</p>
            <h1>Client Login</h1>

            <Input
              placeholder={"User ID"}
              classes={styles.inputComponent}
              onChange={(event) => setEmail(event)}
              required
            />
            <Input
              placeholder={"Password"}
              classes={styles.inputComponent}
              onChange={(event) => setPassword(event)}
              type="password"
              required
            />
            <Button
              classes={styles.buttonComponent}
              title={"Sign In"}
              enabled={!validateInputData}
              onClick={makeLogin}
            />

            <Link onClick={showResetPasswordModal} to="#">
              Forgot User ID or Password?
            </Link>
          </div>
          <img src={clientLoginImage} alt="Client Login" />
        </div>
        <div className={styles.partnersContainer}>
          <Card classes={styles.partnersCard}>
            <NetxInvestor
              className={styles.imgPointer}
              onClick={() => {
                openLink("https://www.netxinvestor.com/nxi/welcome");
              }}
            />
            <p>
              Click on the above logo to access your Pershing Brokerage
              Accounts.
            </p>
          </Card>
          <Card classes={styles.partnersCard}>
            <JohnHancock
              className={styles.imgPointer}
              onClick={() => {
                openLink("https://www.jhannuities.com");
              }}
            />
            <p>Click on the above logo to access your John Hancock Accounts.</p>
          </Card>
          <Card classes={styles.partnersCard}>
            <Venerable
              className={styles.imgPointer}
              onClick={() => {
                openLink("https://www.venerable.com");
              }}
            />
            <p>Click on the above logo to access your Venerable Accounts.</p>
          </Card>
          <Card classes={styles.partnersCard}>
            <img
              src={mutualFundPng}
              alt="mutualFundPng"
              className={styles.mutualFundPng}
              onClick={() => {
                goToMutualFundsPage();
              }}
            />
            <p>
              Click on the above link to download your Mutual Fund Prospectus.
            </p>
          </Card>
        </div>
        <Modal
          ref={resetPasswordModal}
          title={"Forgot User ID or Password?"}
          buttonOkText={"Close"}
          pendingAction={false}
          buttonCloseText={"Close"}
          showCancelButton={false}
          buttonOkOnClick={handleCloseModal}
          modalInnerStyle={styles.modalBodyText}
        >
          {`Please contact a dedicated staff member during normal business hours (8:30 a.m. to 5:00 p.m. Eastern Time Zone) at 863-294-3361. We will be happy to help you reset your User ID or password.
            Please have your full mailing address, date of birth, email address, and cell phone number readily available. Thank you.`}
        </Modal>
      </section>
    );
  } else {
    return <Navigate to="/client/documents" />;
  }
};

export default ClientLogin;
