import { React, useCallback, useRef } from "react";
import styles from "./Input.module.scss";
import classNames from "classnames";
import { useState } from "react";
import { ReactComponent as Eye } from "../../assets/icons/visibility.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/visibility_off.svg";

const Input = ({
  required = false,
  type = "text",
  accept,
  placeholder,
  classes,
  name,
  onChange,
  readOnly = false,
  value,
  maxLength,
}) => {
  const input = useRef();
  const [inputType, setInputType] = useState(type);

  const toggleEye = useCallback(() => {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  if (required === true) {
    placeholder += " *";
  }

  return (
    <div className={classNames(classes, styles.inputComponent)}>
      <input
        ref={input}
        required={required}
        type={inputType}
        placeholder={placeholder}
        id={name}
        name={name}
        onChange={handleChange}
        value={value}
        accept={accept}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      {type === "password" &&
        (inputType === type ? (
          <EyeOff
            className={styles.eyeIcon}
            width={24}
            height={24}
            onClick={toggleEye}
          />
        ) : (
          <Eye
            className={styles.eyeIcon}
            width={24}
            height={24}
            onClick={toggleEye}
          />
        ))}
    </div>
  );
};

export default Input;
